import React, { memo, useState, useEffect } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  OutlinedInput,
  Paper,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  TextareaAutosize,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { DatePicker } from '@mui/x-date-pickers';
import MuiAlert from '@mui/material/Alert';
import { Col, Row } from 'reactstrap';
import moment from 'moment';
import '../../../src/App.css';
import { calculateEarliestSlotDate } from '../../common/utility';

import { getSortedCareCoordinatorsOnLastName } from '../../common/utils/RoleUtils';

import { getDocsDue } from '../../common/utils/DateUtils.js';

const MenuProps = { PaperProps: { className: 'customMenuPaper' } };

const apptTypes = [
  'Remote Then Office',
  'Remote Only',
  'Office Only',
  'Office Then Remote',
];

// Below is the helper function to get ProviderName from ProviderId
const getProviderNameFromProviderId = (usersFilter, providerId) => {
  return usersFilter?.find((userFilter) => userFilter?.id === providerId)
    ?.username;
};

const AddSchedulingModal = ({
  open,
  handleClose,
  selectedAvailableSlot,
  existingClientTurning18,
  selectedDuration,
  handleChangeDuration,
  durations,
  selectedStartDate,
  setSelectedStartDate,
  step03FormValues, // Getting Step03FormValues for DocsDue Date calculation
  testingOrTherapyClientComingToOffice,
  getTherapyDocsDueSection,
  selectedApptType,
  selectedEndDate,
  handleSelectedEndDate,
  setSelectedAvailableSlot,
  selectedOfficeLocation,
  handleChange,
  handleChangeApptType,
  activeCareCoordinators,
  handleChangeOfficeLocation,
  officeLocations,
  selectedCareCoordinator,
  setSelectedCareCoordinator,
  step01FormValues,
  isValidEmail,
  exceptionText,
  setExceptionText,
  setTaMrn,
  createSchedule,
  exceptionChecked,
  setExceptionChecked,
  selectedDate,
  setSelectedDate,
  taMrn,
  setOpen,
  nonFilteredData,
  setNonFilteredData,
  selectedSlots, // Receive the selected slots
  setSelectedSlots,
  slots, // Received as array
  earliestSlotDate,
  isMultiSlot,
  setIsMultiSlot,
  usersFilter,
  isManual, // Getting isManual from the Scheduling Modal
}) => {
  /*
  The below sorting careCoordinators on the basis of lastName
  */
  activeCareCoordinators = getSortedCareCoordinatorsOnLastName(
    activeCareCoordinators
  );

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  // Calculate earliestStartDate using the utility function
  const earliestStartDate = calculateEarliestSlotDate(slots);

  useEffect(() => {
    const slotsCount = Object.keys(selectedSlots).length;
    setIsMultiSlot(slotsCount > 1);
    if (slotsCount > 1) {
      /*
      Below setting the nonFilteredData and setting the docsDueDate
      here. It's the date for the due documents. 
      */
      setNonFilteredData({
        ...nonFilteredData,
        // Below setting the docsDueDate
        /**
         * Here for the case of MultiSlot. I am checking if more than
         * one slot is selected. If yes, then pick the first slot start_date_time.
         * Because, the docsDueDate calculation will be done from this date.
         */
        docsDueDate: getDocsDue(
          Object.values(selectedSlots)[0]?.slot_start_date_time,
          step03FormValues, // step03Form Values here
          selectedDate // This selectedDate getting passed here is the override Date
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSlots, setIsMultiSlot]);

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertOpen(false);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="modal-style"
          sx={{
            width: isMobile ? '90%' : '900px', // Adjust width based on screen size
            overflowY: 'auto',
            maxHeight: '90vh',
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add Scheduling Details
          </Typography>

          {selectedAvailableSlot.isManual && (
            <>
              {!existingClientTurning18() && (
                <FormControl sx={{ width: isMobile ? '100%' : 320, margin: 2 }}>
                  <InputLabel id="duration-label">Duration</InputLabel>

                  <Select
                    fullWidth
                    labelId="duration-label"
                    name="frequency"
                    value={selectedDuration}
                    onChange={handleChangeDuration}
                    input={<OutlinedInput label="Duration" />}
                    renderValue={(selected) =>
                      selected?.map((v) => durations[v]).join(', ')
                    }
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          width: isMobile ? '100vw' : '320px', // Set full width for mobile
                          maxWidth: '100vw', // Prevent overflow on mobile
                        },
                      },
                      MenuListProps: {
                        sx: {
                          padding: 0, // Optional: remove padding for a cleaner look
                        },
                      },
                    }}
                  >
                    {Object.keys(durations).map((key, idx) => (
                      <MenuItem key={key} value={key}>
                        <Checkbox
                          checked={selectedDuration.indexOf(key) > -1}
                        />
                        <ListItemText primary={durations[key]} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              <FormControl sx={{ width: isMobile ? '100%' : 320, margin: 2 }}>
                <div className="form-group">
                  <TextField
                    type="datetime-local"
                    label="Start Date and Time"
                    className="form-control"
                    name="appointmentDate"
                    value={selectedStartDate}
                    onChange={(event) => {
                      setSelectedStartDate(event.target.value);
                      let selectedAvailableSlotClone = {
                        ...selectedAvailableSlot,
                      };
                      selectedAvailableSlotClone.start_date_time =
                        event.target.value;
                      setSelectedAvailableSlot(selectedAvailableSlotClone);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                </div>
              </FormControl>
              {!existingClientTurning18() && (
                <FormControl sx={{ width: isMobile ? '100%' : 320, margin: 2 }}>
                  <div className="form-group">
                    <TextField
                      type="datetime-local"
                      label="End Date and Time"
                      className="form-control"
                      name="appointmentDate"
                      value={moment(selectedEndDate).format('YYYY-MM-DDTHH:mm')}
                      onChange={handleSelectedEndDate}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                    />
                  </div>
                </FormControl>
              )}
            </>
          )}

          <FormControl sx={{ width: isMobile ? '100%' : 320, margin: 2 }}>
            <InputLabel id="care-coordinator-label">
              Care Coordinator Name
            </InputLabel>
            <Select
              labelId="care-coordinator-label"
              name="providers"
              value={selectedCareCoordinator}
              onChange={(e) => setSelectedCareCoordinator(e.target.value)}
              input={<OutlinedInput label="Care Coordinator Name" />}
              MenuProps={{
                PaperProps: {
                  sx: {
                    width: isMobile ? '100vw' : '320px', // Full width for mobile
                    maxWidth: '100vw', // Prevent overflow
                    left: 0, // Align with the left edge of the screen
                  },
                },
                MenuListProps: {
                  sx: {
                    padding: 0, // Optional: cleaner dropdown appearance
                  },
                },
              }}
            >
              {activeCareCoordinators?.map((obj) => (
                <MenuItem
                  key={obj.care_coordinator_name}
                  value={obj.care_coordinator_name}
                >
                  <Checkbox
                    checked={
                      selectedCareCoordinator === obj.care_coordinator_name
                    }
                  />
                  <ListItemText primary={obj.care_coordinator_name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ width: isMobile ? '100%' : 320, margin: 2 }}>
            <InputLabel id="appointment-type-label">
              Appointment Type
            </InputLabel>
            <Select
              labelId="appointment-type-label"
              name="apptTypes"
              value={selectedApptType}
              onChange={handleChangeApptType}
              input={<OutlinedInput label="Appointment Type" />}
              renderValue={(selected) => selected.join(', ')}
              MenuProps={MenuProps}
            >
              {!testingOrTherapyClientComingToOffice() &&
                apptTypes.map((masterObj) => (
                  <MenuItem key={masterObj} value={masterObj}>
                    <Checkbox
                      checked={selectedApptType.indexOf(masterObj) > -1}
                      disabled={true}
                    />
                    <ListItemText primary={masterObj} />
                  </MenuItem>
                ))}
              {testingOrTherapyClientComingToOffice() &&
                ['Office Only'].map((masterObj) => (
                  <MenuItem key={masterObj} value={masterObj}>
                    <Checkbox
                      checked={selectedApptType.indexOf(masterObj) > -1}
                      disabled={true}
                    />
                    <ListItemText primary={masterObj} />
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          {selectedApptType?.length > 0 &&
            !selectedApptType.includes('Remote Only') && (
              <FormControl sx={{ width: isMobile ? '100%' : 320, margin: 2 }}>
                <InputLabel id="office-location-label">
                  Office Location
                </InputLabel>
                <Select
                  labelId="office-location-label"
                  name="officeLocation"
                  value={selectedOfficeLocation}
                  onChange={handleChangeOfficeLocation}
                  input={<OutlinedInput label="Office Location" />}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={MenuProps}
                >
                  {officeLocations.map((masterObj) => (
                    <MenuItem key={masterObj} value={masterObj}>
                      <Checkbox
                        checked={selectedOfficeLocation.indexOf(masterObj) > -1}
                      />
                      <ListItemText primary={masterObj} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

          <FormControl sx={{ width: isMobile ? '100%' : 320, margin: 2 }}>
            <TextField
              required
              autoComplete="off"
              fullWidth
              variant="outlined"
              id="ta_mrn"
              label="TA MRN"
              onChange={(event) => {
                setTaMrn(event.target.value.trimStart());
              }}
              value={taMrn}
            />
          </FormControl>

          {!existingClientTurning18() && (
            <>
              <Row style={{ margin: 2 }}>
                <div style={{ textAlign: 'center', width: '100%' }}>
                  Confirm the following scheduling details:
                </div>
              </Row>

              <Row style={{ margin: 2 }}>
                <div
                  style={{
                    textAlign: 'center',
                    width: '100%',
                  }}
                >
                  {step01FormValues?.first_name} {step01FormValues?.last_name} -{' '}
                  {isValidEmail(step01FormValues?.email)}{' '}
                </div>
              </Row>

              <Row style={{ margin: 2 }}>
                <div
                  style={{
                    textAlign: 'center',
                    width: '100%',
                    whiteSpace: 'nowrap',
                  }}
                >
                  <span style={{ fontWeight: 'bold' }}>
                    Selected Cal Event:
                  </span>{' '}
                  {selectedApptType.includes('Remote Only')
                    ? 'Remote'
                    : selectedAvailableSlot?.location}
                </div>
              </Row>

              {/**
               * The below condition is for case when multiple slot appointment
               * is getting scheduled. Meaning testing multiple Slot appointment
               */}
              {selectedSlots && Object.values(selectedSlots).length > 1 && (
                <Row style={{ margin: 2 }}>
                  <div style={{ textAlign: 'center', width: '100%' }}>
                    <span style={{ fontWeight: 'bold' }}>
                      {/**
                       * The below is the helper function to extract ProviderName
                       * from providerId because multiSlot doesn't contain providerName
                       * explicitly
                       */}
                      {getProviderNameFromProviderId(
                        usersFilter,
                        Object.values(selectedSlots)[0].provider_id
                      )}
                    </span>{' '}
                  </div>

                  <TableContainer component={Paper}>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Date</TableCell>
                          <TableCell>Time</TableCell>
                          <TableCell>Duration</TableCell>
                          <TableCell>Setting</TableCell>
                          <TableCell>Location</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Object.values(selectedSlots).map((slot, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              {moment(slot.start_date_time).format(
                                'MM/DD/YYYY'
                              )}
                            </TableCell>
                            <TableCell>
                              {moment(slot.start_date_time).format('hh:mm A')} -{' '}
                              {moment(slot.end_date_time).format('hh:mm A')}
                            </TableCell>
                            <TableCell>
                              {moment(slot.end_date_time).diff(
                                moment(slot.start_date_time),
                                'minutes'
                              )}{' '}
                              minutes
                            </TableCell>
                            <TableCell>{slot.setting}</TableCell>
                            <TableCell>{slot.location || 'Remote'}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Row>
              )}

              {/**
               * The below condition is for case when single slot appointment
               * is getting scheduled. The regular appointments that we have
               */}
              {selectedSlots && Object.values(selectedSlots).length === 1 && (
                <Row style={{ margin: 2 }}>
                  <div style={{ textAlign: 'center', width: '100%' }}>
                    <span style={{ fontWeight: 'bold' }}>
                      {selectedAvailableSlot?.obj?.username}
                    </span>{' '}
                    -{' '}
                    {`${moment(selectedAvailableSlot?.start_date_time).format(
                      'llll'
                    )}`}{' '}
                    -{' '}
                    {`${moment(selectedAvailableSlot?.end_date_time).format(
                      'llll'
                    )}`}{' '}
                    [
                    {selectedApptType.includes('Remote Only')
                      ? 'Remote'
                      : selectedAvailableSlot?.location}
                    ] <span style={{ fontWeight: 'bold' }}></span>
                  </div>
                </Row>
              )}

              {getTherapyDocsDueSection(
                selectedDate || null, // overRideDocDate: Adjust as needed
                earliestSlotDate, // earliestSlotDate: Ensure this is correctly calculated
                selectedSlots && Object.values(selectedSlots).length > 0
                  ? Object.values(selectedSlots)
                  : [],
                selectedAvailableSlot || null
              )}
            </>
          )}

          {existingClientTurning18() && (
            <Row style={{ margin: 2 }}>
              <div
                style={{
                  textAlign: 'center',
                  width: '100%',
                }}
              >
                {step01FormValues?.first_name} {step01FormValues?.last_name} -{' '}
                {step01FormValues?.email}{' '}
              </div>
            </Row>
          )}

          <Row style={{ margin: 14 }} xs="12">
            <Col
              xs="12"
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Row
                style={{
                  display: 'flex',
                  columnGap: 6,
                  width: '100%',
                  justifyContent: 'center', // Center the buttons
                }}
              >
                <Col xs="12" md="3" style={{ textAlign: 'center' }}>
                  <Button
                    variant="contained"
                    onClick={handleClose} // Use handleClose to ensure all necessary cleanup is done
                  >
                    CANCEL
                  </Button>
                </Col>

                {((selectedApptType?.length > 0 &&
                  selectedApptType.includes('Remote Only')) || // If selectedAppt type is Remote
                  (selectedApptType?.length > 0 &&
                    !selectedApptType.includes('Remote Only') && // If selectedAppt type is not Remote then there should be office location
                    selectedOfficeLocation[0] !== null)) &&
                  ((isManual && !earliestStartDate) || // So if there is Manual then there shouldn't be earliestStartDate
                    (!isManual && earliestStartDate)) && // So if this is no Manual Entry then there should be earliestStartDate
                  taMrn &&
                  ((exceptionChecked && exceptionText && selectedDate) || // If exception is checked then there should be exceptionText and date
                    (!exceptionChecked && !exceptionText && !selectedDate)) && ( // If no exception is checked then there shouldn't be any exceptionText and date
                    <Col xs="12" md="6" style={{ textAlign: 'center' }}>
                      <Button
                        variant="contained"
                        onClick={async (e) => {
                          createSchedule();
                        }}
                      >
                        {existingClientTurning18() ? 'SEND EMAIL' : 'SCHEDULE'}
                      </Button>
                    </Col>
                  )}
              </Row>

              {/* TODO: Do not remove this. May be needed in future that is commented */}
              <Grid
                item
                xs={12}
                style={{ marginBottom: exceptionChecked ? '-5px' : '-40px' }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={exceptionChecked}
                      onChange={(e) => {
                        setExceptionChecked(e.target.checked);
                        if (e.target.checked === false) {
                          setExceptionText(null);
                          setSelectedDate(null);
                          const nonFilteredDataObject = nonFilteredData;
                          delete nonFilteredDataObject.docsDueDate;
                          delete nonFilteredDataObject.exceptionForDocs;
                          setNonFilteredData({
                            ...nonFilteredDataObject,
                          });
                        }
                      }}
                    />
                  }
                  label="Exception for Due Date for Docs"
                />
                {exceptionChecked && (
                  <React.Fragment>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <label style={{ marginBottom: '8px' }}>
                            e.g. Min 24 hours before appointment for therapy and
                            72 hours before a testing appointment
                          </label>
                          <DatePicker
                            variant="inline"
                            inputVariant="outlined"
                            format="MM/dd/yyyy"
                            value={selectedDate}
                            name="custom_date_docs_due"
                            InputAdornmentProps={{ position: 'start' }}
                            onChange={(date) => {
                              /**
                               * The below if condition looks a bit complex but it deals with multiSlot appointment
                               * So, if it's a multiSlot appointment then check the first slot
                               * otherwise look for just pick the slot. I am saving it in a variable
                               */
                              const selectedSlotTime =
                                Object.keys(selectedAvailableSlot || {})
                                  .length > 0 &&
                                Object.values(selectedAvailableSlot)[0]
                                  ?.start_date_time
                                  ? Object.values(selectedAvailableSlot)[0]
                                      ?.start_date_time
                                  : selectedAvailableSlot?.start_date_time;

                              // Verifying docsDueDate shouldn't be after appointment Date
                              if (moment(date).isAfter(selectedSlotTime)) {
                                setAlertMessage(
                                  'You cannot select docs due date after the appointment date.'
                                );
                                setAlertOpen(true);
                              } else {
                                setSelectedDate(date);
                                setNonFilteredData({
                                  ...nonFilteredData,
                                  docsDueDate: date,
                                });
                              }
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            style={{ width: '100%' }}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <InputLabel
                          htmlFor="exception-textarea"
                          style={{ color: '#333', textAlign: 'left' }}
                        >
                          Explanation for Exception
                        </InputLabel>
                        <TextareaAutosize
                          id="exception-textarea"
                          minRows={3}
                          required
                          style={{
                            width: '100%',
                            padding: '8px',
                            border: exceptionText
                              ? '1px solid black'
                              : '1px solid red',
                            borderRadius: '4px',
                            textAlign: 'left',
                          }}
                          value={exceptionText}
                          onChange={(e) => {
                            setExceptionText(e.target.value);
                            setNonFilteredData({
                              ...nonFilteredData,
                              exceptionForDocs: e.target.value,
                            });
                          }}
                        />
                      </Grid>
                    </Grid>
                  </React.Fragment>
                )}
              </Grid>
            </Col>
          </Row>
        </Box>
      </Modal>

      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleAlertClose}
          severity="error"
        >
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default memo(AddSchedulingModal);
