import { useState, useEffect, useMemo } from 'react';

const useSocketStepStatus = (initialSteps, socket) => {
  // Create a stable reference for initialSteps
  const memoizedInitialSteps = useMemo(() => initialSteps, [initialSteps]);

  const [steps, setSteps] = useState(memoizedInitialSteps);

  useEffect(() => {
    const handleUpdateStatus = (data) => {
      console.log('Received updateStatus event:', data);
      setSteps((prevSteps) =>
        prevSteps.map((step) =>
          step.stepId === data.stepId ? { ...step, status: data.status } : step
        )
      );
    };

    // Register event listeners for each stepId
    memoizedInitialSteps.forEach((step) => {
      socket.on(step.stepId, handleUpdateStatus);
    });

    // Cleanup event listeners on unmount
    return () => {
      memoizedInitialSteps.forEach((step) => {
        socket.off(step.stepId, handleUpdateStatus);
      });
    };
  }, [memoizedInitialSteps, socket]);

  // Update steps state when initialSteps change
  useEffect(() => {
    setSteps(memoizedInitialSteps);
  }, [memoizedInitialSteps]);

  return steps;
};

export default useSocketStepStatus;
