import React from 'react';
import { IconButton, Popover, Typography } from '@mui/material';
import { History as HistoryIcon } from '@mui/icons-material';
import useHistoryPopover from '../../../hooks/useHistoryPopover';

/**
 * Below SlotHistoryIcon will be getting used providerCard,
 * customCalendarEvent and Reminders
 */
const SlotHistoryIcon = ({ slot, iconStyle }) => {
  /**
   * Here the slot prop is actually the event that's getting passed.
   * The below is the customHook for actually extracting and returning
   * the slot information.
   */
  const {
    anchorEl,
    historyText,
    open,
    handleHistoryClick,
    handleHistoryClose,
  } = useHistoryPopover(slot);

  return (
    <>
      <IconButton onClick={handleHistoryClick}>
        <HistoryIcon style={iconStyle} />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleHistoryClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Typography style={{ whiteSpace: 'pre-line', padding: 8 }}>
          {historyText}
        </Typography>
      </Popover>
    </>
  );
};

export default SlotHistoryIcon;
