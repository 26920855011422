import React, { useState, useEffect, memo } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import parse from 'date-fns/parse';
import format from 'date-fns/format';
import Grid from '@mui/material/Grid2';
import { useSnackbar } from 'notistack';
import EventBus from '../../common/EventBus';
import { adminService, groupService } from '../../services';
import styles from '../../styles/ProviderConfig.module.css';

// to set the date in correct format
export const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd';

const DAYS_OF_WEEK = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

// Convert DB schedule setting to UI labels
function convertDatabaseSettingToUI(databaseValue) {
  switch (databaseValue) {
    case 'office':
      return 'In-Office';
    case 'hybrid':
      return 'In-Office (Remote)';
    case 'remote_only':
    default:
      return 'Remote';
  }
}

// Convert UI schedule label back to DB values
function convertUISettingToDatabase(uiValue) {
  switch (uiValue) {
    case 'In-Office':
      return 'office';
    case 'In-Office (Remote)':
      return 'hybrid';
    case 'Remote':
    default:
      return 'remote_only';
  }
}

const AdminPracticeInfo = (props) => {
  const [inHIPAA, setInHIPAA] = React.useState('');
  const [hrsPerWeek, setHrsPerWeek] = React.useState('');
  // New multi-row office schedules state (like ProviderPracticeInfo)
  const [officeSchedules, setOfficeSchedules] = useState([]);
  const [allLocations, setAllLocations] = useState([]);
  const [allRooms, setAllRooms] = useState([]);
  const [pw, setPw] = React.useState();
  const [startDate, setStartDate] = React.useState(new Date('2025-12-31'));
  const [endDate, setEndDate] = React.useState(new Date('2025-12-31'));
  const [adminStatus, setAdminStatus] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    // Initialize from selectedUserDetail (from props)
    setInHIPAA(Boolean(props.selectedUserDetail?.hipaa) ?? '');
    setHrsPerWeek(props.selectedUserDetail?.hrs_p_week ?? '');
    setPw(props.selectedUserDetail?.pw ?? '');
    setStartDate(
      props.selectedUserDetail?.date_start || new Date('2025-12-31')
    );
    setEndDate(props.selectedUserDetail?.date_end || new Date('2025-12-31'));
    setAdminStatus(Boolean(props.selectedUserDetail?.is_active) ?? false);

    const loadAdminSchedules = async () => {
      try {
        if (!props.selectedUser?.id) return;
        props.showLoading();
        // Fetch schedules for the admin using an endpoint that returns schedule rows
        const response = await groupService.getOfficeScheduleByAdmin(
          props.selectedUser.id
        );
        console.clear();
        console.log('response.data: ', response.data);
        let data = response.data;
        // If a single object is returned, wrap it in an array.
        if (data && !Array.isArray(data)) {
          data = [data];
        }
        const mapped = (data || []).map((row) => ({
          ...row,
          setting: convertDatabaseSettingToUI(row.setting),
          schedule: {
            ...row.schedule,
            startTime: row.schedule?.startTime
              ? parse(row.schedule.startTime, 'HH:mm', new Date())
              : parse('09:00', 'HH:mm', new Date()),
            endTime: row.schedule?.endTime
              ? parse(row.schedule.endTime, 'HH:mm', new Date())
              : parse('17:00', 'HH:mm', new Date()),
          },
        }));
        setOfficeSchedules(mapped);
      } catch (error) {
        console.error('Error fetching admin schedules:', error);
        enqueueSnackbar('Error fetching admin schedules.', {
          variant: 'error',
        });
      } finally {
        props.hideLoading();
      }
    };

    loadAdminSchedules();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedUser, props.selectedUserDetail]);

  // 2. Load locations
  // ----------------------------------
  useEffect(() => {
    const loadLocations = async () => {
      try {
        const response = await groupService.getAllGroupsWithLocations();
        setAllLocations(response.data.locations || []);
      } catch (error) {
        console.error('Error fetching locations:', error);
      }
    };
    loadLocations();
  }, []);

  // ----------------------------------
  // 3. Load Rooms
  // ----------------------------------
  useEffect(() => {
    const fetchAllRooms = async () => {
      try {
        const response = await groupService.getAllOfficeRooms();
        setAllRooms(response.data || []);
      } catch (error) {
        console.error('Error fetching rooms:', error);
      }
    };
    fetchAllRooms();
  }, []);

  // ----------------------------------
  // 4. Handlers for multi-schedule approach
  // ----------------------------------
  const handleAddScheduleRow = () => {
    const newRow = {
      id: null,
      // For admin schedules, set admin_id instead of provider_id
      admin_id: props.selectedUser?.id,
      setting: 'Remote',
      office_location_id: '',
      office_room_id: '',
      type: 'admin',
      schedule: {
        days: [],
        startTime: parse('09:00', 'HH:mm', new Date()),
        endTime: parse('17:00', 'HH:mm', new Date()),
      },
    };
    setOfficeSchedules((prev) => [...prev, newRow]);
  };

  const handleRemoveScheduleRow = async (index) => {
    const row = officeSchedules[index];
    if (!row) return;

    if (row.id) {
      props.showLoading();
      try {
        await groupService.deleteOfficeSchedule(row.id);
        enqueueSnackbar('Deleted schedule entry.', { variant: 'success' });
      } catch (error) {
        console.error('Error deleting schedule:', error);
        enqueueSnackbar('Error deleting schedule entry.', { variant: 'error' });
      } finally {
        props.hideLoading();
      }
    }
    // Remove from state
    setOfficeSchedules((prev) => prev.filter((_, i) => i !== index));
  };

  const handleChangeField = (index, field, value) => {
    setOfficeSchedules((prev) =>
      prev.map((row, i) => {
        if (i !== index) return row;

        if (['days', 'startTime', 'endTime'].includes(field)) {
          return {
            ...row,
            schedule: {
              ...row.schedule,
              [field]: value,
            },
          };
        }
        return { ...row, [field]: value };
      })
    );
  };

  const handleLocationChange = (index, newLocationId) => {
    handleChangeField(index, 'office_location_id', newLocationId);
  };

  const handleSaveRow = async (index) => {
    const row = officeSchedules[index];
    if (!row) return;

    const databaseSetting = convertUISettingToDatabase(row.setting);

    // If schedule is "office" or "hybrid", location and room are required;
    // otherwise set them to null.
    let finalLocationId = null;
    let finalRoomId = null;
    if (databaseSetting === 'office' || databaseSetting === 'hybrid') {
      finalLocationId = row.office_location_id
        ? Number(row.office_location_id)
        : null;
      finalRoomId = row.office_room_id ? Number(row.office_room_id) : null;
    }

    const payload = {
      // Use admin_id for admin schedule rows
      admin_id: row.admin_id,
      setting: databaseSetting,
      office_location_id: finalLocationId,
      office_room_id: finalRoomId,
      schedule: {
        days: row.schedule.days,
        startTime: row.schedule.startTime
          ? format(row.schedule.startTime, 'HH:mm')
          : '09:00',
        endTime: row.schedule.endTime
          ? format(row.schedule.endTime, 'HH:mm')
          : '17:00',
      },
    };

    try {
      props.showLoading();
      if (row.id) {
        // Update existing row
        await groupService.updateOfficeSchedule(row.id, payload);
        enqueueSnackbar(`Updated schedule #${row.id}`, {
          variant: 'success',
        });
      } else {
        // Create new schedule row
        const createRes = await groupService.createOfficeSchedule(payload);
        const newId = createRes.data?.id;
        enqueueSnackbar(`Created new schedule #${newId}`, {
          variant: 'success',
        });
        // Update local state with new ID
        setOfficeSchedules((prev) =>
          prev.map((r, i2) => {
            if (i2 === index) {
              return { ...r, id: newId };
            }
            return r;
          })
        );
      }
    } catch (error) {
      console.error('Error saving schedule row:', error);
      enqueueSnackbar('Error saving schedule row.', { variant: 'error' });
    } finally {
      props.hideLoading();
    }
  };

  useEffect(() => {
    adminService.getAdmins().then(
      async (response) => {
        // eslint-disable-next-line no-unused-vars
        let admins = response?.data?.users ?? [];
        // eslint-disable-next-line no-unused-vars
        admins = admins
          .filter((admin) => {
            return admin.roleId === 3;
          })
          .sort((admin1, admin2) => {
            const name1 = admin1?.username?.split(' ')[1] ?? '';
            const name2 = admin2?.username?.split(' ')[1] ?? '';
            return name1.localeCompare(name2);
          });
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          EventBus.dispatch('logout');
        }
      }
    );
  }, []);

  const handleOnSubmit = async (event) => {
    event.preventDefault();
    try {
      props.showLoading();
      if (props?.selectedUser?.id) {
        // Validate that required fields are present
        if (props.selectedUserDetail?.admin_email && hrsPerWeek) {
          // Prepare admin details payload. Note: Office schedules are now stored separately.
          const response = await adminService.updateAdminDetails(
            props.selectedUser.id,
            {
              admin_email: props.selectedUserDetail?.admin_email,
              hipaa: inHIPAA,
              hrs_p_week: hrsPerWeek,
              // Optionally, you can choose not to send schedule here if schedules are maintained separately.
              // Other admin fields:
              date_start: startDate,
              date_end: endDate,
              pw: pw,
              is_active: adminStatus,
            }
          );

          if (response && response.data && response.data.success) {
            enqueueSnackbar('Admin details updated successfully.', {
              variant: 'success',
            });
          } else {
            enqueueSnackbar('Updated admin details.', {
              variant: 'success',
            });
          }
        } else {
          enqueueSnackbar('Please fill in all required fields.', {
            variant: 'warning',
          });
        }
      } else {
        enqueueSnackbar('Please select an Admin first.', {
          variant: 'warning',
        });
      }
    } catch (error) {
      console.error('Error updating admin details:', error);
      enqueueSnackbar('Failed to update admin details.', {
        variant: 'error',
      });
    } finally {
      props.hideLoading();
    }
  };

  return (
    <React.Fragment>
      <div className={styles.tabsContentContainer}>
        <form onSubmit={handleOnSubmit}>
          <Grid container spacing={3} className={styles.gridItemTextField}>
            {/* HIPAA */}
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <InputLabel>HIPAA Workforce</InputLabel>
                <Select
                  value={inHIPAA}
                  onChange={(e) => setInHIPAA(e.target.value)}
                >
                  <MenuItem value={true}>True</MenuItem>
                  <MenuItem value={false}>False</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Hours Per Week */}
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                fullWidth
                label="Hours Per Week"
                value={hrsPerWeek}
                onChange={(e) => setHrsPerWeek(e.target.value)}
              />
            </Grid>
          </Grid>

          {/* MULTI-ROW SCHEDULE SECTION */}
          <Typography variant="h6" sx={{ marginTop: '1rem' }}>
            Office Schedules
          </Typography>
          {officeSchedules.map((row, index) => {
            const schedule = row.schedule || {};
            // Filter rooms based on the selected location
            const roomsForThisLocation = allRooms.filter(
              (room) => room.location_id === row.office_location_id
            );

            return (
              <Grid
                container
                spacing={2}
                key={index}
                style={{
                  marginBottom: '1rem',
                  border: '1px solid #ccc',
                  padding: '1rem',
                }}
              >
                {/* Setting (Remote, In-Office, In-Office (Remote)) */}
                <Grid size={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}>
                  <RadioGroup
                    row
                    value={row.setting} // UI label
                    onChange={(e) =>
                      handleChangeField(index, 'setting', e.target.value)
                    }
                  >
                    <FormControlLabel
                      value="Remote"
                      control={<Radio />}
                      label="Remote"
                    />
                    <FormControlLabel
                      value="In-Office"
                      control={<Radio />}
                      label="In-Office"
                    />
                    <FormControlLabel
                      value="In-Office (Remote)"
                      control={<Radio />}
                      label="In-Office (Remote)"
                    />
                  </RadioGroup>
                </Grid>

                {/* Days multi-select */}
                <Grid size={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}>
                  <FormControl fullWidth>
                    <InputLabel>Days</InputLabel>
                    <Select
                      multiple
                      value={schedule.days || []}
                      onChange={(e) =>
                        handleChangeField(index, 'days', e.target.value)
                      }
                    >
                      {DAYS_OF_WEEK.map((day) => (
                        <MenuItem key={day} value={day}>
                          {day}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                {/* Start Time */}
                <Grid size={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <TimePicker
                      label="Start Time"
                      value={schedule.startTime}
                      onChange={(newValue) =>
                        handleChangeField(index, 'startTime', newValue)
                      }
                    />
                  </LocalizationProvider>
                </Grid>

                {/* End Time */}
                <Grid size={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <TimePicker
                      label="End Time"
                      value={schedule.endTime}
                      onChange={(newValue) =>
                        handleChangeField(index, 'endTime', newValue)
                      }
                    />
                  </LocalizationProvider>
                </Grid>

                {/* Location & Room (only for In-Office or In-Office (Remote)) */}
                {(row.setting === 'In-Office' ||
                  row.setting === 'In-Office (Remote)') && (
                  <>
                    {/* Location */}
                    <Grid size={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}>
                      <FormControl fullWidth>
                        <InputLabel>Location</InputLabel>
                        <Select
                          value={row.office_location_id || ''}
                          onChange={(e) =>
                            handleLocationChange(index, e.target.value)
                          }
                        >
                          <MenuItem value="">Select Location</MenuItem>
                          {allLocations.map((loc) => (
                            <MenuItem
                              key={loc.location_id}
                              value={loc.location_id}
                            >
                              {loc.location_name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    {/* Room */}
                    <Grid size={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}>
                      <FormControl fullWidth>
                        <InputLabel>Room</InputLabel>
                        <Select
                          value={row.office_room_id || ''}
                          onChange={(e) =>
                            handleChangeField(
                              index,
                              'office_room_id',
                              e.target.value
                            )
                          }
                        >
                          <MenuItem value="">Select Room</MenuItem>
                          {roomsForThisLocation.map((room) => (
                            <MenuItem key={room.id} value={room.id}>
                              {room.room_letter}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </>
                )}

                {/* Save & Delete buttons for each schedule row */}
                <Grid item xs={12} style={{ textAlign: 'right' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleSaveRow(index)}
                    style={{ marginRight: '8px' }}
                  >
                    {row.id ? 'Update' : 'Save'}
                  </Button>
                  <IconButton onClick={() => handleRemoveScheduleRow(index)}>
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            );
          })}

          {/* Button to add a new schedule row */}
          <Button variant="outlined" onClick={handleAddScheduleRow}>
            + Add Schedule
          </Button>

          {/* Save button for Admin Base Details */}
          <Grid item xs={12} style={{ marginTop: '1rem', textAlign: 'center' }}>
            <Button variant="contained" color="primary" type="submit">
              Save Admin Details
            </Button>
          </Grid>
        </form>
      </div>
    </React.Fragment>
  );
};

export default memo(AdminPracticeInfo);
