import { useState } from 'react';
import moment from 'moment';

const eventLabels = {
  provider_availability_slot_created: 'Slot Created By',
  provider_availability_slot_hold_created: 'Hold Created By',
  provider_availability_slot_hold_removed: 'Hold Removed By',
  provider_availability_slot_recreated_due_to_rescheduling:
    'Slot Recreated Due To Rescheduling By',
  provider_availability_slot_archived: 'Slot Archived By',
  provider_availability_appointment_rescheduled: 'Appt Rescheduled By',
  provider_availability_slot_cancelled: 'Appt Cancelled By',
  provider_availability_slot_hold_archived: 'Slot Hold Archived By',
  provider_availability_slot_appointment_created: 'Appt Created By',
};

const useHistoryPopover = (groupFirstSlot) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [historyText, setHistoryText] = useState('');

  const handleHistoryClick = (event) => {
    // Toggle off if popover is already open
    if (anchorEl) {
      setAnchorEl(null);
      setHistoryText('');
      return;
    }

    if (!groupFirstSlot || !groupFirstSlot.slot_history) {
      setAnchorEl(event.currentTarget);
      setHistoryText('No slot history available.');
      return;
    }

    // Sort and format the history (latest event first)
    const sorted = [...groupFirstSlot.slot_history].sort(
      (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
    );

    const formatted = sorted.map((item) => {
      const label = eventLabels[item.event] || item.event; // Use mapped label or fallback to event name
      const time = moment(item.timestamp).format('M/D/YY h:mma');
      return `${label}: ${item.username} @ ${time}`;
    });

    setAnchorEl(event.currentTarget);
    setHistoryText(formatted.join('\n'));
  };

  const handleHistoryClose = () => {
    setAnchorEl(null);
    setHistoryText('');
  };

  const open = Boolean(anchorEl);

  return {
    anchorEl,
    historyText,
    open,
    handleHistoryClick,
    handleHistoryClose,
  };
};

export default useHistoryPopover;
