import React from 'react';
import SlotHistoryIcon from '../UI/Icons/SlotHistoryIcon';
import PanToolWithPopover from '../UI/Icons/PanToolWithPopover';
import NoteWithPopover from '../UI/Icons/NoteWithPopover';
import moment from 'moment';

/**
 * The below CustomCalendarEvent is going to show on
 * availability Calendar. With History Icon
 */
const CustomCalendarEvent = ({ event, providersOptions }) => {
  const handleIconClick = (e) => {
    e.stopPropagation(); // Prevent triggering onSelectEvent
  };

  /**
   * Filtering that particular provider from providerOptions
   */
  const provider = providersOptions
    ? providersOptions.find((p) => p.id === event.provider_id)
    : undefined;

  let username = provider?.username;

  if (username && username.split(' ').length > 1) {
    const name = username.split(',')[0];
    username = name.split(' ')[name.split(' ').length - 1];
  }

  const startMoment = moment(event.start_date_time);
  const startTime = startMoment.format('h:mmA');

  const historyIcon = (
    <div
      onClick={handleIconClick}
      style={{ display: 'inline-flex', alignItems: 'center' }}
    >
      <SlotHistoryIcon slot={event} iconStyle={{ color: 'white' }} />{' '}
      {/* White color */}
    </div>
  );

  const freqLabel = (
    <span
      style={{
        marginLeft: '4px',
        color: 'white',
        fontWeight: 'bold',
        fontSize: '0.9em',
      }}
    >
      {event.frequency}
    </span>
  );

  // Add the red border for Testing
  const testingBorder =
    event.service === 'Testing'
      ? { border: '4px solid red', borderRadius: '4px' }
      : {};

  return (
    <div
      style={{
        height: '100%',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        padding: '2px', // Increased padding slightly
        fontSize: '0.75em', // Keep base font size
        lineHeight: '1.1',
        ...testingBorder, // Apply the testing border conditionally
      }}
    >
      <div
        style={{ display: 'flex', alignItems: 'center', marginBottom: '2px' }}
      >
        {/* Displaying Larger username on Event */}
        <span style={{ fontWeight: 'bold', fontSize: '1.3em' }}>
          {username}
        </span>{' '}
      </div>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop: '0px',
        }}
      >
        {/* Displaying Brighter white time, Larger time on Event */}
        <div style={{ fontSize: '1.3em', color: 'white', fontWeight: 'bold' }}>
          {' '}
          {startTime}
        </div>

        <div
          style={{ display: 'flex', alignItems: 'center', marginTop: '-5px' }}
        >
          {' '}
          {/* Align icons in a row */}
          {event.type === 'Hold' && <PanToolWithPopover slot={event} />}
          {event.type === 'Open' && event.note && (
            <NoteWithPopover slot={event} iconStyle={{ color: 'white' }} />
          )}
          <div style={{ marginLeft: 'auto' }}>{historyIcon}</div>
          {/* Added marginLeft here */}
        </div>
      </div>
      <div style={{ display: 'flex' }}>{freqLabel}</div>
    </div>
  );
};

export default CustomCalendarEvent;
