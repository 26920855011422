import { useState } from 'react';
import moment from 'moment';
import { getFirstCharacters } from '../common/utils/StringUtils';

/**
 * Hook for displaying the Hold information
 */
const usePanToolPopover = (slot) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [content, setContent] = useState('');

  const handleIconClick = (event) => {
    if (anchorEl) {
      //close if already open
      setAnchorEl(null);
      setContent('');
      return;
    }

    if (!slot || slot.type !== 'Hold') {
      setContent('Not a hold slot.'); // Or handle as you see fit
      setAnchorEl(event.currentTarget); //still need to set.
      return;
    }

    let popoverContent = '';
    if (slot.person_creating_hold) {
      popoverContent += `Hold Created By ${getFirstCharacters(
        slot.person_creating_hold
      )} on ${moment(slot.holdCreationDate).format('MM/DD/YY')} RE -${
        slot.clientInitials || ''
      }`;
    }
    if (slot.holdExpirationDateTime) {
      popoverContent += `\nHold Expires on ${moment(slot.holdExpirationDateTime)
        .utc()
        .format('MM/DD/YY hh:mm A')}`;
    }

    if (!popoverContent) {
      popoverContent = 'No hold details available.';
    }
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setContent(popoverContent);
  };

  const handlePopoverClose = (event) => {
    setAnchorEl(null);
    setContent('');
    event.stopPropagation();
  };

  const open = Boolean(anchorEl);

  return { anchorEl, content, open, handleIconClick, handlePopoverClose };
};

export default usePanToolPopover;
