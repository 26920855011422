import React, { memo } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
} from '@mui/material';

const AddConditionErrorDialog = ({
  errorModalOpen,
  setErrorModalOpen,
  errorMessage,
}) => {
  return (
    <Dialog open={errorModalOpen} onClose={() => setErrorModalOpen(false)}>
      <DialogTitle>Condition Creation Error</DialogTitle>
      <DialogContent>
        <Typography sx={{ whiteSpace: 'pre-wrap' }}>{errorMessage}</Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => setErrorModalOpen(false)}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(AddConditionErrorDialog);
