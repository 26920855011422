import axios from 'axios';
import authHeader from './auth-header';
const API_URL = `${process.env.REACT_APP_API_HOST}/api/`;

const updateAvailability = (id, body) =>
  axios.post(`${API_URL}updateAvailability/${id}`, body, {
    headers: authHeader(),
  });

const deleteEvent = (id, body) =>
  axios.post(`${API_URL}deleteEvent/${id}`, body, { headers: authHeader() });

const addProviderAvailability = (id, body) =>
  axios.post(`${API_URL}addProviderAvailability/${id}`, body, {
    headers: authHeader(),
  });

const getProviderAvailabilityAndSlots = (id) =>
  axios.get(`${API_URL}getProviderAvailabilityAndSlots/${id}`, {
    headers: authHeader(),
  });

const getProviderAvailabilityAndSlotsForUpdate = (id) =>
  axios.get(`${API_URL}getProviderAvailabilityAndSlotsForUpdate/${id}`, {
    headers: authHeader(),
  });

// eslint-disable-next-line
export default {
  updateAvailability,
  deleteEvent,
  addProviderAvailability,
  getProviderAvailabilityAndSlots,
  getProviderAvailabilityAndSlotsForUpdate,
};
