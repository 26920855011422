import moment from 'moment';

export const yesNoOptions = ['', 'No', 'Yes'];

export const entryTypeFormValuesValid = (entryTypeFormValues) => {
  let {
    entryType,
    existingClientOptions,
    returningClientQ1,
    cancellationOptions,
  } = entryTypeFormValues;

  if (entryType === 'new') {
    return true;
  }

  if (entryType === 'existing') {
    if (existingClientOptions === 'Transfer') {
      return true;
    }

    if (existingClientOptions === 'Testing Client Coming to Office') {
      return true;
    }

    if (existingClientOptions === 'Therapy Client Coming to Office') {
      return true;
    }

    if (existingClientOptions === 'Turning 18 years-Old') {
      return true;
    }
  }

  if (entryType === 'returning') {
    if (returningClientQ1) {
      return true;
    }
  }

  if (entryType === 'cancellation') {
    if (cancellationOptions) {
      return true;
    }
  }

  // Check if the entry type is 'waitlist' and return true if so
  if (entryType === 'waitlist') {
    return true;
  }

  return false;
};

export const cases = (entryTypeFormValues, services) => {
  return {
    case1:
      entryTypeFormValues.entryType === 'existing' &&
      entryTypeFormValues.existingClientOptions === 'Transfer',

    // since transferQuestion is eliminated, case2 never fires
    case2:
      entryTypeFormValues.entryType === 'existing' &&
      entryTypeFormValues.existingClientOptions === 'Transfer' &&
      entryTypeFormValues.transferQuestion === 'After July 2021',

    // note that covid addendum question is now removed from therapy client coming to office option. so the case conditions have been changed. TODO: revisit case 3,4,5,6

    case3:
      (entryTypeFormValues.entryType === 'existing' &&
        entryTypeFormValues.existingClientOptions ===
          'Therapy Client Coming to Office') ||
      (entryTypeFormValues.existingClientOptions ===
        'Testing Client Coming to Office' &&
        // entryTypeFormValues.covidAddendum === 'Yes' &&
        !services?.includes('Bariatric Surgical Psychological Evaluations')),

    case4:
      (entryTypeFormValues.entryType === 'existing' &&
        entryTypeFormValues.existingClientOptions ===
          'Therapy Client Coming to Office') ||
      (entryTypeFormValues.existingClientOptions ===
        'Testing Client Coming to Office' &&
        services?.includes('Bariatric Surgical Psychological Evaluations')),

    // Since we removed the Covid Addendum - case 5 below will not be fired and commented out first part of case 5 since it's redundant with case 4
    case5:
      entryTypeFormValues.existingClientOptions ===
        'Testing Client Coming to Office' &&
      entryTypeFormValues.covidAddendum === 'No' &&
      services?.includes('Bariatric Surgical Psychological Evaluations'),

    // since we removed the COVID Addendum case 6 will not be fired
    case6:
      (entryTypeFormValues.entryType === 'existing' &&
        entryTypeFormValues.existingClientOptions ===
          'Therapy Client Coming to Office') ||
      (entryTypeFormValues.existingClientOptions ===
        'Testing Client Coming to Office' &&
        entryTypeFormValues.covidAddendum === 'No' &&
        !services?.includes('Bariatric Surgical Psychological Evaluations')),

    case7:
      entryTypeFormValues.entryType === 'returning' &&
      entryTypeFormValues.returningClientQ1 === 'Yes' &&
      entryTypeFormValues.returningClientQ2?.indexOf('Nota') === -1,
    case8:
      entryTypeFormValues.entryType === 'returning' &&
      entryTypeFormValues.returningClientQ1 === 'Yes' &&
      entryTypeFormValues.returningClientQ2?.indexOf('Nota') > -1,
    case9:
      entryTypeFormValues.entryType === 'returning' &&
      entryTypeFormValues.returningClientQ1 === 'No' &&
      entryTypeFormValues.returningClientQ2?.indexOf('Nota') === -1,

    // case 10 never fires since returningClientQ2 is eliminated
    case10:
      entryTypeFormValues.entryType === 'returning' &&
      entryTypeFormValues.returningClientQ1 === 'No' &&
      entryTypeFormValues.returningClientQ2?.indexOf('Nota') > -1,

    case11:
      entryTypeFormValues.entryType === 'existing' &&
      entryTypeFormValues.existingClientOptions === 'Turning 18 years-Old',

    // two cases below will not be fired since we removed COVID Addendum

    caseCovidAddendumYes:
      entryTypeFormValues.entryType === 'existing' &&
      entryTypeFormValues.existingClientOptions ===
        'Testing Client Coming to Office' &&
      entryTypeFormValues.covidAddendum === 'Yes',

    caseCovidAddendumNo:
      entryTypeFormValues.entryType === 'existing' &&
      entryTypeFormValues.existingClientOptions ===
        'Testing Client Coming to Office' &&
      entryTypeFormValues.covidAddendum === 'No',
  };
};

export const isAdmin = (currentUser) => {
  return (
    currentUser &&
    currentUser.roles &&
    currentUser.roles.indexOf('ROLE_ADMIN') > -1
  );
};

export const isCareCoordinator = (currentUser) => {
  return (
    currentUser &&
    currentUser.roles &&
    currentUser.roles.indexOf('ROLE_CARE_COORDINATOR') > -1
  );
};

export const isAdminOrCareCoordinator = (currentUser) => {
  return isAdmin(currentUser) || isCareCoordinator(currentUser);
};

export const isSupervisee = (currentUser) => {
  return (
    currentUser &&
    currentUser.roles &&
    currentUser.roles.indexOf('ROLE_SUPERVISEE') > -1
  );
};

export const isSupervisorORSupervisee = (currentUser) => {
  return (
    currentUser &&
    currentUser.roles &&
    (currentUser.roles.indexOf('ROLE_SUPERVISOR') > -1 ||
      currentUser.roles.indexOf('ROLE_SUPERVISEE') > -1)
  );
};

export const isOnlyDoctorOrSupervisor = (currentUser) => {
  return (
    currentUser &&
    currentUser.roles &&
    currentUser.roles.length === 2 &&
    currentUser.roles.indexOf('ROLE_SUPERVISOR') > -1 &&
    currentUser.roles.indexOf('ROLE_DOCTOR') > -1
  );
};

export const isDoctorAdminSupervisor = (currentUser) => {
  return (
    currentUser &&
    currentUser.roles &&
    currentUser.roles.indexOf('ROLE_DOCTOR') > -1 &&
    currentUser.roles.indexOf('ROLE_ADMIN') > -1 &&
    currentUser.roles.indexOf('ROLE_SUPERVISOR') > -1
  );
};

export const isDoctor = (currentUser) => {
  return (
    currentUser &&
    currentUser.roles &&
    currentUser.roles.indexOf('ROLE_DOCTOR') > -1 &&
    !isDoctorAdminSupervisor(currentUser)
  );
};

export const isOnlyDoctor = (currentUser) => {
  return (
    currentUser &&
    currentUser.roles &&
    currentUser.roles.length === 1 &&
    currentUser.roles.indexOf('ROLE_DOCTOR') > -1
  );
};

export const isExtern = (currentUser) => {
  return (
    currentUser &&
    currentUser.roles &&
    currentUser.roles.length === 1 &&
    currentUser.roles.indexOf('ROLE_EXTERN') > -1
  );
};

export const isOnlyDoctorOrSupervisee = (currentUser) => {
  return (
    currentUser &&
    currentUser.roles &&
    currentUser.roles.length < 3 &&
    (currentUser.roles.indexOf('ROLE_DOCTOR') > -1 ||
      currentUser.roles.indexOf('ROLE_SUPERVISEE') > -1) &&
    currentUser.roles.indexOf('ROLE_SUPERVISOR') < 0
  );
};

export const isCredentialer = (currentUser) => {
  return (
    currentUser &&
    currentUser.roles &&
    currentUser.roles.indexOf('ROLE_CREDENTIALER') > -1
  );
};

export const isBiller = (currentUser) => {
  return (
    currentUser &&
    currentUser.roles &&
    currentUser.roles.indexOf('ROLE_BILLER') > -1
  );
};

export const isHIM = (currentUser) => {
  return (
    currentUser &&
    currentUser.roles &&
    currentUser.roles.indexOf('ROLE_HIM') > -1
  );
};

export const isAuditor = (currentUser) => {
  return (
    currentUser &&
    currentUser.roles &&
    currentUser.roles.indexOf('ROLE_AUDITOR') > -1
  );
};

export const isAdminOrAuditor = (currentUser) => {
  return isAdmin(currentUser) || isAuditor(currentUser);
};

export const isClaimsManager = (currentUser) => {
  return (
    currentUser &&
    currentUser.roles &&
    currentUser.roles.indexOf('ROLE_CLAIMS_MANAGER') > -1
  );
};

export const isPayrollSpecialist = (currentUser) => {
  return (
    currentUser &&
    currentUser.roles &&
    currentUser.roles.indexOf('ROLE_PAYROLL_SPECIALIST') > -1
  );
};

export const hasSuperAdminAccess = (currentUser) => {
  return (
    // eslint-disable-next-line eqeqeq
    currentUser.id == process.env.REACT_APP_USERID1_FOR_LOGS_AND_USER_MANAGE ||
    // eslint-disable-next-line eqeqeq
    currentUser.id == process.env.REACT_APP_USERID2_FOR_LOGS_AND_USER_MANAGE
  );
};

export const hasCredentialingAccess = (currentUser) => {
  return (
    // eslint-disable-next-line eqeqeq
    currentUser.id == process.env.REACT_APP_USERID1_FOR_LOGS_AND_USER_MANAGE ||
    // eslint-disable-next-line eqeqeq
    currentUser.id == process.env.REACT_APP_USERID2_FOR_LOGS_AND_USER_MANAGE ||
    isCredentialer(currentUser)
  );
};

export const isUserAdminOrCareCoordinator = (user) => {
  // Admin has roleId 3 and Care Coordinator has roleId 6
  const allowedRoleIds = [3, 6];
  return allowedRoleIds.includes(user.roleId);
};

export const getUserRoleInfo = (user) => {
  const roles = [
    { label: 'Admin', color: '#f44336' },
    { label: 'Provider', color: '#4caf50' },
    { label: 'Care Coordinator', color: '#2196f3' },
    { label: 'Unknown', color: '#757575' },
  ];

  if (user.isProvider === 1) {
    return roles[1];
  } else if (user.isAdmin === 1) {
    return roles[0];
  } else if (user.isCareCoordinator === 1) {
    return roles[2];
  } else {
    return roles[3];
  }
};

export const isSelectedUserSupervisee = (userDetails) => {
  return (
    Array.isArray(userDetails.degrees_and_license) &&
    !userDetails.degrees_and_license.includes('Fully Licensed')
  );
};

export const isUserCareCoordinator = (user) => {
  const allowedRoleIds = [6];
  return allowedRoleIds.includes(user.roleId);
};

export const removeNullUndefinedValues = (obj) => {
  const keys = Object.keys(obj);

  return keys.reduce((result, key) => {
    if (obj[key] !== null && obj[key] !== undefined) {
      result[key] = obj[key];
    }
    return result;
  }, {});
};

// Helper function to calculate business days between two dates
export const calculateBusinessDays = (startDate, endDate) => {
  let count = 0;
  const current = new Date(startDate);
  const end = new Date(endDate);

  while (current <= end) {
    const dayOfWeek = current.getDay();
    if (dayOfWeek >= 1 && dayOfWeek <= 5) {
      count++;
    }
    current.setDate(current.getDate() + 1);
  }

  return count;
};
export const isDST = (date) => {
  const year = date.getFullYear();

  const secondSundayInMarch = new Date(year, 2, 8);

  secondSundayInMarch.setDate(8 + (7 - secondSundayInMarch.getDay()));

  const firstSundayInNovember = new Date(year, 10, 1);

  firstSundayInNovember.setDate(1 + (7 - firstSundayInNovember.getDay()));

  return date >= secondSundayInMarch && date < firstSundayInNovember;
};

export const addBusinessDays = (date, numDays) => {
  let count = 0;
  while (count < numDays) {
    date.setDate(date.getDate() + 1);
    const dayOfWeek = date.getDay();
    if (dayOfWeek >= 1 && dayOfWeek <= 5) {
      count++;
    }
  }
  return date;
};

export const extractEmailAddress = (inputString) => {
  const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/;

  const match = inputString?.match(emailRegex);

  if (match) {
    return match[0];
  }

  return inputString;
};

export const formatDate = (dateString) => {
  if (!dateString) return ''; // Handle case where date is not available

  const formattedDate = moment(dateString).format('MM/DD/YY');
  return formattedDate;
};

export const calculateEarliestSlotDate = (slots) => {
  // If slots is not an array, but it's an object with start_date_time, convert it to an array
  if (!Array.isArray(slots)) {
    if (slots && slots.start_date_time) {
      slots = [slots];
    } else {
      console.warn('Slots is not an array and not a valid slot object.');
      return null;
    }
  }

  // Filter out any null or undefined slots with valid start_date_time
  slots = slots.filter((slot) => slot && slot.start_date_time);

  if (slots.length === 0) {
    console.warn('Slots array is empty or invalid.');
    return null;
  }

  // Initialize with the first slot's start_date_time
  const earliestDate = slots.reduce((earliest, slot) => {
    const slotDate = new Date(slot.start_date_time);

    if (isNaN(slotDate.getTime())) {
      console.warn('Invalid slot date encountered:', slot.start_date_time);
      return earliest;
    }

    // Log each slot date and its comparison
    console.log('Comparing:', {
      currentEarliest: earliest,
      slotDate: slotDate,
    });

    // Return the earlier of the two dates
    return earliest > slotDate ? slotDate : earliest;
  }, new Date(slots[0].start_date_time)); // Use the first slot's date for initialization

  console.log('Calculated earliestDate:', earliestDate);
  return earliestDate instanceof Date && !isNaN(earliestDate.getTime())
    ? earliestDate
    : null;
};

export const compareSlotDateWithToday = (availableSlot) => {
  const availableDate = new Date(availableSlot.start_date_time);
  const today = new Date();

  const availableDateOnly = new Date(
    availableDate.getFullYear(),
    availableDate.getMonth(),
    availableDate.getDate()
  );

  // Get the date for the immediate next day
  const nextDay = new Date(today);
  nextDay.setDate(nextDay.getDate() + 1);
  const nextDayOnly = new Date(
    nextDay.getFullYear(),
    nextDay.getMonth(),
    nextDay.getDate()
  );

  // Check if it's after 12 PM today
  const isAfter12PM = today.getHours() >= 12;

  // Check if the available date is the immediate next day
  const isNextDay = availableDateOnly.getTime() === nextDayOnly.getTime();

  // Check if it's after 12 PM and the available date is the immediate next day
  if (isAfter12PM && isNextDay) {
    return true;
  }

  // Check if the available date is today (considering year, month, and day)
  if (
    availableDateOnly.getDate() === today.getDate() &&
    availableDateOnly.getMonth() === today.getMonth() &&
    availableDateOnly.getFullYear() === today.getFullYear()
  ) {
    return true;
  }

  return false;
};

export const filterDocuments = (docs, filters, userSessionDetails) => {
  // First, filter all documents according to the given criteria
  const filteredDocs = docs.filter((doc) => {
    const matches = {
      serviceGroup:
        doc.service_group === 'All' ||
        !filters.serviceGroup ||
        doc.service_group.toLowerCase().trim() ===
          filters.serviceGroup.toLowerCase().trim(),
      serviceType:
        doc.service_sub_type === 'All' ||
        !filters.serviceType ||
        doc.service_sub_type
          .toLowerCase()
          .trim()
          .split(', ')
          .includes(filters.serviceType.toLowerCase().trim()),
      clientType:
        doc.client_type === 'All' ||
        !filters.clientType ||
        doc.client_type
          .toLowerCase()
          .trim()
          .split(', ')
          .includes(filters.clientType.toLowerCase().trim()),
      ageRange:
        doc.age_range === 'All' ||
        !filters.age ||
        (doc.age_range === '18+' ? filters.age >= 18 : filters.age < 18),
      licenseStatus:
        doc.license_status === 'All' ||
        !filters.licenseStatus ||
        doc.license_status.toLowerCase().trim() ===
          filters.licenseStatus.toLowerCase().trim(),
      specialScenario:
        doc.special_scenario === null ||
        isSpecialScenarioMet(doc, userSessionDetails),
    };

    return Object.values(matches).every(Boolean);
  });

  // Modify here: Exclude the influence of `isTurning18` from excluding other documents
  if (userSessionDetails.isTurning18) {
    return filteredDocs; // If `isTurning18` is true, do not exclude any documents that meet the criteria
  } else {
    // Handle other special scenarios that might require exclusions
    const specialScenarioDocs = filteredDocs.filter(
      (doc) => doc.special_scenario
    );
    const regularDocs = filteredDocs.filter((doc) => !doc.special_scenario);

    return [...new Set([...specialScenarioDocs, ...regularDocs])]; // Combine and remove duplicates
  }
};

// The below function sorts the documents based on their doc_name
export const sortDocuments = (documents) => {
  // Helper function to extract a prefix like "1.", "(2/4)", "(2B)", etc.
  function parsePrefix(name) {
    // This regex captures:
    // - Optional "(" at the start
    // - One or more digits (\d+)
    // - Followed optionally by letters or "/"
    // - Optional ")" or "." after
    // Example matches:
    //   "1. Welcome..."
    //   "(2/4) Something..."
    //   "(2B) Something..."
    //   "3. Something..."
    // It returns just the prefix portion, e.g. "1", "2/4", "2B", "3"
    // eslint-disable-next-line no-useless-escape
    const match = name.match(/^\(?(\d+[A-Za-z0-9\/]*)\)?\.?\s?/);
    return match ? match[1] : null;
  }

  return documents.sort((a, b) => {
    const aPrefix = parsePrefix(a.doc_name);
    const bPrefix = parsePrefix(b.doc_name);

    // If both have numeric-like prefixes, compare them more specifically
    if (aPrefix && bPrefix) {
      // Extract the leading number from each
      const aNumMatch = aPrefix.match(/^(\d+)/);
      const bNumMatch = bPrefix.match(/^(\d+)/);

      if (aNumMatch && bNumMatch) {
        const aNum = parseInt(aNumMatch[1], 10);
        const bNum = parseInt(bNumMatch[1], 10);

        // If numeric parts differ, sort by the number
        if (aNum !== bNum) {
          return aNum - bNum;
        }
      }

      // If leading digits are the same, compare leftover (e.g. "/4" vs. "B")
      const aRest = aPrefix.replace(/^\d+/, '');
      const bRest = bPrefix.replace(/^\d+/, '');
      return aRest.localeCompare(bRest);
    }

    // If only one has a prefix, that one goes first
    if (aPrefix) return -1;
    if (bPrefix) return 1;

    // Otherwise, do a standard alphabetical compare
    return a.doc_name.localeCompare(b.doc_name);
  });
};

export const isSpecialScenarioMet = (document, userSessionDetails) => {
  // If there's no special_scenario, just return true
  if (!document.special_scenario || document.special_scenario.trim() === '') {
    return true;
  }

  // Split by commas in case there are multiple scenarios in one string
  const scenarios = document.special_scenario.split(',').map((s) => s.trim());

  // Check if at least one scenario is satisfied
  return scenarios.some((scenario) => {
    switch (scenario) {
      case 'Pro Bono':
        return userSessionDetails.isProBono;

      case 'Adoptions':
        return userSessionDetails.isAdoptions;

      case 'Adult ADHD':
        return userSessionDetails.isAdultADHD;

      case 'Adult Autism':
        return userSessionDetails.isAdultAutism;

      case 'Bariatrics':
        return userSessionDetails.isBariatric;

      case 'Bariatrics - Hopkins':
        return userSessionDetails.isBariatricHopkins;

      case 'Bariatrics - Other':
        return userSessionDetails.isBariatricNOTHopkins;

      case 'Couples - Insurance':
        return userSessionDetails.isCouplesInsurance;

      case 'Couples - PP':
        return userSessionDetails.isCouplesPP;

      case 'Families - Insurance':
        return userSessionDetails.isFamiliesInsurance;

      case 'Families - PP':
        return userSessionDetails.isFamiliesPP;

      case 'Turning 18':
        return userSessionDetails.isTurning18;

      // Add other cases as needed...

      default:
        // If the scenario string doesn’t match any known key,
        // treat it as false (i.e., not met).
        return false;
    }
  });
};

/* 
Utility function to group and sort days into ranges like "Mon-Thurs".
It's getting used in CheatSheet in the Group Component
*/
export const groupDays = (days) => {
  const dayOrder = ['Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat', 'Sun'];

  if (!Array.isArray(days) || days.length === 0) {
    return '';
  }

  // Remove duplicates and sort by dayOrder
  const sortedDays = [...new Set(days)]
    .filter((day) => dayOrder.includes(day))
    .sort((a, b) => dayOrder.indexOf(a) - dayOrder.indexOf(b));

  // Group consecutive days
  const grouped = [];
  let rangeStart = sortedDays[0];

  for (let i = 1; i <= sortedDays.length; i++) {
    if (
      i === sortedDays.length ||
      dayOrder.indexOf(sortedDays[i]) !==
        dayOrder.indexOf(sortedDays[i - 1]) + 1
    ) {
      if (rangeStart === sortedDays[i - 1]) {
        grouped.push(rangeStart); // Just one day
      } else {
        grouped.push(`${rangeStart}-${sortedDays[i - 1]}`); // Range
      }
      rangeStart = sortedDays[i];
    }
  }

  return grouped.join(', ');
};

/* 
Utility function to format the time. 
It's getting used in CheatSheet in the Group Component
*/
export const formatTime = (dateObj) => {
  // Raw hours in 24-hour format
  let hours = dateObj.getHours();
  const minutes = dateObj.getMinutes();
  // Determine AM/PM
  const ampm = hours >= 12 ? 'PM' : 'AM';

  // Convert to 12-hour
  hours = hours % 12;
  if (hours === 0) {
    hours = 12;
  }

  // If minutes === 0, skip the :00 part, otherwise format with a colon
  if (minutes === 0) {
    return `${hours}${ampm}`; // e.g. "5PM"
  } else {
    // e.g. "5:30PM" (padding minutes as needed)
    return `${hours}:${String(minutes).padStart(2, '0')}${ampm}`;
  }
};

/*
Utility function to format the Location Address. 
Helper function to format a location's address into multiple lines:
*/
export const formatLocationAddress = (location) => {
  if (!location) return '';
  const { address1, address2, city, state, zip } = location;
  return (
    <>
      <div>{address1}</div>
      {address2 && <div>{address2}</div>}
      <div>
        {city}, {state} {zip}
      </div>
    </>
  );
};

/*
Utility function to format time from 24 to 12 hours
It's getting used in GroupLocationsTable file
*/
export const formatTime24To12 = (time) => {
  if (!time) return 'N/A'; // Handle empty or missing time
  const [hour, minute] = time.split(':').map(Number);
  const period = hour >= 12 ? 'pm' : 'am';
  const formattedHour = hour % 12 || 12; // Convert hour to 12-hour format
  return `${formattedHour}${minute > 0 ? `:${minute}` : ''}${period}`;
};
