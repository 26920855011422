import React, { useState, memo } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
} from '@mui/material';

import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// -----------------------------------------------------
// 2) CONDITION DIALOG FOR AVAILABILITY CONDITIONS
// -----------------------------------------------------
const AddConditionDialog = ({
  open,
  onClose,
  onSave,
  editingCondition,
  conditionNames,
}) => {
  const [selectedConditionId, setSelectedConditionId] = useState(
    editingCondition?.condition_name_id || ''
  );
  const [conditionValue, setConditionValue] = useState(
    editingCondition?.condition_value || ''
  );
  const [startDate, setStartDate] = useState(
    editingCondition?.startDate
      ? new Date(editingCondition.startDate)
      : new Date()
  );
  const [endDate, setEndDate] = useState(
    editingCondition?.endDate ? new Date(editingCondition.endDate) : new Date()
  );
  // eslint-disable-next-line no-unused-vars
  const [noEndDate, setNoEndDate] = useState(false);
  const [notes, setNotes] = useState(editingCondition?.notes || '');

  const handleConditionChange = (event) => {
    setSelectedConditionId(event.target.value);
    // Reset condition value when condition changes
    setConditionValue('');
  };

  const handleCancel = () => {
    onClose();
  };

  const handleSaveClick = () => {
    if (!selectedConditionId) {
      alert('Please select a condition.');
      return;
    }
    if (!conditionValue) {
      alert('Please enter a value for the selected condition.');
      return;
    }

    const result = {
      condition_name_id: selectedConditionId,
      condition_value: conditionValue,
      start_date: startDate ? startDate.toISOString().split('T')[0] : null,
      end_date: noEndDate ? null : endDate.toISOString().split('T')[0],
      notes,
    };

    onSave(result);
  };

  const getConditionOptions = () => {
    const currentCondition = conditionNames.find(
      (c) => c.id === selectedConditionId
    )?.condition_name;

    switch (currentCondition) {
      case 'no_back_to_back':
        return Array.from({ length: 12 }, (_, i) => (i + 1) * 15).map(
          (minutes) => ({
            value: minutes,
            label:
              minutes % 60 === 0
                ? `${minutes} min (${minutes / 60} hour${
                    minutes / 60 > 1 ? 's' : ''
                  })`
                : `${minutes} min`,
          })
        );
      case 'weekly_client_limit':
        return Array.from({ length: 100 }, (_, i) => i + 1).map((num) => ({
          value: num,
          label: `${num} Client${num > 1 ? 's' : ''}`,
        }));
      case 'daily_client_limit':
        return Array.from({ length: 20 }, (_, i) => i + 1).map((num) => ({
          value: num,
          label: `${num} Client${num > 1 ? 's' : ''}`,
        }));
      default:
        return [];
    }
  };

  const getConditionLabel = () => {
    const condition = conditionNames.find(
      (c) => c.id === selectedConditionId
    )?.condition_name;
    switch (condition) {
      case 'no_back_to_back':
        return 'Min # of Minutes In Between Sessions';
      case 'weekly_client_limit':
        return 'Max # of Clients in a Work Week (Sun-Fri)';
      case 'daily_client_limit':
        return 'Max # of Clients in a Day';
      default:
        return '';
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Set/Update Availability Condition</DialogTitle>
      <DialogContent>
        <Box sx={{ marginTop: 2 }}>
          <FormControl fullWidth variant="outlined" sx={{ marginTop: 2 }}>
            <InputLabel id="condition-select-label">
              Select Condition
            </InputLabel>
            <Select
              labelId="condition-select-label"
              id="condition-select"
              value={selectedConditionId}
              onChange={handleConditionChange}
              label="Select Condition"
              required
            >
              <MenuItem value="">
                <em>-- Select a Condition --</em>
              </MenuItem>
              {conditionNames.map((condition) => (
                <MenuItem key={condition.id} value={condition.id}>
                  {condition.condition_name
                    .replace(/_/g, ' ')
                    .replace(/\b\w/g, (l) => l.toUpperCase())}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {selectedConditionId && (
            <>
              <FormControl fullWidth variant="outlined" sx={{ marginY: 2 }}>
                <InputLabel id="condition-value-label">
                  {getConditionLabel()}
                </InputLabel>
                <Select
                  labelId="condition-value-label"
                  id="condition-value"
                  value={conditionValue}
                  onChange={(e) => setConditionValue(e.target.value)}
                  label={getConditionLabel()}
                >
                  {getConditionOptions().map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label="Start Date"
                  inputFormat="MM/dd/yyyy"
                  value={startDate}
                  onChange={(newValue) => setStartDate(newValue)}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth sx={{ marginBottom: 2 }} />
                  )}
                />
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label="End Date"
                  inputFormat="MM/dd/yyyy"
                  value={endDate}
                  onChange={(newValue) => setEndDate(newValue)}
                  disabled={noEndDate}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth sx={{ marginBottom: 2 }} />
                  )}
                />
              </LocalizationProvider>
              {/* <FormControlLabel
                control={
                  <Checkbox
                    checked={noEndDate}
                    onChange={(e) => setNoEndDate(e.target.checked)}
                  />
                }
                label="No End Date"
                sx={{ marginBottom: 2 }}
              /> */}

              <TextField
                label="Notes (Optional)"
                multiline
                rows={3}
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                fullWidth
                sx={{ marginBottom: 3 }}
              />
            </>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSaveClick}
          disabled={!selectedConditionId || !conditionValue}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(AddConditionDialog);
