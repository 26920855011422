import moment from 'moment';
import calendarService from '../../services/calendar.service';

// Helper function to check if the date is the next business day
export const getIsNextBusinessDay = (date, today) => {
  const dayOfWeek = date.getDay();

  // Check if the date is the next business day (Monday to Friday) and if it's after today
  return dayOfWeek >= 1 && dayOfWeek <= 5 && date > today;
};

// Helper function to calculate business days between two dates
export const calculateBusinessDays = (startDate, endDate) => {
  let count = 0;
  const current = new Date(startDate);
  const end = new Date(endDate);

  while (current <= end) {
    const dayOfWeek = current.getDay();
    if (dayOfWeek >= 1 && dayOfWeek <= 5) {
      count++;
    }
    current.setDate(current.getDate() + 1);
  }

  return count;
};

export const isDST = (date) => {
  const year = date.getFullYear();

  const secondSundayInMarch = new Date(year, 2, 8);

  secondSundayInMarch.setDate(8 + (7 - secondSundayInMarch.getDay()));

  const firstSundayInNovember = new Date(year, 10, 1);

  firstSundayInNovember.setDate(1 + (7 - firstSundayInNovember.getDay()));

  return date >= secondSundayInMarch && date < firstSundayInNovember;
};

export const addBusinessDays = (date, numDays) => {
  let count = 0;
  while (count < numDays) {
    date.setDate(date.getDate() + 1);
    const dayOfWeek = date.getDay();
    if (dayOfWeek >= 1 && dayOfWeek <= 5) {
      count++;
    }
  }
  return date;
};

export const getDocsDueTime = (docsDueDate) => {
  const today = new Date();
  const givenDateObj = new Date(docsDueDate);
  const todayYear = today.getFullYear();
  const todayMonth = today.getMonth();
  const todayDay = today.getDate();

  const givenYear = givenDateObj.getFullYear();
  const givenMonth = givenDateObj.getMonth();
  const givenDay = givenDateObj.getDate();

  if (
    todayYear === givenYear &&
    todayMonth === givenMonth &&
    todayDay === givenDay
  ) {
    return '4:00 PM';
  } else {
    return '10:00 AM';
  }
};

export const substractWorkdays = (d, numToSubstract) => {
  if (d && numToSubstract) {
    const nd = new Date(d);
    for (let i = 1; i <= numToSubstract; i++) {
      nd.setDate(nd.getDate() - 1);
      if (nd.getDay() === 6) {
        nd.setDate(nd.getDate() - 1);
      } else if (nd.getDay() === 0) {
        nd.setDate(nd.getDate() - 2);
      }
    }
    return nd;
  }
};

export const getFirstFuDate = (dt) => {
  if (dt) {
    const today = new Date();
    const apptDate = typeof dt === 'string' ? new Date(dt) : dt;

    const isNextBusinessDay = getIsNextBusinessDay(apptDate, today);

    const timeDifference = apptDate - today;

    const businessDaysDifference = calculateBusinessDays(today, apptDate);

    let firstFuContactDate;

    if (timeDifference >= 4 * 7 * 24 * 60 * 60 * 1000) {
      const apptDay = apptDate.getDay();
      const daysUntilPreviousMonday = apptDay > 1 ? apptDay - 1 : apptDay + 6;
      const threeMondaysBack = daysUntilPreviousMonday + 14;
      firstFuContactDate = new Date(
        apptDate.getTime() - threeMondaysBack * 24 * 60 * 60 * 1000
      );
    } else if (timeDifference >= 8 * 24 * 60 * 60 * 1000) {
      firstFuContactDate = addBusinessDays(
        today,
        Math.floor(businessDaysDifference / 2) - 1
      );
    } else if (
      timeDifference >= 2 * 24 * 60 * 60 * 1000 &&
      timeDifference <= 7 * 24 * 60 * 60 * 1000
    ) {
      firstFuContactDate = addBusinessDays(
        today,
        Math.floor(businessDaysDifference / 2) - 1
      );
    } else if (isNextBusinessDay && today.getHours() < 12) {
      firstFuContactDate = today;
    }

    return firstFuContactDate;
  }
};

export const compareSlotDateWithToday = (availableSlot) => {
  const availableDate = new Date(availableSlot.start_date_time);
  const today = new Date();

  const availableDateOnly = new Date(
    availableDate.getFullYear(),
    availableDate.getMonth(),
    availableDate.getDate()
  );

  // Get the date for the immediate next day
  const nextDay = new Date(today);
  nextDay.setDate(nextDay.getDate() + 1);
  const nextDayOnly = new Date(
    nextDay.getFullYear(),
    nextDay.getMonth(),
    nextDay.getDate()
  );

  // Check if it's after 12 PM today
  const isAfter12PM = today.getHours() >= 12;

  // Check if the available date is the immediate next day
  const isNextDay = availableDateOnly.getTime() === nextDayOnly.getTime();

  // Check if it's after 12 PM and the available date is the immediate next day
  if (isAfter12PM && isNextDay) {
    return true;
  }

  // Check if the available date is today (considering year, month, and day)
  if (
    availableDateOnly.getDate() === today.getDate() &&
    availableDateOnly.getMonth() === today.getMonth() &&
    availableDateOnly.getFullYear() === today.getFullYear()
  ) {
    return true;
  }

  return false;
};

// below function uses CalendarService

// The below is the utility function for getting and deleting birthday event
export const getBirthdayEventsAndDelete = async (name) => {
  console.log('Received name for deletion:', name); // Log the input name

  // Split the full name into parts based on spaces
  const nameParts = name.split(' ');
  console.log('Name parts for deletion:', nameParts); // Log the split name parts

  // Extract the initial of the first name
  const firstNameInitial = nameParts[0][0];
  console.log('First name initial for deletion:', firstNameInitial); // Log the initial of the first name

  // Find the index of the part that contains the comma
  const commaIndex = nameParts.findIndex((part) => part.includes(','));
  console.log('Index of comma:', commaIndex); // Log the index of the comma

  // Determine the last name based on the presence of a comma
  // If a comma exists, use the part immediately before the comma as the last name
  // Since the last name might include the comma, remove it for proper formatting
  const lastName =
    commaIndex > 0
      ? nameParts[commaIndex].replace(',', '')
      : nameParts[nameParts.length - 1];
  console.log('Assumed last name for deletion:', lastName); // Log the assumed last name

  // Check if the last name ends in 's' and adjust the possessive accordingly
  const possessiveSuffix = lastName.endsWith('s') ? "'" : "'s";
  console.log('Possessive suffix for deletion:', possessiveSuffix); // Log the possessive suffix

  // Construct the event title with the initial of the first name and correct possessive suffix
  const eventTitle = `${firstNameInitial}. ${lastName}${possessiveSuffix} Birthday`;
  console.log('Event title for deletion:', eventTitle); // Log the final event title

  try {
    const events = await calendarService.findEventByName(eventTitle);
    console.log('Found events for deletion:', events); // Log found events
    for (let i = 0; i < events.length; i++) {
      await calendarService.deleteEvent(events[i].id);
      console.log('Deleted event ID:', events[i].id); // Log the ID of each deleted event
    }
  } catch (error) {
    console.error('Error during event deletion:', error);
    if (error.response) {
      // Handle HTTP errors from Axios
      console.log('Error status:', error.response.status);
      console.log('Error data:', error.response.data);
    }
  }
};

// The below utility function for getting and deleting the License Event
export const getLicenseEventsAndDelete = async (name, state = null) => {
  console.log('Received name:', name);

  const nameParts = name.split(' ');
  console.log('Name parts:', nameParts);

  const firstNameInitial = nameParts[0][0];
  console.log('First name initial:', firstNameInitial);

  const commaIndex = nameParts.findIndex((part) => part.includes(','));
  console.log('Index of comma:', commaIndex);

  const lastName =
    commaIndex > 0
      ? nameParts[commaIndex].replace(',', '')
      : nameParts[nameParts.length - 1];
  console.log('Assumed last name:', lastName);

  const possessiveSuffix = lastName.endsWith('s') ? "'" : "'s";
  console.log('Possessive suffix:', possessiveSuffix);

  const eventTitle = `${firstNameInitial}. ${lastName}${possessiveSuffix} ${
    state ? `${state} License` : 'Professional Liability'
  } Expires`;
  console.log('Event title:', eventTitle);

  try {
    const events = await calendarService.findLicenseEventByName(eventTitle);
    console.log('Found events:', events);

    for (let event of events) {
      await calendarService.deleteLicenseEvent(event.id);
      console.log('Deleted event ID:', event.id);
    }
  } catch (error) {
    console.error('Error deleting calendar events:', error);
    if (error.response) {
      console.log('Error status:', error.response.status);
      console.log('Error data:', error.response.data);
    }
  }
};

// The below function is responsible for getting anniversary events and delete it
export const getAnniversaryEventsAndDelete = async (name) => {
  console.log('Received name:', name);

  const nameParts = name.split(' ');
  console.log('Name parts:', nameParts);

  const firstNameInitial = nameParts[0][0];
  console.log('First name initial:', firstNameInitial);

  const commaIndex = nameParts.findIndex((part) => part.includes(','));
  console.log('Index of comma:', commaIndex);

  const lastName =
    commaIndex > 0
      ? nameParts[commaIndex].replace(',', '')
      : nameParts[nameParts.length - 1];
  console.log('Assumed last name:', lastName);

  const possessiveSuffix = lastName.endsWith('s') ? "'" : "'s";
  console.log('Possessive suffix:', possessiveSuffix);

  const eventTitle = `${firstNameInitial}. ${lastName}${possessiveSuffix} Work Anniversary`;
  console.log('Event title:', eventTitle);

  try {
    const events = await calendarService.findAnniversaryEventByName(eventTitle);
    console.log('Found events:', events);

    for (let event of events) {
      await calendarService.deleteAnniversaryEvent(event.id);
      console.log('Deleted event ID:', event.id);
    }
  } catch (error) {
    console.error('Error deleting calendar events:', error);
    if (error.response) {
      console.log('Error status:', error.response.status);
      console.log('Error data:', error.response.data);
    }
  }
};

// below functions use moment

// The below function is responsible for creation and updation of Anniversary
export const createOrUpdateAnniversaryEvent = async (name, anniversaryDate) => {
  console.log('Received name:', name);

  const nameParts = name.split(' ');
  console.log('Name parts:', nameParts);

  const firstNameInitial = nameParts[0][0];
  console.log('First name initial:', firstNameInitial);

  const commaIndex = nameParts.findIndex((part) => part.includes(','));
  console.log('Index of comma:', commaIndex);

  const lastName =
    commaIndex > 0
      ? nameParts[commaIndex].replace(',', '')
      : nameParts[nameParts.length - 1];
  console.log('Assumed last name:', lastName);

  const possessiveSuffix = lastName.endsWith('s') ? "'" : "'s";
  console.log('Possessive suffix:', possessiveSuffix);

  const eventTitle = `${firstNameInitial}. ${lastName}${possessiveSuffix} Work Anniversary`;
  console.log('Event title:', eventTitle);

  const formattedAnniversaryDate = moment(anniversaryDate).format('YYYY-MM-DD');
  console.log('Formatted Anniversary Date:', formattedAnniversaryDate);

  const eventData = {
    summary: eventTitle,
    description: `Anniversary Date`,
    start: {
      date: formattedAnniversaryDate,
      timeZone: 'America/New_York',
    },
    end: {
      date: formattedAnniversaryDate,
      timeZone: 'America/New_York',
    },
    recurrence: ['RRULE:FREQ=YEARLY'],
  };

  console.log('Event data being sent:', eventData);

  try {
    const events = await calendarService.findAnniversaryEventByName(eventTitle);
    console.log('Found events:', events);

    const existingEvent = events.find((event) => event.summary === eventTitle);
    console.log('Existing event:', existingEvent);

    if (existingEvent) {
      for (let event of events) {
        await calendarService.deleteAnniversaryEvent(event.id);
        console.log('Deleted event ID:', event.id);
      }
    }

    await calendarService.createAnniversaryEvent(eventData);
    console.log('New event created successfully');
  } catch (error) {
    console.error('Error creating or updating calendar event:', error);
    if (error.response) {
      console.log('Error status:', error.response.status);
      console.log('Error data:', error.response.data);
    }
  }
};

export const formatDate = (dateString) => {
  if (!dateString) return ''; // Handle case where date is not available

  const formattedDate = moment(dateString).format('MM/DD/YY');
  return formattedDate;
};

export const formatDateCalendarFormat = (dateString) => {
  if (!dateString) return ''; // Handle case where date is not available

  const formattedDate = moment(dateString).format('YYYY-MM-DD');
  return formattedDate;
};

export const getDocsDue = (
  apptDate,
  step03FormValues,
  overRideDocDate = null
) => {
  /* 
  If overRideDocDate is here then simply print it
   */
  if (overRideDocDate) {
    const dueDate = overRideDocDate;
    let docsDue = moment
      .utc(dueDate)
      .subtract(4, 'hours')
      .format('ddd, MMM DD, YYYY');
    return `${docsDue} ${getDocsDueTime(docsDue)}`;
  } else if (apptDate) {
    const dueDate =
      step03FormValues.serviceGroup === 'Therapy'
        ? substractWorkdays(apptDate, 2)
        : getFirstFuDate(apptDate);
    let docsDue = moment.utc(dueDate).format('ddd, MMM DD, YYYY');
    return `${docsDue} ${getDocsDueTime(docsDue)}`;
  }
};

export const tsToLocalDtStr = (ts) => {
  return moment(ts).format('MM-DD-YYYY HH:mm a');
};

// The below is the utility function for creating or updating birthday event
export const createOrUpdateBirthdayEvent = async (name, dob) => {
  // Split the full name into parts based on spaces
  const nameParts = name.split(' ');

  // Extract the initial of the first name
  const firstNameInitial = nameParts[0][0];

  // Find the index of the part that contains the comma
  const commaIndex = nameParts.findIndex((part) => part.includes(','));
  console.log('Index of comma:', commaIndex); // Log the index of the comma

  // Determine the last name based on the presence of a comma
  // If a comma exists, use the part immediately before the comma as the last name
  // Since the last name "Holster," includes the comma, remove it for proper formatting
  const lastName =
    commaIndex > 0
      ? nameParts[commaIndex].replace(',', '')
      : nameParts[nameParts.length - 1];
  console.log('Assumed last name:', lastName); // Log the assumed last name

  // Check if the last name ends in 's' and adjust the possessive accordingly
  const possessiveSuffix = lastName.endsWith('s') ? "'" : "'s";
  console.log('Possessive suffix:', possessiveSuffix); // Log the possessive suffix

  // Construct the event title with the initial of the first name and correct possessive suffix
  const eventTitle = `${firstNameInitial}. ${lastName}${possessiveSuffix} Birthday`;
  console.log('Event title:', eventTitle); // Log the final event title

  const birthDate = moment(dob);
  const formattedStart = birthDate.format('YYYY-MM-DD'); // Ensure consistent formatting
  console.log('Formatted Start Date:', formattedStart); // Log the formatted start date

  const eventData = {
    summary: eventTitle,
    description: `Birthday of ${name}`,
    start: {
      date: formattedStart,
      timeZone: 'America/New_York', // Adding time zone
    },
    end: {
      date: formattedStart,
      timeZone: 'America/New_York', // Adding time zone
    },
    recurrence: ['RRULE:FREQ=YEARLY'],
  };

  console.log('Event data being sent:', eventData); // Log the full event data

  try {
    const events = await calendarService.findEventByName(eventTitle);
    console.log('Found events:', events); // Log found events
    // Finding the event from the database
    const existingEvent = events.find((event) => event.summary === eventTitle);
    console.log('Existing event:', existingEvent); // Log if an existing event is found

    // if event exists, delete the existing event
    if (existingEvent) {
      for (let i = 0; i < events.length; i++) {
        await calendarService.deleteEvent(events[i].id);
        console.log('Deleted event ID:', events[i].id); // Log the ID of each deleted event
      }
    }
    // creating the new event
    await calendarService.createEvent(eventData);
    console.log('New event created successfully'); // Log successful creation
  } catch (error) {
    console.error('Error creating or updating calendar event:', error);
    if (error.response) {
      // Handle HTTP errors from Axios
      console.log('Error status:', error.response.status);
      console.log('Error data:', error.response.data);
    }
  }
};

// The below is the utility function for creating or updating license. push
export const createOrUpdateLicenseEvent = async (
  name,
  expirationDate,
  state = null
) => {
  console.log('Received name:', name);

  const nameParts = name.split(' ');
  console.log('Name parts:', nameParts);

  const firstNameInitial = nameParts[0][0];
  console.log('First name initial:', firstNameInitial);

  const commaIndex = nameParts.findIndex((part) => part.includes(','));
  console.log('Index of comma:', commaIndex);

  const lastName =
    commaIndex > 0
      ? nameParts[commaIndex].replace(',', '')
      : nameParts[nameParts.length - 1];
  console.log('Assumed last name:', lastName);

  const possessiveSuffix = lastName.endsWith('s') ? "'" : "'s";
  console.log('Possessive suffix:', possessiveSuffix);

  const eventTitle = `${firstNameInitial}. ${lastName}${possessiveSuffix} ${
    state ? `${state} License` : 'Professional Liability'
  } Expires`;
  console.log('Event title:', eventTitle);

  const formattedExpirationDate = moment
    .utc(expirationDate)
    .format('YYYY-MM-DD');
  console.log('Formatted Expiration Date:', formattedExpirationDate);

  const eventData = {
    summary: eventTitle,
    description: `${
      state ? `${state} License Expires` : 'Professional Liability'
    }`,
    start: {
      date: formattedExpirationDate,
      timeZone: 'America/New_York',
    },
    end: {
      date: formattedExpirationDate,
      timeZone: 'America/New_York',
    },
  };

  try {
    const events = await calendarService.findLicenseEventByName(eventTitle);
    console.log('Found events:', events);

    const existingEvent = events.find((event) => event.summary === eventTitle);
    console.log('Existing event:', existingEvent);

    if (existingEvent) {
      for (let event of events) {
        await calendarService.deleteLicenseEvent(event.id);
        console.log('Deleted event ID:', event.id);
      }
    }

    await calendarService.createLicenseEvent(eventData);
    console.log('New event created successfully');
  } catch (error) {
    console.error('Error creating or updating calendar event:', error);
    if (error.response) {
      console.log('Error status:', error.response.status);
      console.log('Error data:', error.response.data);
    }
  }
};

/*
 The below is the utility function for sorting the slots.
 If it's multiSlot and created in different order than always 
 earliest appointment slot should come first than the oldest. 
 The function is implementing a hacky solution, because JS always 
 maintain the keys in correct order 
 */
export const sortAvailableSlots = (availableSlot) => {
  const keys = Object.keys(availableSlot);

  // Make sure there are exactly two slots to swap
  if (keys.length === 2) {
    const [key1, key2] = keys;

    // Swap the values
    const temp = availableSlot[key1];
    availableSlot[key1] = availableSlot[key2];
    availableSlot[key2] = temp;

    // Prefix the keys to prevent numeric sorting
    return {
      [`slot_${key1}`]: availableSlot[key1],
      [`slot_${key2}`]: availableSlot[key2],
    };
  }

  // If not exactly two slots, return as is
  return availableSlot;
};
