import React from 'react';

// Function to conditionally render Popover content based on selected insurance
const RenderPopoverContent = ({ popoverSelectedInsurance }) => {
  switch (popoverSelectedInsurance) {
    case 'Aetna':
    case 'Aetna EAP':
    case 'Aetna Medicare Advantage':
      return (
        <div
          style={{
            minWidth: '500px',
            minHeight: '50px',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <span style={{ display: 'inline-block' }}>
            Link for Aetna (Payer ID - 60054) Benefits Info
          </span>
          <a
            href="https://apps.availity.com/availity/web/public.elegant.login?p:lm=1695867050"
            target="_blank"
            rel="noopener noreferrer"
            style={{ display: 'inline-block' }}
          >
            &nbsp;&nbsp;Click Here
          </a>
        </div>
      );
    case 'BCBS':
      return (
        <div
          style={{
            minWidth: '500px',
            minHeight: '150px',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div style={{ marginBottom: '10px' }}>
            <span style={{ display: 'inline-block' }}>
              Link for BCBS (Payer ID SB690 or SB580) Benefits Info
            </span>
            <a
              href="https://provider.carefirst.com/prv/#/login"
              target="_blank"
              rel="noopener noreferrer"
              style={{ display: 'inline-block' }}
            >
              &nbsp;&nbsp;Click Here
            </a>
          </div>

          <div style={{ marginBottom: '10px' }}>
            <span style={{ display: 'inline-block' }}>
              Link for Carefirst Administrators (Payer ID 75191) Benefits Info
            </span>
            <a
              href="https://ebixhub.ebix.com/sso/client/clientlogin.aspx?client=cfablue"
              target="_blank"
              rel="noopener noreferrer"
              style={{ display: 'inline-block' }}
            >
              &nbsp;&nbsp;Click Here
            </a>
          </div>

          <div>
            <span style={{ display: 'inline-block' }}>
              BCBS Blue Card - Find Plan With First 3 Letters
            </span>
            <a
              href="https://www.bcbs.com/member-services"
              target="_blank"
              rel="noopener noreferrer"
              style={{ display: 'inline-block' }}
            >
              &nbsp;&nbsp;Click Here
            </a>
          </div>
        </div>
      );

    case 'CIGNA':
    case 'CIGNA EAP':
      return (
        <div
          style={{
            minWidth: '500px',
            minHeight: '50px',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <span style={{ display: 'inline-block' }}>
            Link for Cigna / Evernorth (Payer ID 62308) Benefits Info
          </span>
          <a
            href=" https://provider.evernorth.com/app/login"
            target="_blank"
            rel="noopener noreferrer"
            style={{ display: 'inline-block' }}
          >
            &nbsp;&nbsp;Click Here
          </a>
        </div>
      );

    case 'Johns Hopkins EHP':
      return (
        <div
          style={{
            minWidth: '500px',
            minHeight: '50px',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <span style={{ display: 'inline-block' }}>
            Link for Johns Hopkins EHP (Payer ID 52189) Benefits Info
          </span>
          <a
            href=" https://ehp.healthtrioconnect.com/app/index.page"
            target="_blank"
            rel="noopener noreferrer"
            style={{ display: 'inline-block' }}
          >
            &nbsp;&nbsp;Click Here
          </a>
        </div>
      );

    case 'Johns Hopkins USFHP':
      return (
        <div
          style={{
            minWidth: '500px',
            minHeight: '50px',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <span style={{ display: 'inline-block' }}>
            Link for Johns Hopkins USFHP (Payer ID 52123) Benefits Info
          </span>
          <a
            href=" https://usfhp.healthtrioconnect.com/app/index.page"
            target="_blank"
            rel="noopener noreferrer"
            style={{ display: 'inline-block' }}
          >
            &nbsp;&nbsp;Click Here
          </a>
        </div>
      );

    case 'Medical Assistance':
      return (
        <div
          style={{
            minWidth: '500px',
            minHeight: '50px',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <span style={{ display: 'inline-block' }}>
            Link for Medical Assistance / Medicaid (Payer ID BHOMD) Benefits
            Info
          </span>
          <a
            href="https://providerconnect.carelonbehavioralhealth.com/pc/eProvider/providerHome.do"
            target="_blank"
            rel="noopener noreferrer"
            style={{ display: 'inline-block' }}
          >
            &nbsp;&nbsp;Click Here
          </a>
        </div>
      );

    case 'Medicare':
      return (
        <div
          style={{
            minWidth: '500px',
            minHeight: '50px',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <span style={{ display: 'inline-block' }}>
            Link for Medicare Benefits (Payer ID Bal/Col 00901 | SS 00903) Info
          </span>
          <a
            href="https://www.novitasphere.com/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ display: 'inline-block' }}
          >
            &nbsp;&nbsp;Click Here
          </a>
        </div>
      );

    case 'Tricare Standard/Indeminity (NOT Prime)':
      return (
        <div
          style={{
            minWidth: '500px',
            minHeight: '50px',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <span style={{ display: 'inline-block' }}>
            Link for Tricare (Payer ID 99727) Benefits Info
          </span>
          <a
            href="https://infocenter.humana-military.com/provider/service/account/login"
            target="_blank"
            rel="noopener noreferrer"
            style={{ display: 'inline-block' }}
          >
            &nbsp;&nbsp;Click Here
          </a>
        </div>
      );

    default:
      return 'Select Insurance from Dropdown To View Link';
  }
};

export default RenderPopoverContent;
