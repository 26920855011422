import axios from 'axios';
import authHeader from './auth-header';
const API_URL = `${process.env.REACT_APP_API_HOST}/api/`;

const getDIPStatus = (body) =>
  axios.post(`${API_URL}clients/DIPStatus`, body, {
    headers: authHeader(),
  });

const getClients = (
  includeInactive = false,
  page,
  rowsPerPage,
  clientStatus = true,
  searchAPICall = false,
  firstName = '',
  lastName = '',
  dob = '',
  email = '',
  phone = '',
  accountNumber = '',
  startsWithFirstName = true,
  startsWithLastName = true,
  therapistFilter = [] // <-- new parameter
) => {
  const queryParams = [
    `page=${page}`,
    `rowsPerPage=${rowsPerPage}`,
    `clientStatus=${clientStatus}`,
    `searchAPICall=${searchAPICall}`,
  ];

  if (includeInactive) {
    queryParams.push('includeInactive=true');
  }
  if (firstName) {
    queryParams.push(`firstName=${encodeURIComponent(firstName)}`);
    queryParams.push(`startsWithFirstName=${startsWithFirstName}`);
  }
  if (lastName) {
    queryParams.push(`lastName=${encodeURIComponent(lastName)}`);
    queryParams.push(`startsWithLastName=${startsWithLastName}`);
  }
  if (dob) {
    queryParams.push(`dob=${encodeURIComponent(dob)}`);
  }
  if (email) {
    queryParams.push(`email=${encodeURIComponent(email)}`);
  }
  if (phone) {
    queryParams.push(`phone=${encodeURIComponent(phone)}`);
  }
  if (accountNumber) {
    queryParams.push(`accountNumber=${encodeURIComponent(accountNumber)}`);
  }
  // Add therapist filter if it has values
  if (therapistFilter && therapistFilter.length > 0) {
    // Pass as JSON string; backend must parse it.
    queryParams.push(
      `therapistFilter=${encodeURIComponent(JSON.stringify(therapistFilter))}`
    );
  }

  const queryString = queryParams.join('&');
  const url = `${API_URL}clients?${queryString}`;
  return axios.get(url, { headers: authHeader() });
};

const getMyClients = () =>
  axios.get(`${API_URL}clients`, { headers: authHeader() });

const getClientsByProvider = (providerId) =>
  axios.get(`${API_URL}clients/provider/${providerId}`, {
    headers: authHeader(),
  });

const getClientsOfSupervisees = () => {
  return axios.get(API_URL + 'clients/supervisees', {
    headers: authHeader(),
  });
};

const addClientToStillAwol = (clientUpdates) => {
  return axios.post(`${API_URL}clients/addClientToStillAwol`, clientUpdates, {
    headers: authHeader(),
  });
};

const addClientToReturned = (clientUpdates) => {
  return axios.post(`${API_URL}clients/addClientToReturned`, clientUpdates, {
    headers: authHeader(),
  });
};

const updateClientColumns = (clientUpdates) => {
  return axios.put(`${API_URL}clients/updateClientColumns`, clientUpdates, {
    headers: authHeader(),
  });
};

const updateClientCompleteQuestionnaire = (selectedClientUniqueKey, body) => {
  return axios.post(
    `${API_URL}clients/updateClientCompleteQuestionnaire/${selectedClientUniqueKey}`,
    body,
    {
      headers: authHeader(),
    }
  );
};

const updateClientScore = (selectedClientUniqueKey, body) => {
  return axios.post(
    `${API_URL}clients/updateClientScore/${selectedClientUniqueKey}`,
    body,
    {
      headers: authHeader(),
    }
  );
};

const fetchClientsWithCriteria = (providerId, status = [], insurances = []) => {
  // Initialize URLSearchParams to handle query parameters
  let params = new URLSearchParams();
  // Check if a specific provider is selected and not "ALL"
  if (providerId && providerId !== '1001') {
    // Assuming '1001' represents "ALL"
    params.append('providerId', providerId);
  }
  // Handle status if provided; assuming status is an array
  status.forEach((s) => params.append('status', s));
  // Handle insurances if provided; assuming insurances is an array
  insurances.forEach((insurance) => params.append('insurance', insurance));
  // Construct the full URL with query parameters
  const fullUrl = `${API_URL}clients/criteria?${params.toString()}`;
  // Make the GET request with axios
  return axios.get(fullUrl, { headers: authHeader() });
};

const addAdminNameToClient = (clientUpdates) => {
  return axios.post(`${API_URL}clients/addAdminName`, clientUpdates, {
    headers: authHeader(),
  });
};

const getProspectiveClientDetails = (uniqueId) =>
  axios.get(`${API_URL}getProspectiveClientsDetails/${uniqueId}`);

const getDIPAllClients = () => {
  return axios.get(`${API_URL}clients/getAllDIPClients`, {
    headers: authHeader(),
  });
};

const updateSentQuestionnaireDate = (selectedClientUniqueKey, body) => {
  return axios.post(
    `${API_URL}clients/updateSentQuestionnaireDate/${selectedClientUniqueKey}`,
    body,
    {
      headers: authHeader(),
    }
  );
};

const updateDiagnosisStatus = (selectedClientUniqueKey, body) => {
  return axios.post(
    `${API_URL}clients/updateDiagnosisStatus/${selectedClientUniqueKey}`,
    body,
    {
      headers: authHeader(),
    }
  );
};

// eslint-disable-next-line
export default {
  getDIPStatus,
  getClients,
  getProspectiveClientDetails,
  getMyClients,
  getClientsByProvider,
  getClientsOfSupervisees,
  addClientToStillAwol,
  addAdminNameToClient,
  addClientToReturned,
  updateClientColumns,
  updateClientCompleteQuestionnaire,
  updateClientScore,
  fetchClientsWithCriteria,
  getDIPAllClients,
  updateSentQuestionnaireDate,
  updateDiagnosisStatus,
};
