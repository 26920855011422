import { useState } from 'react';

/**
 * A customHook for displaying the Note over the slot.
 * It is used in availability, Reminders and Scheduling
 */
const useNotePopover = (slot) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [content, setContent] = useState('');

  const handleIconClick = (event) => {
    if (anchorEl) {
      setAnchorEl(null);
      setContent('');
      return;
    }

    if (!slot || !slot.note) {
      setContent('No note available.'); // Or handle as you see fit
      setAnchorEl(event.currentTarget); //still need to set
      return;
    }
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setContent(slot.note);
  };

  const handlePopoverClose = (event) => {
    setAnchorEl(null);
    setContent('');
    event.stopPropagation();
  };

  const open = Boolean(anchorEl);

  return { anchorEl, content, open, handleIconClick, handlePopoverClose };
};

export default useNotePopover;
