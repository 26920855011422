import axios from 'axios';
import authHeader from './auth-header';

const API_BASE_URL = process.env.REACT_APP_API_HOST;

// ----- Condition Names (avail_condition_names) -----

// Get all condition names
const getAllConditionNames = () => {
  return axios.get(`${API_BASE_URL}/api/conditions/names/`, {
    headers: authHeader(),
  });
};

// Get a single condition description by conditionId (NEW)
const getConditionDescription = (conditionId) => {
  return axios.get(`${API_BASE_URL}/api/conditions/names/${conditionId}`, {
    headers: authHeader(),
  });
};

// Get the count of active conditions for a provider
const getActiveConditionCount = async (providerId) => {
  return axios
    .get(`${API_BASE_URL}/api/conditions/active-count/${providerId}`, {
      headers: authHeader(),
    })
    .then((response) => response.data.count)
    .catch((error) => {
      console.error('Error fetching active condition count:', error);
      return 0; // Default to 0 in case of an error
    });
};

// Create a condition name
const createConditionName = (conditionData) => {
  return axios.post(`${API_BASE_URL}/api/conditions/names/`, conditionData, {
    headers: authHeader(),
  });
};

// Update a condition name
const updateConditionName = (id, conditionData) => {
  return axios.put(
    `${API_BASE_URL}/api/conditions/names/${id}`,
    conditionData,
    {
      headers: authHeader(),
    }
  );
};

// Delete a condition name
const deleteConditionName = (id) => {
  return axios.delete(`${API_BASE_URL}/api/conditions/names/${id}`, {
    headers: authHeader(),
  });
};

// ----- Condition Configs (avail_conditions_config) -----

// Get all condition configs
const getAllConditionConfigs = (providerId) => {
  return axios.get(`${API_BASE_URL}/api/conditions/configs/`, {
    headers: authHeader(),
  });
};

// Create a condition config
const createConditionConfig = (configData) => {
  return axios.post(`${API_BASE_URL}/api/conditions/configs/`, configData, {
    headers: authHeader(),
  });
};

// Update a condition config
const updateConditionConfig = (id, configData) => {
  return axios.put(`${API_BASE_URL}/api/conditions/configs/${id}`, configData, {
    headers: authHeader(),
  });
};

// Delete a condition config
const deleteConditionConfig = (id) => {
  return axios.delete(`${API_BASE_URL}/api/conditions/configs/${id}`, {
    headers: authHeader(),
  });
};

const getConditionConfigWithUsage = (providerId) => {
  return axios.get(
    `${API_BASE_URL}/api/conditions/configs/with-usage/${providerId}`,
    {
      headers: authHeader(),
    }
  );
};

// New function to get blocking slots with status "active"
export const getBlockingSlots = (providerId, slotId) => {
  const url = `${API_BASE_URL}/api/providers/${providerId}/blockingSlots/${slotId}`;
  console.log('🔹[conditionService] GET =>', url);

  return axios.get(url, {
    headers: authHeader(),
  });
};

const conditionsService = {
  // Condition Names
  getAllConditionNames,
  getConditionDescription,
  createConditionName,
  updateConditionName,
  deleteConditionName,
  // Condition Configs
  getAllConditionConfigs,
  createConditionConfig,
  updateConditionConfig,
  deleteConditionConfig,
  getConditionConfigWithUsage,
  getActiveConditionCount,
  getBlockingSlots,
};

export default conditionsService;
