export const Socket_IO_Appointment_Cancel_Event = {
  slots_cancelled: 'slots_cancelled',
  slot_history_updated: 'slot_history_updated',
  appointment_dockhealth_task_updated: 'appointment_dockhealth_task_updated',
};

/**
 * Appointment Cancel Event Intial Steps
 */
export const Socket_IO_Appointment_Cancel_Event_Initial_Steps = [
  {
    stepId: Socket_IO_Appointment_Cancel_Event.slots_cancelled,
    detail: 'Cancelling slots from the database.',
    status: 'pending',
  },
  {
    stepId: Socket_IO_Appointment_Cancel_Event.slot_history_updated,
    detail: 'Slot history getting updated in database',
    status: 'pending',
  },
  {
    stepId:
      Socket_IO_Appointment_Cancel_Event.appointment_dockhealth_task_updated,
    detail: 'Appointment DockHealth Tasks getting updated',
    status: 'pending',
  },
];

export const Socket_IO_Appointment_Update_Events = {
  slots_updated: 'slots_updated',
  slot_history_updated: 'slot_history_updated',
  appointment_dockhealth_task_updated: 'appointment_dockhealth_task_updated',
  email_sent: 'email_sent',
};

/**
 * Appointment Update Events Initial Steps
 */
export const Socket_IO_Appointment_Update_Event_Initial_Steps = [
  {
    stepId: Socket_IO_Appointment_Update_Events.slots_updated,
    detail: 'Slots getting updated in database.',
    status: 'pending',
  },
  {
    stepId: Socket_IO_Appointment_Update_Events.slot_history_updated,
    detail: 'Slot history getting updated in database',
    status: 'pending',
  },
  {
    stepId:
      Socket_IO_Appointment_Update_Events.appointment_dockhealth_task_updated,
    detail: 'Appointment DockHealth Tasks getting updated',
    status: 'pending',
  },
  {
    stepId: Socket_IO_Appointment_Update_Events.email_sent,
    detail: 'Email getting Sent out',
    status: 'pending',
  },
];

export const Socket_IO_Appointment_Create_Events = {
  duplicate_appointment_check: 'duplicate_appointment_check',
  slots_updated: 'slots_updated',
  appointment_entry_created: 'appointment_entry_created',
  appointment_email_sent: 'appointment_email_sent',
  appointment_dockhealth_task_created: 'appointment_dockhealth_task_created',
  appointment_client_created: 'appointment_client_created',
};

/**
 * Appointment Create Event Initial steps
 */
export const Socket_IO_Appointment_Create_Event_Initial_Steps = [
  {
    stepId: Socket_IO_Appointment_Create_Events.duplicate_appointment_check,
    detail: 'Checking Duplicate Appointment.',
    status: 'pending',
  },
  {
    stepId: Socket_IO_Appointment_Create_Events.slots_updated,
    detail: 'Slots getting updated in database',
    status: 'pending',
  },
  {
    stepId: Socket_IO_Appointment_Create_Events.appointment_entry_created,
    detail: 'Appointment entry getting created',
    status: 'pending',
  },
  {
    stepId: Socket_IO_Appointment_Create_Events.appointment_email_sent,
    detail: 'Appointment creation email getting sent',
    status: 'pending',
  },
  {
    stepId:
      Socket_IO_Appointment_Create_Events.appointment_dockhealth_task_created,
    detail: 'Appointment DockHealth Tasks getting created',
    status: 'pending',
  },
  {
    stepId: Socket_IO_Appointment_Create_Events.appointment_client_created,
    detail: 'Appointment Client entry getting created',
    status: 'pending',
  },
];
