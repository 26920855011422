/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Tabs, Tab, Box } from '@mui/material';

import 'react-big-calendar/lib/css/react-big-calendar.css';

import styles from '../../styles/MainAvailability.module.css';

import { AvailabilityConditions } from './AvailabilityConditions';
import { MainAvailability } from './MainAvailability';
import { isAdmin } from '../../common/utils/RoleUtils';

/* 
----------------------------------------------------------------------------------
TABS COMPONENT WITH CUSTOM LABELS/STYLING
----------------------------------------------------------------------------------
*/

// A small helper component to render a title + description inside a tab label
const TabLabelWithDescription = ({ title, description }) => (
  <div style={{ textAlign: 'center' }}>
    <div style={{ fontSize: '1rem' }}>{title}</div>
    <div style={{ fontSize: '0.7rem' }}>{description}</div>
  </div>
);

export default function AvailabilityContainer() {
  const { user: currentUser } = useSelector((state) => state.auth);

  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        centered
        style={{
          paddingTop: '10px',
          paddingBottom: '10px',
          marginBottom: '20px',
          marginTop: '20px',
        }}
        sx={{
          '& button.Mui-selected': { backgroundColor: '#1c405c' },
          borderBottom: 1,
          borderColor: 'divider',
        }}
      >
        <Tab
          label={
            <TabLabelWithDescription
              title="Update Availability"
              description="View Practice Schedule & Make Individual Updates"
            />
          }
          className={activeTab === 0 ? styles.customTabSelected : ''}
          classes={{ root: styles.customTabRoot }}
        />

        {isAdmin(currentUser) && (
          <Tab
            label={
              <TabLabelWithDescription
                title="Availability Conditions"
                description="Create, View & Edit Availability Conditions"
              />
            }
            className={activeTab === 1 ? styles.customTabSelected : ''}
            classes={{ root: styles.customTabRoot }}
          />
        )}
      </Tabs>

      {activeTab === 0 && (
        <Box sx={{ padding: 2 }}>
          <MainAvailability />
        </Box>
      )}

      {activeTab === 1 && (
        <Box sx={{ padding: 2 }}>
          <AvailabilityConditions />
        </Box>
      )}
    </Box>
  );
}
