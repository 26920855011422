import React, { useEffect, memo } from 'react';
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
  Button,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { parseISO } from 'date-fns';
import { adminService } from '../../services';
import { createOrUpdateBirthdayEvent } from '../../common/utils/DateUtils.js';

import { additionalLanguagesSpokenOptions } from '../../common/utils/ProviderConfigUtils.js';

import styles from '../../styles/ProviderConfig.module.css';

// to set the date in correct format
export const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const states = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];

const relationshipOptions = ['Parent', 'Spouse', 'Sibling', 'Friend', 'Other'];

const AdminPersonalInfo = (props) => {
  const [dob, setDob] = React.useState('');
  const [additionalLanguagesSpoken, setAdditionalLanguagesSpoken] =
    React.useState([]);
  const [gender, setGender] = React.useState('');
  const [ss_num, setSS_num] = React.useState('');
  const [personalEmail, setPersonalEmail] = React.useState('');
  const [mobileNumber, setMobileNumber] = React.useState('');
  const [ext, setExt] = React.useState('');
  const [race, setRace] = React.useState('');
  const [address1, setAddress1] = React.useState('');
  const [address2, setAddress2] = React.useState('');
  const [city, setCity] = React.useState('');
  const [state, setState] = React.useState('');
  const [zip, setZip] = React.useState('');

  const [emergencyContactName, setEmergencyContactName] = React.useState('');
  const [emergencyContactRelationship, setEmergencyContactRelationship] =
    React.useState('');
  const [emergencyContactPhone, setEmergencyContactPhone] = React.useState('');

  const [customRelationship, setCustomRelationship] = React.useState('');

  const [adminName, setAdminName] = React.useState('');

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const date = moment(
      props.selectedUserDetail?.date_of_birth?.split('T')[0] ?? ''
    ).format('YYYY-MM-DD');
    setDob(date ?? '');
    setSS_num(props.selectedUserDetail?.ss_num ?? '');

    setAdditionalLanguagesSpoken(
      props?.selectedUserDetail?.additional_Languages_spoken ?? []
    );
    setGender(
      props.selectedUserDetail?.gender === 'm' ||
        props.selectedUserDetail?.gender === 'M'
        ? 'Male'
        : 'Female' ?? ''
    );

    setRace(props.selectedUserDetail?.race ?? '');

    setAdminName(
      props.selectedUserDetail?.admin_name ??
        props.selectedUserDetail?.username ??
        ''
    );

    setPersonalEmail(props.selectedUserDetail?.personal_email ?? '');
    setMobileNumber(props.selectedUserDetail?.mobile_number ?? '');
    setExt(props.selectedUserDetail?.ext ?? '');
    setAddress1(props.selectedUserDetail?.address1 ?? '');
    setAddress2(props.selectedUserDetail?.address2 ?? '');
    setCity(props.selectedUserDetail?.city ?? '');
    setState(props.selectedUserDetail?.state ?? '');
    setZip(props.selectedUserDetail?.zip ?? '');
    setEmergencyContactName(
      props?.selectedUserDetail?.emergencyContactName ?? ''
    );
    setEmergencyContactRelationship(
      props?.selectedUserDetail?.emergencyContactRelationship ?? ''
    );
    setEmergencyContactPhone(
      props?.selectedUserDetail?.emergencyContactPhone ?? ''
    );
  }, [props.selectedUserDetail]);

  const handleLanguagesChange = (event) => {
    const {
      target: { value },
    } = event;
    setAdditionalLanguagesSpoken(
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const handleOnSubmit = async (event) => {
    event.preventDefault();
    let missingFields = [];

    // Extract admin email directly from props
    const adminEmail = props.selectedUser?.admin_email;

    // Check for the presence of admin email
    if (!adminEmail) {
      missingFields.push('Admin Email');
    }

    // Assuming dob, gender, race, etc., are state variables in your component
    if (!dob || dob === 'Invalid date') {
      missingFields.push('Date of Birth');
    }

    if (!gender) {
      missingFields.push('Gender');
    }

    if (!race) {
      missingFields.push('Race');
    }

    if (!personalEmail) {
      missingFields.push('Personal Email');
    }

    if (!ext) {
      missingFields.push('Extension');
    }

    if (!address1) {
      missingFields.push('Address 1');
    }

    if (!city) {
      missingFields.push('City');
    }

    if (!state) {
      missingFields.push('State');
    }

    if (!mobileNumber) {
      missingFields.push('Mobile Number');
    }

    if (adminName.length === 0) {
      missingFields.push('Admin Name');
    }

    // If there are any missing fields, show an alert and log the missing fields
    if (missingFields.length > 0) {
      enqueueSnackbar(
        `Please fill out the required fields: ${missingFields.join(', ')}.`,
        {
          variant: 'warning',
          timeout: 3000,
        }
      );
      return; // Stop the function here to prevent submitting the form with missing fields
    }

    // If all fields are present, proceed with form submission
    try {
      props.showLoading(); // showing loading
      const response = await adminService.updateAdminDetails(
        props.selectedUser.id,
        {
          admin_email: adminEmail, // Admin email directly used from props
          dob,
          gender,
          race,
          personalEmail,
          mobileNumber,
          ext,
          ss_num,
          adminName,
          address1,
          address2,
          city,
          state,
          zip,
          additionalLanguagesSpoken,
          emergencyContactName,
          emergencyContactRelationship:
            emergencyContactRelationship !== 'Other'
              ? emergencyContactRelationship
              : customRelationship,
          emergencyContactPhone,
          // Add other fields as needed
        }
      );

      if (
        response &&
        response.data &&
        response.data.message === 'Admin details updated successfully.'
      ) {
        enqueueSnackbar('Admin details updated successfully.', {
          variant: 'success',
          timeout: 3000,
        });

        // Assuming calendarId is stored in environment or passed as a prop
        if (dob) {
          const formattedDOB = moment(dob).format('YYYY-MM-DD'); //
          createOrUpdateBirthdayEvent(adminName, formattedDOB)
            .then((msg) => {
              enqueueSnackbar('Birthday event created successfully', {
                variant: 'success',
                timeout: 3000,
              });
            })
            .catch((err) => {
              console.error('Error creating or updating birthday event:', err);
              enqueueSnackbar(`Failed to create or update birthday event.`, {
                variant: 'error',
                timeout: 3000,
              });
            });
        }
      } else {
        // Handle case where the response does not indicate success
        enqueueSnackbar('Failed to update admin details. Please try again.', {
          variant: 'error',
          timeout: 3000,
        });
      }
    } catch (error) {
      console.error('handleOnSubmit.error', error);
      enqueueSnackbar('An error occurred while updating admin details.', {
        variant: 'error',
        timeout: 3000,
      });
    } finally {
      props.hideLoading();
    }
  };

  return (
    <React.Fragment>
      {props.isAdmin && (
        <Typography variant="body1" className={styles.providerNotice1}>
          All sections of the admin configuration is read-only. Please reach out
          to Levi or Victoria if there is any information that you would like to
          update.
        </Typography>
      )}

      <div className={styles.tabsContentContainer}>
        <Grid container spacing={3} className={styles.gridItemTextField}>
          {
            <Grid size={{ xs: 12, sm: 6, md: 3 }}>
              <TextField
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                autoComplete="off"
                fullWidth
                variant="outlined"
                id="admin_name"
                label="Admin Name"
                value={adminName}
                onChange={(e) => {
                  setAdminName(e.target.value);
                }}
                // disabled={props.isProvider}
              />
            </Grid>
          }

          {dob && dob !== undefined && (
            <Grid size={{ xs: 12, sm: 6, md: 3 }}>
              <DatePicker
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                autoOk
                autoComplete="off"
                name="date_of_birth"
                fullWidth
                variant="inline"
                inputVariant="outlined"
                label="Date of Birth"
                format="M/d/yy"
                defaultValue={parseISO(dob)}
                value={parseISO(dob)}
                InputAdornmentProps={{ position: 'start' }}
                onChange={(date) => {
                  const updatedDate = moment(date).format('yyyy-MM-DD');
                  setDob(updatedDate);
                }}
              />
            </Grid>
          )}

          {
            <Grid size={{ xs: 12, sm: 6, md: 3 }}>
              <TextField
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                autoComplete="off"
                fullWidth
                variant="outlined"
                id="ss_num"
                label="SS #"
                value={ss_num}
                onChange={(event) => {
                  setSS_num(event.target.value);
                }}
                // disabled={props.isProvider}
              />
            </Grid>
          }

          {
            <Grid size={{ xs: 12, sm: 6, md: 3 }}>
              <FormControl
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                sx={{ width: '100%' }}
              >
                <InputLabel id="demo-multiple-name-label">Gender</InputLabel>

                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={gender}
                  label="Gender"
                  // disabled={props.isProvider}
                  onChange={(event) => {
                    setGender(event.target.value);
                  }}
                >
                  <MenuItem value={'Male'}>Male</MenuItem>
                  <MenuItem value={'Female'}>Female</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          }

          {
            <Grid size={{ xs: 12, sm: 6, md: 3 }}>
              <FormControl
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                sx={{ width: '100%' }}
              >
                <InputLabel id="demo-simple-select-label">Race</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={race}
                  label="Race"
                  // disabled={props.isProvider}
                  onChange={(event) => {
                    setRace(event.target.value);
                  }}
                >
                  <MenuItem value={'White'}>White</MenuItem>
                  <MenuItem value={'African American'}>
                    African American
                  </MenuItem>
                  <MenuItem value={'Asian'}>Asian</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          }

          {
            <Grid size={{ xs: 12, sm: 6, md: 3 }}>
              <TextField
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                autoComplete="off"
                fullWidth
                variant="outlined"
                id="admin_email"
                label="Admin Email"
                value={props.selectedUser?.admin_email ?? ''}
                // disabled={props.isProvider}
              />
            </Grid>
          }

          {
            <Grid size={{ xs: 12, sm: 6, md: 3 }}>
              <TextField
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                autoComplete="off"
                fullWidth
                variant="outlined"
                id="personal_email"
                label="Personal Email"
                value={personalEmail}
                onChange={(event) => {
                  setPersonalEmail(event.target.value);
                }}
                // disabled={props.isProvider}
              />
            </Grid>
          }

          {
            <Grid size={{ xs: 12, sm: 6, md: 3 }}>
              <TextField
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                autoComplete="off"
                fullWidth
                variant="outlined"
                id="mobile_number"
                label="Mobile Number"
                value={mobileNumber}
                onChange={(event) => {
                  setMobileNumber(event.target.value);
                }}
                // disabled={props.isProvider}
              />
            </Grid>
          }

          {
            <Grid size={{ xs: 12, sm: 6, md: 3 }}>
              <TextField
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                autoComplete="off"
                fullWidth
                variant="outlined"
                id="ext"
                label="Ext"
                value={ext}
                onChange={(event) => {
                  setExt(event.target.value);
                }}
                // disabled={props.isProvider}
              />
            </Grid>
          }

          {
            <Grid size={{ xs: 12, sm: 6, md: 3 }}>
              <FormControl
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                sx={{ width: '100%' }}
              >
                <InputLabel id="demo-multiple-name-label">
                  Additional Languages Spoken
                </InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  multiple
                  value={
                    Array.isArray(additionalLanguagesSpoken)
                      ? additionalLanguagesSpoken
                      : []
                  }
                  onChange={handleLanguagesChange}
                  input={<OutlinedInput label="Additional Languages Spoken" />}
                  MenuProps={MenuProps}
                  renderValue={(selected) => selected?.join(', ')}
                  disabled={props.isProvider}
                >
                  {additionalLanguagesSpokenOptions.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox
                        checked={additionalLanguagesSpoken.indexOf(name) > -1}
                      />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          }
        </Grid>

        <div className={styles.addressSection}>
          <label htmlFor="address1">Home Address</label>

          <Grid container spacing={3} className={styles.gridItemTextField}>
            <Grid size={{ xs: 12, sm: 6, md: 5 }}>
              <TextField
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                autoComplete="off"
                fullWidth
                variant="outlined"
                id="address1"
                label="Address"
                value={address1}
                onChange={(event) => {
                  setAddress1(event.target.value);
                }}
              />
            </Grid>

            <Grid size={{ xs: 12, sm: 6, md: 6 }}>
              <TextField
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                autoComplete="off"
                fullWidth
                variant="outlined"
                id="address2"
                label="e.g Apt"
                value={address2}
                onChange={(event) => {
                  setAddress2(event.target.value);
                }}
              />
            </Grid>

            <Grid size={{ xs: 12, sm: 6, md: 3 }}>
              <TextField
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                autoComplete="off"
                fullWidth
                variant="outlined"
                id="city"
                label="City"
                value={city}
                onChange={(event) => {
                  setCity(event.target.value);
                }}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6, md: 3 }}>
              <FormControl
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                sx={{ width: '100%' }}
              >
                <InputLabel id="state-label">State</InputLabel>
                <Select
                  labelId="state-label"
                  id="state"
                  value={state}
                  onChange={(event) => setState(event.target.value)}
                  label="State"
                >
                  {states.map((state) => (
                    <MenuItem key={state} value={state}>
                      {state}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid size={{ xs: 12, sm: 6, md: 3 }}>
              <TextField
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                autoComplete="off"
                fullWidth
                variant="outlined"
                id="zip"
                label="Zip Code"
                value={zip}
                onChange={(event) => setZip(event.target.value)}
              />
            </Grid>
          </Grid>
        </div>

        <div>
          <Grid container spacing={3} className={styles.gridItemTextField}>
            <Grid size={{ xs: 12, sm: 6, md: 4 }}>
              <TextField
                fullWidth
                variant="outlined"
                id="emergencyContactName"
                label="Emergency Contact Name"
                value={emergencyContactName}
                onChange={(e) => setEmergencyContactName(e.target.value)}
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
              />
            </Grid>

            <Grid size={{ xs: 12, sm: 6, md: 4 }}>
              <FormControl
                fullWidth
                variant="outlined"
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
              >
                <InputLabel>Relationship</InputLabel>
                <Select
                  value={emergencyContactRelationship}
                  onChange={(e) =>
                    setEmergencyContactRelationship(e.target.value)
                  }
                  label="Relationship"
                >
                  {relationshipOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {emergencyContactRelationship === 'Other' && (
              <Grid size={{ xs: 12, sm: 6, md: 4 }}>
                <TextField
                  fullWidth
                  variant="outlined"
                  id="customRelationship"
                  label="Specify Relationship"
                  value={customRelationship}
                  onChange={(e) => setCustomRelationship(e.target.value)}
                  className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                />
              </Grid>
            )}

            {/* Emergency Contact Phone Number Field */}
            <Grid size={{ xs: 12, sm: 6, md: 4 }}>
              <TextField
                fullWidth
                variant="outlined"
                id="emergencyContactPhone"
                label="Emergency Contact Phone"
                value={emergencyContactPhone}
                onChange={(e) => setEmergencyContactPhone(e.target.value)}
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
              />
            </Grid>
          </Grid>
        </div>

        <Grid container spacing={3} className={styles.gridItemTextField}>
          <Grid
            container
            spacing={3}
            className={styles.gridItemTextField}
          ></Grid>
          <Grid container spacing={3} className={styles.gridItemTextField}>
            <Grid size={{ xs: 12, sm: 6, md: 3 }}>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOnSubmit}
                  style={{ marginTop: '40px' }}
                >
                  Save
                </Button>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default memo(AdminPersonalInfo);
