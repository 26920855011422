import React, { useState, useEffect, useMemo } from 'react';
import {
  Button,
  TextField,
  Typography,
  Box,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  TablePagination,
  Tabs,
  Tab,
  Autocomplete,
} from '@mui/material';

import moment from 'moment';

import { useSelector } from 'react-redux';
import { isAdmin, isCareCoordinator } from '../../common/utils/RoleUtils';
import {
  AddConditionDialog,
  AddConditionErrorDialog,
  CancelConditionDialog,
} from '../Dialogs';
import { providerService } from '../../services'; // used to fetch providers
import { conditionsService } from '../../services/index.js'; //

import {
  NoBack2BackIcon,
  DailyMaxOrangeIcon,
  WeeklyMaxOrangeIcon,
} from '../UI/Icons';

// -----------------------------------------------------
// 3) AVAILABILITY CONDITIONS TAB + TABLE + DIALOG
// -----------------------------------------------------
function AvailabilityConditions() {
  const [providersOptions, setProvidersOptions] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [conditions, setConditions] = useState([]);
  const [conditionDialogOpen, setConditionDialogOpen] = useState(false);
  const [editingCondition, setEditingCondition] = useState(null);
  const [conditionNames, setConditionNames] = useState([]);
  const [cancelConfirmationOpen, setCancelConfirmationOpen] = useState(false);
  const [conditionToCancel, setConditionToCancel] = useState(null);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [subTab, setSubTab] = useState(0);
  const handleSubTabChange = (event, newValue) => {
    setSubTab(newValue);
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [sortField, setSortField] = useState('condition');
  const [sortDirection, setSortDirection] = useState('asc');

  const { user: currentUser } = useSelector((state) => state.auth);

  const handleRequestSort = (field) => {
    const isAsc = sortField === field && sortDirection === 'asc';
    setSortDirection(isAsc ? 'desc' : 'asc');
    setSortField(field);
  };

  const dummyAllProvider = { id: 0, username: 'All' };
  const isProviderAll = selectedProvider?.id === 0 || !selectedProvider;

  useEffect(() => {
    // If user is a non-admin provider => only that user in list
    if (
      currentUser &&
      !(isAdmin(currentUser) || isCareCoordinator(currentUser))
    ) {
      setProvidersOptions([currentUser]);
      setSelectedProvider(currentUser);
    } else {
      // Otherwise fetch all active providers
      providerService.getAllProviders().then((resp) => {
        let users = resp?.data?.users ?? [];
        // Filter for roleId=5 + providerActiveStatus=1
        users = users.filter(
          (u) => u.roleId === 5 && u.providerActiveStatus === 1
        );

        // Sort by last name
        users.sort((a, b) => {
          const lastNameA = a.username.split(' ')[1] ?? '';
          const lastNameB = b.username.split(' ')[1] ?? '';
          return lastNameA.localeCompare(lastNameB);
        });

        // Insert "All" at the front
        users.unshift(dummyAllProvider);
        setProvidersOptions(users);
        setSelectedProvider(dummyAllProvider);
      });
    }

    // Fetch existing condition configs
    conditionsService
      .getAllConditionConfigs()
      .then((res) => setConditions(res.data))
      .catch((err) => console.error('Error fetching conditions:', err));

    // Fetch all condition names
    conditionsService
      .getAllConditionNames()
      .then((resp) => setConditionNames(resp.data))
      .catch((err) => console.error('Error fetching condition names:', err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const onProviderChange = (event, value) => {
    setSelectedProvider(value);
    setPage(0); // reset pagination
  };

  // Filter by provider
  const filteredConditions = isProviderAll
    ? conditions
    : conditions.filter((c) => c.provider_id === selectedProvider.id);

  // Then filter by subTab => 0=Active, 1=Expired, 2=Cancelled
  const displayedConditions = filteredConditions.filter((row) => {
    if (subTab === 0) return row.status === 'Active';
    if (subTab === 1) return row.status === 'Expired';
    if (subTab === 2) return row.status === 'Cancelled';
    return false;
  });

  // Sorting
  const sortData = (array, field, direction) => {
    return [...array].sort((a, b) => {
      if (a[field] == null) return 1;
      if (b[field] == null) return -1;

      // If date field
      if (field === 'start_date' || field === 'createdAt') {
        const dateA = new Date(a[field]);
        const dateB = new Date(b[field]);
        return direction === 'asc' ? dateA - dateB : dateB - dateA;
      }
      // Otherwise string
      const valA = a[field].toString().toLowerCase();
      const valB = b[field].toString().toLowerCase();
      if (valA < valB) return direction === 'asc' ? -1 : 1;
      if (valA > valB) return direction === 'asc' ? 1 : -1;
      return 0;
    });
  };

  const sortedConditions = useMemo(() => {
    return sortData(displayedConditions, sortField, sortDirection);
  }, [displayedConditions, sortField, sortDirection]);

  // Pagination slice
  const pagedConditions = useMemo(() => {
    const startIndex = page * rowsPerPage;
    return sortedConditions.slice(startIndex, startIndex + rowsPerPage);
  }, [sortedConditions, page, rowsPerPage]);

  const getProviderName = (providerId) => {
    const provider = providersOptions.find((p) => p.id === providerId);
    return provider ? provider.username : 'Unknown Provider';
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'No End';
    return moment(dateString).format('M/D/YY');
  };

  // Map numeric IDs to condition codes
  const codeMapping = {
    1: 'no_back_to_back',
    2: 'weekly_client_limit',
    3: 'daily_client_limit',
  };

  // Icons
  const conditionIcons = {
    no_back_to_back: (
      <NoBack2BackIcon style={{ width: '44px', height: '44px' }} />
    ),
    daily_client_limit: (
      <DailyMaxOrangeIcon style={{ width: '54px', height: '54px' }} />
    ),
    weekly_client_limit: (
      <WeeklyMaxOrangeIcon style={{ width: '54px', height: '54px' }} />
    ),
  };

  // Return the icon if we have one
  const getConditionIcon = (conditionId) => {
    const code = codeMapping[conditionId];
    return conditionIcons[code] || conditionId;
  };

  const handleAddCondition = () => {
    setEditingCondition(null);
    setConditionDialogOpen(true);
  };

  const handleEdit = (row) => {
    setEditingCondition(row);
    setConditionDialogOpen(true);
  };

  const handleSaveCondition = (data) => {
    if (!selectedProvider) return;

    // If we are editing an existing condition
    if (editingCondition) {
      conditionsService
        .updateConditionConfig(editingCondition.id, {
          ...editingCondition,
          ...data, // new fields
          provider_id: selectedProvider.id,
        })
        .then(() => conditionsService.getAllConditionConfigs())
        .then((response) => setConditions(response.data))
        .catch((error) => {
          console.error('Error updating condition:', error);
          const msgFromBackend =
            error?.response?.data?.error || 'An unexpected error occurred.';
          setErrorMessage(msgFromBackend);
          setErrorModalOpen(true);
        });
    } else {
      // Create a brand-new condition
      conditionsService
        .createConditionConfig({
          ...data,
          provider_id: selectedProvider.id,
          status: 'Active',
          created_by: currentUser.username,
        })
        .then(() => conditionsService.getAllConditionConfigs())
        .then((response) => setConditions(response.data))
        .catch((error) => {
          console.error('Error creating condition:', error);
          const msgFromBackend =
            error?.response?.data?.error || 'An unexpected error occurred.';
          setErrorMessage(msgFromBackend);
          setErrorModalOpen(true);
        });
    }
    setConditionDialogOpen(false);
  };

  const handleCancelCondition = (row) => {
    setConditionToCancel(row);
    setCancelConfirmationOpen(true);
  };

  const handleConfirmCancellation = () => {
    if (!conditionToCancel) return;
    conditionsService
      .updateConditionConfig(conditionToCancel.id, {
        status: 'Cancelled',
        cancelled_by: currentUser.username,
        date_cancelled: new Date(),
      })
      .then(() => conditionsService.getAllConditionConfigs())
      .then((response) => setConditions(response.data))
      .catch((error) => console.error('Error Cancelling condition:', error))
      .finally(() => {
        setCancelConfirmationOpen(false);
        setConditionToCancel(null);
      });
  };

  const handleCancelConfirmationClose = () => {
    setCancelConfirmationOpen(false);
    setConditionToCancel(null);
  };

  const SortableHeader = ({ label, columnKey }) => {
    return (
      <TableCell
        onClick={() => handleRequestSort(columnKey)}
        style={{ cursor: 'pointer', fontWeight: 'bold' }}
      >
        {label}
        {sortField === columnKey && (sortDirection === 'asc' ? ' ▲' : ' ▼')}
      </TableCell>
    );
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h5" gutterBottom>
        Availability Conditions
      </Typography>
      <Autocomplete
        sx={{ maxWidth: 300 }}
        disablePortal
        value={selectedProvider}
        onChange={onProviderChange}
        options={providersOptions}
        getOptionLabel={(option) => option.username || ''}
        renderInput={(params) => (
          <TextField variant="outlined" {...params} label="Select Provider" />
        )}
      />
      {selectedProvider && selectedProvider.id !== 0 && (
        <Box sx={{ marginY: 2 }}>
          <Button variant="contained" onClick={handleAddCondition}>
            Add Condition
          </Button>
        </Box>
      )}
      {/**
       * Below Showing the Tabs
       */}
      <Tabs
        value={subTab}
        onChange={handleSubTabChange}
        style={{ marginTop: '20px', marginBottom: '20px' }}
        sx={{
          '& button.Mui-selected': { backgroundColor: '#1c405c' },
          borderBottom: 1,
          borderColor: 'divider',
        }}
      >
        <Tab label="Active" />
        <Tab label="Expired" />
        <Tab label="Cancelled" />
      </Tabs>
      <Table>
        <TableHead>
          <TableRow>
            <SortableHeader label="Provider Name" columnKey="provider_id" />
            <SortableHeader label="Condition" columnKey="condition_name_id" />
            <TableCell style={{ fontWeight: 'bold' }}>
              Minutes | Max #
            </TableCell>
            <SortableHeader label="Dates" columnKey="start_date" />
            <SortableHeader label="Created At" columnKey="createdAt" />
            <SortableHeader label="Created By" columnKey="created_by" />
            {subTab === 2 && (
              <>
                <SortableHeader
                  label="Date Cancelled"
                  columnKey="date_cancelled"
                />
                <SortableHeader label="Cancelled By" columnKey="cancelled_by" />
              </>
            )}
            <TableCell style={{ fontWeight: 'bold' }}>Notes</TableCell>
            {subTab === 0 && (
              <TableCell style={{ fontWeight: 'bold' }}>Action</TableCell>
            )}
          </TableRow>
        </TableHead>

        <TableBody>
          {pagedConditions.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{getProviderName(row.provider_id)}</TableCell>
              <TableCell>{getConditionIcon(row.condition_name_id)}</TableCell>
              <TableCell>{row.condition_value}</TableCell>
              <TableCell>
                {formatDate(row.start_date)} - {formatDate(row.end_date)}
              </TableCell>
              <TableCell>{moment(row.createdAt).format('M/D/YY')}</TableCell>
              <TableCell>{row.created_by}</TableCell>
              {subTab === 2 && (
                <>
                  <TableCell>
                    {row.date_cancelled
                      ? moment(row.date_cancelled).format('M/D/YY')
                      : ''}
                  </TableCell>
                  <TableCell>{row.cancelled_by || ''}</TableCell>
                </>
              )}
              <TableCell>{row.notes}</TableCell>
              {subTab === 0 && (
                <TableCell>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => handleEdit(row)}
                    style={{ marginRight: '10px' }}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    style={{ backgroundColor: 'red', color: 'white' }}
                    onClick={() => handleCancelCondition(row)}
                  >
                    Cancel
                  </Button>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <TablePagination
        component="div"
        count={sortedConditions.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />

      {/**
       * Below Dialog for Adding Condition
       */}
      <AddConditionDialog
        open={conditionDialogOpen}
        onClose={() => setConditionDialogOpen(false)}
        onSave={handleSaveCondition}
        editingCondition={editingCondition}
        conditionNames={conditionNames}
      />

      {/**
       * Below Dialog for Cancelling the Condition
       */}
      <CancelConditionDialog
        cancelConfirmationOpen={cancelConfirmationOpen}
        handleCancelConfirmationClose={handleCancelConfirmationClose}
        handleConfirmCancellation={handleConfirmCancellation}
      />

      {/**
       * Below Dialog for Error in Adding Condition
       */}
      <AddConditionErrorDialog
        errorModalOpen={errorModalOpen}
        setErrorModalOpen={setErrorModalOpen}
        errorMessage={errorMessage}
      />
    </Box>
  );
}

export { AvailabilityConditions };
