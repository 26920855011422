import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  styled,
  useTheme,
  useMediaQuery,
  Box,
  Typography,
  AppBar,
  Badge,
  Button,
  CssBaseline,
  Divider,
  Drawer as MuiDrawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Collapse,
  FormControl,
  Select,
} from '@mui/material';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import FloatingCheatSheetButton from './Buttons/FloatingCheatSheetButton';
import {
  AccountCircle,
  Assignment as AssignmentIcon,
  ChevronLeft as ChevronLeftIcon,
  CreditScore as CreditScoreIcon,
  Dashboard as InboxIcon,
  Diversity1 as Diversity1Icon,
  EventAvailable as EventAvailableIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  Group as GroupIcon,
  Home as HomeIcon,
  LocationCity as LocationCityIcon,
  Logout as LogoutIcon,
  Mail as MailIcon,
  ManageAccounts as ManageAccountsIcon,
  ManageSearch as ManageSearchIcon,
  Menu as MenuIcon,
  MenuBook as MenuBookIcon,
  Notifications as NotificationsIcon,
  NotificationsActive as NotificationsActiveIcon,
  PaidOutlined as PaidOutlinedIcon,
  PersonAddAlt as PersonAddAltIcon,
  PersonSearch as PersonSearchIcon,
  Schedule as ScheduleIcon,
  Speed as SpeedIcon,
  TableChart as TableChartIcon,
  ArrowDropDown as WhiteArrowDropDownIcon,
} from '@mui/icons-material';
import { logout } from '../actions/auth';
import { clearFormData } from '../actions/scheduling';
import UserManagement from './UserManagement';
import Logs from './Logs';
import Dashboard from './Dashboard';
import AvailabilityContainer from './Availability/AvailabilityContainer';
import Notifications from './Notifications';
import PersonalInfo from './PersonalInfo';
import AdminConfigHome from './Admin/AdminConfigHome';
import ProviderConfigHome from './Provider/ProviderConfigHome';
import CareCoordinatorConfigHome from './CareCoordinator/CareCoordinatorConfigHome';
import GroupConfigHome from './Group/GroupConfigHome';
import Scheduling from './Scheduling/Scheduling';
import Reminders from './Reminders';
import Appropriateness from './Approp/Approp';
import Providers from './Table/Providers';
import Admins from './Table/Admins';
import CheatSheet from './Table/CheatSheet';
import CareCoordinators from './Table/CareCoordinators';
import Payroll from './Payroll';
import CptRates from './Table/CPTRates';
import Docs from './Docs';
import DefinitionTable from './Table/DefinitionTable';
import Clients from './Client/Clients';
import OurTeam from './Team/OurTeam';
import Directory from './Table/DirectoryTable';
import TeamMetrics from './Table/TeamMetrics';
import Group from './Table/Group';
import {
  hasSuperAdminAccess,
  isCareCoordinator,
  isAdmin,
} from '../common/utils/RoleUtils';

import { useLocation, useHistory } from 'react-router-dom';

const drawerWidth = 260;
const checkIsSpecifiedTab = (selectedTab) =>
  selectedTab === 'Our Team' ||
  selectedTab === 'Profiles' ||
  selectedTab === 'Dashboard';

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'auto',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'auto',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const StyledDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'hideDrawer',
})(
  ({
    theme,
    open,
    isMobile,
    selectedTab,
    currentUser,
    variant,
    hideDrawer,
  }) => {
    const isSpecifiedTab = checkIsSpecifiedTab(selectedTab);

    const userIsNotDoctor = !currentUser?.roles.includes('ROLE_DOCTOR');

    const appBarHeight =
      isMobile && isSpecifiedTab && userIsNotDoctor ? '100px' : '64px';

    return {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      marginTop: appBarHeight, // Push the Drawer content down
      ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': {
          ...openedMixin(theme),
          marginTop: appBarHeight,
        },
      }),
      ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': {
          ...closedMixin(theme),
          marginTop: appBarHeight,
        },
      }),
    };
  }
);

const StyledRoot = styled('div')({
  display: 'flex',
});

const StyledAppBar = styled(AppBar)(
  ({ theme, open, selectedTab, isMobile }) => {
    const isSpecifiedTab =
      selectedTab === 'Our Team' ||
      selectedTab === 'Profiles' ||
      selectedTab === 'Dashboard';
    return {
      zIndex: theme.zIndex.drawer + 1,
      height: '70px', // Set default height for larger screens
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      ...(isMobile && {
        height: isSpecifiedTab ? '100px' : '55px',
        '& .MuiToolbar-root': {
          flexDirection: isSpecifiedTab ? 'column' : 'row',
          alignItems: isSpecifiedTab ? 'center' : 'flex-end',
          paddingTop: isSpecifiedTab ? theme.spacing(1) : 0,
          paddingBottom: isSpecifiedTab ? theme.spacing(1) : 0,
        },
        '& .MuiTypography-h6': {
          display: 'block',
          fontSize: '1.25rem',
          marginBottom: isSpecifiedTab ? theme.spacing(1) : 0,
          textAlign: 'center',
        },
      }),
    };
  }
);

const StyledButton = styled(Button)(({ theme, active }) => ({
  backgroundColor: active ? '#3f51b5' : 'transparent', // Active tab background color
  color: active ? 'white' : 'gray', // Active text color for active, gray for inactive
  fontSize: '1.2rem',
  borderRadius: '20px',
  padding: '6px 20px',
  '&:hover': {
    backgroundColor: active ? '#303f9f' : '#eeeeee', // Darken the background color on hover for active, light color for inactive
  },
  margin: theme.spacing(1),
}));

const StyledTabButton = styled(Button)(({ theme, active }) => ({
  color: active ? 'white' : 'gray', // Active tab has white color, others gray
  backgroundColor: active ? '#3f51b5' : 'transparent', // Active tab has a specific background color
  fontSize: '1.2rem',
  borderRadius: '20px',
  padding: '10px 20px',
  '&:hover': {
    backgroundColor: active ? '#303f9f' : '#eeeeee', // Change background on hover
  },
  margin: theme.spacing(1),
}));

const StyledToolbar = styled('div')(({ theme, isMobile, selectedTab }) => {
  const isSpecifiedTab =
    selectedTab === 'Our Team' ||
    selectedTab === 'Profiles' ||
    selectedTab === 'Dashboard';
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    ...(isMobile &&
      isSpecifiedTab && {
        flexDirection: 'row', // Stack elements vertically
        alignItems: 'center',
      }),
  };
});

// Wrapper around
const StyledContent = styled('main', {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'hasBottomNav',
})(({ open, hasBottomNav }) => ({
  flexGrow: 1,
  marginTop: '60px',
  padding: '0px',
  transition: 'margin 225ms cubic-bezier(0.4, 0, 0.6, 1)',
  marginLeft: open ? `0px` : `0px`,
  paddingBottom: hasBottomNav ? '56px' : '0px',
}));

const MiniDrawer = (props) => {
  // const classes = useStyles();
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector((state) => state.auth);
  const { count } = useSelector((state) => state.notification);
  const [selectedTab, setSelectedTab] = useState('PsychCare');
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const hideDrawer = isSmallScreen;
  const drawerVariant = hideDrawer ? 'temporary' : 'permanent';
  const [open, setOpen] = React.useState(!hideDrawer);
  const isSpecifiedTab = checkIsSpecifiedTab(selectedTab);

  let menuOptions = [];

  if (hasSuperAdminAccess(currentUser)) {
    menuOptions = [
      'Home',
      'Our Team',
      'Group',
      'Profiles',
      'Availability',
      'Scheduling',
      'Reminders',
      'Clients',
      'Provider Search',
      'Tables',
    ];
  } else if (isCareCoordinator(currentUser)) {
    menuOptions = [
      'Dashboard',
      'Home',
      'My Profile',
      'Our Team',
      'Group',
      'Provider Profiles',
      'Availability',
      'Scheduling',
      'Reminders',
      'Provider Search',
      'Clients',
      'Tables',
    ];
  } else if (isAdmin(currentUser)) {
    menuOptions = [
      'Dashboard',
      'Home',
      'Our Team',
      'Group',
      'Update Provider Config',
      'Update Admin Config',
      'Availability',
      'Scheduling',
      'Reminders',
      'Provider Search',
      'Clients',
      'Tables',
    ];
  } else if (currentUser?.roles.includes('ROLE_DOCTOR')) {
    if (currentUser.roles.includes('ROLE_EXTERN')) {
      // Combined menu for users who are both doctors and externs
      menuOptions = [
        'Dashboard', // Assuming you might want externs to have access to the dashboard
        'Home',
        'Update Provider Config',
        'Provider Search',
        'Clients',
        'Directory',
        // Additional options specific to externs could be added here if needed
      ];
    } else {
      // Menu for doctors who are not externs
      menuOptions = [
        'Home',
        'Update Provider Config',
        'Payroll',
        'Provider Search',
        'Clients',
        'Directory',
      ];
    }
  } else if (currentUser?.roles.includes('ROLE_EXTERN')) {
    // Menu for externs, which should never occur standalone as per your system rules
    menuOptions = [
      'Home',
      'Update Provider Config',
      'Provider Search',
      'Clients',
      'Directory',
    ];
  } else {
    // Default menu for users who don't match any special roles
    menuOptions = ['Home', 'Availability', 'Provider Search'];
  }

  let navOptions = [];

  if (currentUser?.roles.includes('ROLE_DOCTOR')) {
    navOptions = [
      { label: 'Home', icon: <HomeIcon />, path: '/personal_info' },
      {
        label: 'My Profile',
        icon: <ManageAccountsIcon />,
        path: '/user-profiles/provider_config',
      },
      { label: 'Clients', icon: <GroupIcon />, path: '/clients' },
      { label: 'Provider Search', icon: <PersonSearchIcon />, path: '/approp' },
    ];
  } else if (isCareCoordinator(currentUser)) {
    navOptions = [
      {
        label: 'Dashboard',
        icon: <SpeedIcon />,
        path: '/dashboard/appointments',
      },
      { label: 'Scheduling', icon: <ScheduleIcon />, path: '/scheduling' },
      { label: 'Clients', icon: <GroupIcon />, path: '/clients' },
    ];
  } else if (isAdmin(currentUser) || hasSuperAdminAccess(currentUser)) {
    navOptions = [
      {
        label: 'Dashboard',
        icon: <SpeedIcon />,
        path: '/dashboard/appointments',
      },
      {
        label: 'User Management',
        icon: <GroupIcon />,
        path: '/user/management',
      },
      { label: 'Logs', icon: <ManageSearchIcon />, path: '/logs' },
    ];
  } else {
    navOptions = [
      { label: 'Home', icon: <HomeIcon />, path: '/personal_info' },
      { label: 'Provider Search', icon: <PersonSearchIcon />, path: '/approp' },
      {
        label: 'Availability',
        icon: <EventAvailableIcon />,
        path: '/update_availability',
      },
    ];
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [selectedSubTab, setSelectedSubTab] = useState('');
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const { pathname } = useLocation();
  const [openTables, setOpenTables] = React.useState(false);
  const [bottomNavValue, setBottomNavValue] = useState(0);

  useEffect(() => {
    const currentIndex = navOptions.findIndex((option) =>
      pathname.startsWith(option.path)
    );
    if (currentIndex !== -1) {
      setBottomNavValue(currentIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    // Automatically open the drawer if the pathname is '/personal_info'
    if (pathname === '/personal_info') {
      // Check the window width; if it's greater than 600px, open the drawer
      if (window.innerWidth > 600) {
        setOpen(true);
      } else {
        // For smaller screens like iPhones, keep the drawer closed
        setOpen(false);
      }
    }
  }, [pathname]); // dependency array to run the effect whenever pathname changes

  useEffect(() => {
    // Update selectedTab based on the current pathname
    if (pathname.startsWith('/dashboard')) {
      setSelectedTab('Dashboard');
      if (pathname.includes('/dashboard/appointments')) {
        setSelectedSubTab('Appointments');
      } else if (pathname.includes('/dashboard/call-logs')) {
        setSelectedSubTab('Call Logs');
      } else if (pathname.includes('/dashboard/team_members')) {
        setSelectedSubTab('Team Members');
      } else {
        setSelectedSubTab(''); // Default or reset
      }
    } else if (
      pathname.includes('/our-team/directory') &&
      currentUser?.roles.includes('ROLE_DOCTOR')
    ) {
      setSelectedTab('Directory');
      setSelectedSubTab(''); // Clear subtab if Directory is selected
    } else if (pathname.includes('/our-team')) {
      setSelectedTab('Our Team');
      if (pathname.includes('/our-team/providers')) {
        setSelectedSubTab('Providers');
      } else if (pathname.includes('/our-team/admins')) {
        setSelectedSubTab('Admins');
      } else if (pathname.includes('/our-team/care-coordinators')) {
        setSelectedSubTab('Care Coordinators');
      } else if (pathname.includes('/our-team/directory')) {
        setSelectedSubTab('Directory');
      } else if (pathname.includes('/our-team/group')) {
        setSelectedSubTab('Group');
      } else {
        setSelectedSubTab(''); // Clear subtab if none of the paths match
      }
    } else if (pathname.startsWith('/user-profiles')) {
      if (pathname.includes('/user-profiles/provider_config')) {
        // Set tab title to "Update Provider Config" if user is a doctor
        if (currentUser?.roles.includes('ROLE_DOCTOR')) {
          setSelectedTab('Update Provider Config');
        } else {
          // Default to "Profiles" for others
          setSelectedTab('Profiles');
        }
        setSelectedSubTab('Provider Profiles');
      } else if (pathname.includes('/user-profiles/admin_config')) {
        setSelectedSubTab('Admin Profiles');
      } else if (pathname.includes('/user-profiles/care_coordinator_config')) {
        setSelectedSubTab('Care Coordinator Profiles');
      } else if (pathname.includes('/user-profiles/group_config')) {
        setSelectedSubTab('Group Profile');
      } else {
        setSelectedSubTab('');
      }
    } else if (pathname.startsWith('/personal_info')) {
      setSelectedTab('Home');
      setSelectedSubTab('');
    } else if (pathname.startsWith('/reminders')) {
      setSelectedTab('Reminders');
      setSelectedSubTab('');
    } else if (pathname.startsWith('/scheduling')) {
      setSelectedTab('Scheduling');
      setSelectedSubTab('');
    } else if (pathname.startsWith('/update_availability')) {
      setSelectedTab('Availability');
      setSelectedSubTab('');
    } else if (pathname.startsWith('/approp')) {
      setSelectedTab('Provider Search');
      setSelectedSubTab('');
    } else {
      setSelectedTab('PsychCare'); // Clear main tab if none of the paths match
      setSelectedSubTab(''); // Clear subtab
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, currentUser]);

  const history = useHistory();

  const handleClick = (event) => {
    history.push('/notifications');
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleDrawerToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  // Handle subtab click, setting the selectedSubTab state and navigating
  const handleOurTeamSubTabClick = (subTabName) => {
    console.log(`Our Team Sub Tab Clicked: ${subTabName}`);
    const urlFriendlySubTabName = subTabName.toLowerCase().replace(/\s+/g, '-');
    setSelectedSubTab(subTabName);
    history.push(`/our-team/${urlFriendlySubTabName}`);
  };

  const handleGroupSubTabClick = (subTabName) => {
    console.log(`Group Sub Tab Clicked: ${subTabName}`);
    const urlFriendlySubTabName = subTabName.toLowerCase().replace(/\s+/g, '-'); // e.g., "Basic Info" → "basic-info"
    setSelectedSubTab(subTabName);
    history.push(`/group/${urlFriendlySubTabName}`);
  };

  const handleUserProfilesSubTabClick = (subTabName) => {
    console.log(`Profiles Sub Tab Clicked: ${subTabName}`);
    let urlPath = '';
    switch (subTabName) {
      case 'Provider Profiles':
        urlPath = '/user-profiles/provider_config'; // URL for Provider Config
        break;
      case 'Admin Profiles':
        urlPath = '/user-profiles/admin_config'; // URL for Admin Config
        break;
      case 'Care Coordinator Profiles':
        urlPath = '/user-profiles/care_coordinator_config'; // URL for Care Coordinator Config
        break;
      case 'Group Profile':
        urlPath = '/user-profiles/group_config'; // URL for Group Config
        break;
      default:
        urlPath = '/'; // Default path if no match
        break;
    }
    setSelectedSubTab(subTabName);
    history.push(urlPath);
  };

  const handleDashboardSubTabClick = (subTabName) => {
    console.log(`Dashboard Sub Tab Clicked: ${subTabName}`);
    let urlPath = '';
    switch (subTabName) {
      case 'Appointments':
        urlPath = '/dashboard/appointments'; // URL for Appointments
        break;
      case 'Call Logs':
        urlPath = '/dashboard/call-logs'; // URL for Call Logs
        break;
      case 'Team Members':
        urlPath = '/dashboard/team_members'; // URL for Providers
        break;
      default:
        console.error('Unexpected subTabName:', subTabName);
        urlPath = '/dashboard/appointments'; // Default or error handling route
        break;
    }
    setSelectedSubTab(subTabName);
    history.push(urlPath); // Navigate to the selected tab's URL
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleProfileIconClick = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    history.push('/personal_info');
  };

  const handleDashboardIconClick = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    handleDrawerClose();
    history.push('/dashboard/appointments');
  };

  const handleUserMgtClick = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    handleDrawerClose();
    history.push('/user/management');
  };

  const handleActivityLogClick = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    handleDrawerClose();
    history.push('/logs');
  };

  const handleLogout = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    dispatch(clearFormData());
    dispatch(logout());
    props.history.push('/');
  };

  const renderAppBarContent = () => {
    // Exit the function early if the user is a doctor
    if (currentUser?.roles.includes('ROLE_DOCTOR')) {
      return null;
    }

    const subTabNames = getSubTabNames(selectedTab, currentUser);

    // Check if the selectedTab is one of the specified pages
    const isSpecifiedPage =
      selectedTab === 'Our Team' ||
      selectedTab === 'Profiles' ||
      selectedTab === 'Dashboard';

    if (isSmallScreen && isSpecifiedPage) {
      // Render dropdown
      return (
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <FormControl
            variant="outlined"
            size="small"
            sx={{
              marginLeft: isSmallScreen ? '15px' : '0px', // Move left by 20px
              marginTop: isSmallScreen ? '-7px' : '0px', // Move down by 10px (use negative for upward movement)
              marginBottom: isSmallScreen ? '5px' : '0px', // Adjust as needed
            }}
          >
            <Select
              value={selectedSubTab || ''}
              onChange={(event) => handleSubTabClick(event.target.value)}
              displayEmpty
              renderValue={(value) => {
                if (value === '' || value === null) {
                  return selectedTab; // Display the main tab's name as placeholder
                }
                return value; // Display the selected sub-tab's name
              }}
              sx={{
                color: 'inherit',
                backgroundColor: 'transparent',
                border: '1px solid white', // Add white border
                borderRadius: '4px',
                '.MuiOutlinedInput-notchedOutline': { border: 0 },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': { border: 0 },
                '&:hover .MuiOutlinedInput-notchedOutline': { border: 0 },
                '& .MuiSelect-select': {
                  paddingRight: '28px',
                },
              }}
              IconComponent={WhiteArrowDropDownIcon} // Use custom white arrow icon
              MenuProps={{
                PaperProps: {
                  style: {
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                    zIndex: 2300, // Increase z-index (higher than typical content)
                  },
                },
              }}
            >
              {subTabNames?.map((subTabName) => (
                <MenuItem key={subTabName} value={subTabName}>
                  {subTabName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      );
    } else if (!isSmallScreen && isSpecifiedPage) {
      // Render tabs as before
      return (
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {subTabNames?.map((subTabName) => {
            const ButtonComponent = currentUser?.roles.includes('ROLE_DOCTOR')
              ? StyledButton
              : StyledTabButton;

            return (
              <ButtonComponent
                key={subTabName}
                onClick={() => handleSubTabClick(subTabName)}
                active={selectedSubTab === subTabName}
              >
                {subTabName}
              </ButtonComponent>
            );
          })}
        </Box>
      );
    }

    // For other pages or when not on small screens, render nothing
    return null;
  };

  const getSubTabNames = (selectedTab, currentUser) => {
    if (selectedTab === 'Our Team') {
      return ['Providers', 'Admins', 'Care Coordinators', 'Directory'];
    } else if (selectedTab === 'Group') {
      return [
        'Basic Info',
        'Locations',
        'Room Schedule',
        'Passwords',
        'Inventory',
      ];
    } else if (selectedTab === 'Profiles') {
      // Directly check if the user has the 'ROLE_DOCTOR' role
      if (currentUser?.roles.includes('ROLE_DOCTOR')) {
        // If the user is a doctor, return an empty array or specific tabs that a doctor should see
        return [];
      } else {
        // For non-doctors, return all profile types
        return [
          'Provider Profiles',
          'Admin Profiles',
          'Care Coordinator Profiles',
          'Group Profile',
        ];
      }
    } else if (selectedTab === 'Dashboard') {
      return ['Appointments', 'Call Logs', 'Team Members'];
    }
    return [];
  };

  const handleSubTabClick = (subTabName) => {
    if (selectedTab === 'Our Team') {
      handleOurTeamSubTabClick(subTabName);
    } else if (selectedTab === 'Profiles') {
      handleUserProfilesSubTabClick(subTabName);
    } else if (selectedTab === 'Dashboard') {
      handleDashboardSubTabClick(subTabName);
    } else if (selectedTab === 'Group') {
      handleGroupSubTabClick(subTabName); // NEW: Delegate to the Group subtab click handler
    }
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {!hasSuperAdminAccess(currentUser) && (
        <MenuItem
          key="home"
          onClick={handleProfileIconClick}
          style={{ justifyContent: 'flex-start' }}
        >
          <ListItemIcon style={{ minWidth: '35px' }}>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </MenuItem>
      )}
      {/* <MenuItem onClick={handleMenuClose}>My account</MenuItem> */}
      {hasSuperAdminAccess(currentUser) && [
        <MenuItem
          key="dashboard"
          onClick={handleDashboardIconClick}
          style={{ justifyContent: 'flex-start' }}
        >
          <ListItemIcon style={{ minWidth: '35px' }}>
            <SpeedIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </MenuItem>,

        <MenuItem
          key="logs"
          onClick={handleActivityLogClick}
          style={{ justifyContent: 'flex-start' }}
        >
          <ListItemIcon style={{ minWidth: '35px' }}>
            <ManageSearchIcon />
          </ListItemIcon>
          <ListItemText primary="Logs | User Activity" />
        </MenuItem>,

        <MenuItem
          key="user-mgt"
          onClick={handleUserMgtClick}
          style={{ justifyContent: 'flex-start' }}
        >
          <ListItemIcon style={{ minWidth: '35px' }}>
            <PersonAddAltIcon />
          </ListItemIcon>
          <ListItemText primary="User Mgt." />
        </MenuItem>,
      ]}

      <MenuItem onClick={handleLogout} style={{ justifyContent: 'flex-start' }}>
        <ListItemIcon style={{ minWidth: '35px' }}>
          <LogoutIcon />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </MenuItem>
    </Menu>
  );
  const mobileMenuId = 'primary-search-account-menu-mobile';
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton aria-label="show 4 new mails" color="inherit" size="large">
          <Badge badgeContent={4} color="secondary">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          aria-label="show 11 new notifications"
          color="inherit"
          onClick={handleClick}
          size="large"
        >
          <Badge badgeContent={11} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>

        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
          size="large"
        >
          {/* <AccountCircle /> */}
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <StyledRoot>
      <CssBaseline />

      <StyledAppBar
        position="fixed"
        open={open}
        selectedTab={selectedTab}
        isMobile={isSmallScreen}
      >
        <StyledToolbar isMobile={isSmallScreen} selectedTab={selectedTab}>
          {/* Left side: Menu Icon */}
          <IconButton
            onClick={handleDrawerToggle}
            edge="start"
            color="inherit"
            aria-label="open drawer"
            size="large"
            style={{ marginLeft: 5 }}
          >
            {open ? <ChevronLeftIcon /> : <MenuIcon />}
          </IconButton>

          {/* Center: Main Tab Title and Dropdown */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: isSmallScreen ? 'column' : 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
              flexGrow: 1,
            }}
          >
            <Typography
              variant="h6"
              component="div"
              noWrap
              style={{
                fontSize:
                  isSmallScreen && selectedTab === 'Provider Search'
                    ? '1.4rem'
                    : isSmallScreen
                    ? '1.8rem'
                    : '1.5rem', // Adjust font size conditionally
                fontWeight: isSmallScreen ? 'bold' : 'normal', // Bold for small screens
              }}
              sx={{
                display: 'flex',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                textAlign: 'center',
                color: '#FFFFFF', // Bright white font color
                marginTop: isSmallScreen && isSpecifiedTab ? '10px' : '0px',
                marginLeft: isSmallScreen ? '25px' : '0px',
              }}
            >
              {selectedTab}
            </Typography>

            {(selectedTab === 'Our Team' ||
              selectedTab === 'Profiles' ||
              selectedTab === 'Dashboard') &&
              renderAppBarContent()}
          </Box>

          {/* Right side: Notifications and Profile Icons */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <IconButton
              aria-label={`show ${count} new notifications`}
              color="inherit"
              onClick={handleClick}
              size="large"
              sx={{
                position:
                  isSmallScreen && isSpecifiedTab ? 'absolute' : 'static', // Use 'absolute' only if conditions are true
                top: isSmallScreen && isSpecifiedTab ? '50px' : 'unset', // Move further down under "Admin"
                right: isSmallScreen && isSpecifiedTab ? '20px' : 'unset', // Slightly closer to the center
              }}
            >
              <Badge badgeContent={count} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>

            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
              size="large"
            >
              {hasSuperAdminAccess(currentUser) ? (
                <Typography
                  variant="body1"
                  component="div"
                  style={{
                    fontSize: 20,
                    marginRight:
                      isSmallScreen && isSpecifiedTab ? '5px' : '15px',
                    marginTop:
                      isSmallScreen && isSpecifiedTab ? '-50px' : '0px',
                  }}
                >
                  Admin
                </Typography>
              ) : (
                <AccountCircle />
              )}
            </IconButton>
          </Box>
        </StyledToolbar>
      </StyledAppBar>

      {renderMobileMenu}
      {renderMenu}
      <StyledDrawer
        variant={drawerVariant}
        open={open}
        onClose={handleDrawerToggle}
        isMobile={isSmallScreen}
        selectedTab={selectedTab}
        hideDrawer={hideDrawer}
        currentUser={currentUser}
      >
        <StyledToolbar isMobile={window.innerWidth <= 480}>
          <IconButton
            onClick={handleDrawerToggle}
            edge="start"
            color="inherit"
            aria-label="open drawer"
            size="large"
          >
            {/* {open ? <ChevronLeftIcon /> : <MenuIcon />} */}
          </IconButton>
        </StyledToolbar>
        <Divider style={{ height: '1px', backgroundColor: '#e0e0e0' }} />

        <List>
          {menuOptions?.map((text, index) => {
            if (text === 'Tables') {
              return (
                <React.Fragment key={text}>
                  <ListItemButton onClick={() => setOpenTables(!openTables)}>
                    <ListItemIcon>
                      <TableChartIcon /> {/* Icon for Tables */}
                    </ListItemIcon>
                    <ListItemText primary="Tables" />
                    {openTables ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </ListItemButton>
                  <Collapse in={openTables} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItemButton
                        onClick={() => {
                          history.push('/approp_table');
                          setSelectedTab('Approp Table');
                          handleDrawerClose();
                        }}
                      >
                        <ListItemIcon>
                          <MenuBookIcon />
                        </ListItemIcon>
                        <ListItemText primary="Approp" />
                      </ListItemButton>

                      <ListItemButton
                        onClick={() => {
                          history.push('/docs');
                          setSelectedTab('Docs');
                          handleDrawerClose();
                        }}
                      >
                        <ListItemIcon>
                          <AssignmentIcon />
                        </ListItemIcon>
                        <ListItemText primary="Docs" />
                      </ListItemButton>

                      <ListItemButton
                        onClick={() => {
                          history.push('/cpt_rates');
                          setSelectedTab('CPT Rates');
                          handleDrawerClose();
                        }}
                      >
                        <ListItemIcon>
                          <CreditScoreIcon />
                        </ListItemIcon>
                        <ListItemText primary="CPT Rates" />
                      </ListItemButton>
                    </List>
                  </Collapse>
                </React.Fragment>
              );
            }

            return (
              <ListItemButton
                key={text}
                onClick={(event) => {
                  switch (text) {
                    case 'Dashboard':
                      history.push('/dashboard/appointments');
                      setSelectedTab('Dashboard');
                      handleDrawerClose();
                      break;
                    case 'User Management':
                      history.push('/user/management');
                      setSelectedTab('User Management');
                      break;
                    case 'Home':
                      history.push('/personal_info');
                      setSelectedTab('Home');
                      break;
                    case 'Update Provider Config':
                      history.push('/user-profiles/provider_config');
                      setSelectedTab('Update Provider Config');
                      if (isSmallScreen) {
                        handleDrawerClose();
                      }
                      break;
                    case 'Update Admin Config':
                      history.push('/user-profiles/admin_config');
                      setSelectedTab('Update Admin Config');
                      break;
                    case 'Update Care Coordinator Config':
                      history.push('/user-profiles/care_coordinator_config');
                      setSelectedTab('Update Care Coordinator Config');
                      break;
                    case 'Availability':
                      history.push('/update_availability');
                      setSelectedTab('Update Availability');
                      handleDrawerClose();
                      break;
                    case 'Scheduling':
                      history.push('/scheduling');
                      setSelectedTab('Scheduling');
                      handleDrawerClose();
                      break;
                    case 'Reminders':
                      history.push('/reminders');
                      setSelectedTab('Reminders');
                      handleDrawerClose();
                      break;
                    case 'Provider Search':
                      history.push('/approp');
                      setSelectedTab('Approp');
                      handleDrawerClose();
                      break;
                    case 'Payroll':
                      history.push('/payroll');
                      setSelectedTab('Payroll');
                      break;
                    case 'Logs | User Activity':
                      history.push('/logs');
                      setSelectedTab('Logs | User Activity');
                      break;
                    case 'Clients':
                      history.push('/clients');
                      setSelectedTab('Clients');
                      handleDrawerClose();
                      break;
                    case 'Cheat Sheet':
                      history.push('/cheat_sheet');
                      setSelectedTab('Cheat Sheet');
                      handleDrawerClose();
                      break;
                    case 'Directory':
                      history.push('/our-team/directory');
                      setSelectedTab('Directory');
                      handleDrawerClose();
                      break;
                    case 'Providers':
                      history.push('/dashboard/metrics');
                      setSelectedTab('Providers');
                      handleDrawerClose();
                      break;
                    case 'Our Team':
                      history.push('/our-team/providers');
                      setSelectedTab('Our Team');
                      handleDrawerClose();
                      break;
                    case 'Care Coordinators':
                      history.push('/our-team/care-coordinators');
                      setSelectedTab('Our Team');
                      handleDrawerClose();
                      break;

                    case 'Group':
                      history.push('/group/basic-info');
                      setSelectedTab('Group');
                      handleDrawerClose();
                      break;

                    case 'Profiles':
                      history.push('/user-profiles/provider_config');
                      setSelectedTab('Profiles');
                      handleDrawerClose();
                      break;

                    case 'Provider Profiles':
                      history.push('/user-profiles/providers');
                      setSelectedTab('Provider Profiles');
                      handleDrawerClose();
                      break;

                    case 'Admin Profiles':
                      history.push('/user-profiles/admins');
                      setSelectedTab('Admin Profiles');
                      handleDrawerClose();
                      break;

                    case 'Care Coordinator Profiles':
                      history.push('/user-profiles/care_coordinators');
                      setSelectedTab('Care Coordinator Profiles');
                      handleDrawerClose();
                      break;

                    case 'Group Profiles':
                      history.push('/user-profiles/group');
                      setSelectedTab('Group Profiles');
                      handleDrawerClose();
                      break;

                    case 'My Profile':
                      history.push('/care_coordinator_config');
                      setSelectedTab('My Profile');
                      handleDrawerClose();
                      break;

                    case 'CPT Rates':
                      history.push('/cpt_rates');
                      setSelectedTab('Cpt Rates');
                      handleDrawerClose();
                      break;

                    case 'Docs':
                      history.push('/docs');
                      setSelectedTab('Docs');
                      handleDrawerClose();
                      break;

                    case 'Approp Table':
                      history.push('/approp_table');
                      setSelectedTab('Approp Table');
                      handleDrawerClose();
                      break;

                    default:
                      break;
                  }
                }}
              >
                <ListItemIcon>
                  {text === 'Dashboard' ? (
                    <SpeedIcon />
                  ) : text === 'User Management' ? (
                    <GroupIcon />
                  ) : text === 'Home' ? (
                    <HomeIcon />
                  ) : text === 'My Profile' ? (
                    <ManageAccountsIcon />
                  ) : text === 'Update Provider Config' ? (
                    <ManageAccountsIcon />
                  ) : text === 'Availability' ? (
                    <EventAvailableIcon />
                  ) : text === 'Scheduling' ? (
                    <ScheduleIcon />
                  ) : text === 'Provider Profiles' ? (
                    <ManageAccountsIcon />
                  ) : text === 'Profiles' ? (
                    <ManageAccountsIcon />
                  ) : text === 'Reminders' ? (
                    <NotificationsActiveIcon />
                  ) : text === 'Group' ? (
                    <LocationCityIcon />
                  ) : text === 'Provider Search' ? (
                    <PersonSearchIcon />
                  ) : text === 'Cheat Sheet' ? (
                    <PersonSearchIcon />
                  ) : text === 'Payroll' ? (
                    <PaidOutlinedIcon />
                  ) : text === 'Logs | User Activity' ? (
                    <ManageSearchIcon />
                  ) : text === 'Clients' ? (
                    <GroupIcon />
                  ) : text === 'Our Team' || text === 'Directory' ? (
                    <Diversity1Icon />
                  ) : (
                    <InboxIcon />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={
                    text === 'Our Team'
                      ? currentUser?.roles.includes('ROLE_DOCTOR')
                        ? 'Directory'
                        : 'Our Team'
                      : text
                  }
                />
              </ListItemButton>
            );
          })}
        </List>

        <Divider style={{ height: '1px', backgroundColor: '#e0e0e0' }} />

        <ListItemButton
          key="logout"
          onClick={(e) => {
            handleLogout();
          }}
        >
          <ListItemIcon>
            {' '}
            <LogoutIcon />{' '}
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItemButton>
      </StyledDrawer>

      {/*I have passed this open prop explicitly previously it wasn't passed */}
      <StyledContent
        open={open}
        hasBottomNav={isSmallScreen}
        variant={drawerVariant}
        hideDrawer={hideDrawer}
      >
        <StyledToolbar>
          {(pathname === '/dashboard/appointments' ||
            pathname === '/dashboard/call-logs') && <Dashboard />}
          {pathname === '/user/management' && <UserManagement />}
          {pathname === '/personal_info' && <PersonalInfo />}
          {pathname === '/our-team/providers' && <Providers />}
          {pathname === '/our-team/admins' && <Admins />}
          {pathname === '/our-team/care-coordinators' && <CareCoordinators />}
          {pathname === '/our-team/group' && <Group />}
          {pathname === '/our-team/directory' && <Directory />}
          {pathname === '/dashboard/team_members' && <TeamMetrics />}
          {pathname === '/user-profiles/providers' && <ProviderConfigHome />}
          {pathname === '/user-profiles/admins' && <AdminConfigHome />}
          {pathname === '/user-profiles/group' && <GroupConfigHome />}
          {pathname === '/user-profiles/care_coordinators' && (
            <CareCoordinatorConfigHome />
          )}
          {pathname === '/user-profiles/care_coordinator_config' && (
            <CareCoordinatorConfigHome />
          )}
          {pathname === '/user-profiles/admin_config' && <AdminConfigHome />}
          {pathname === '/user-profiles/group_config' && <GroupConfigHome />}
          {pathname === '/user-profiles/provider_config' && (
            <ProviderConfigHome />
          )}
          {pathname === '/care_coordinator_config' && (
            <CareCoordinatorConfigHome />
          )}
          {pathname === '/scheduling' && <Scheduling />}
          {pathname === '/reminders' && <Reminders />}
          {pathname === '/update_availability' && <AvailabilityContainer />}
          {pathname === '/notifications' && <Notifications />}
          {pathname === '/approp' && <Appropriateness />}
          {pathname === '/payroll' && <Payroll />}
          {pathname === '/logs' && <Logs />}
          {pathname === '/clients' && <Clients />}
          {pathname === '/cheat_sheet' && <CheatSheet />}
          {pathname === '/our_team/providers' && <OurTeam />}
          {pathname === '/cpt_rates' && <CptRates />}
          {pathname === '/docs' && <Docs />}
          {pathname === '/approp_table' && <DefinitionTable />}
          {pathname === '/group/basic-info' && <Group />}
          {pathname === '/group/locations' && <Group />}
          {pathname === '/group/room-schedule' && <Group />}
          {pathname === '/group/passwords' && <Group />}
          {pathname === '/group/inventory' && (
            <Typography variant="h6" sx={{ padding: '16px' }}>
              Inventory - Coming Soon
            </Typography>
          )}
        </StyledToolbar>
      </StyledContent>

      {isSmallScreen && (
        <Box
          sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: theme.zIndex.appBar,
          }}
        >
          <BottomNavigation
            value={bottomNavValue}
            onChange={(event, newValue) => {
              setBottomNavValue(newValue);
              history.push(navOptions[newValue].path);
            }}
            showLabels
            sx={{ backgroundColor: theme.palette.primary.main }}
          >
            {navOptions.map((option, index) => (
              <BottomNavigationAction
                key={option.label}
                label={option.label}
                icon={option.icon}
                sx={{
                  color:
                    index === bottomNavValue
                      ? '#FFFFFF'
                      : 'rgba(255, 255, 255, 0.7)', // Brighter white for selected, lighter for unselected
                  fontWeight: index === bottomNavValue ? 'bold' : 'normal', // Bolder font for selected
                  fontSize: index === bottomNavValue ? '1rem' : '0.875rem', // Slightly larger text for selected
                  transition: 'color 0.2s, font-weight 0.2s', // Smooth transition for selection
                }}
                style={{
                  color:
                    index === bottomNavValue
                      ? 'white'
                      : 'rgba(255, 255, 255, 0.7)',
                  borderBottom:
                    index === bottomNavValue ? '2px solid white' : 'none',
                }}
              />
            ))}
          </BottomNavigation>
        </Box>
      )}
      <FloatingCheatSheetButton currentUser={currentUser} />
    </StyledRoot>
  );
};

export default MiniDrawer;
