import React, { memo } from 'react';
import { Button, Modal, Typography } from '@mui/material';

const CancellationFormModal = ({ showFinalModal, setShowFinalModal }) => {
  return (
    <>
      <Modal open={showFinalModal} onClose={() => setShowFinalModal(false)}>
        <div
          style={{ backgroundColor: 'white', padding: '16px', height: '6in' }}
        >
          <Typography variant="h5" gutterBottom>
            Cancellation Form
          </Typography>
          <iframe
            title="jotform"
            src="https://form.jotform.com/220945182806155"
            style={{ width: '100%', height: '70vh', border: 'none' }}
          ></iframe>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '20px',
            }}
          >
            <Button
              variant="contained"
              color="primary"
              style={{ backgroundColor: '#4CAF50' }}
              onClick={() => {
                setShowFinalModal(false);
                window.location.reload();
              }}
            >
              <span style={{ marginRight: '5px', fontWeight: 'bold' }}>
                &#10003;
              </span>{' '}
              I have completed the Cancellation Form
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setShowFinalModal(false);
                window.location.reload();
              }}
            >
              <span style={{ marginRight: '5px', fontWeight: 'bold' }}>X</span>{' '}
              Close - Just Close this Screen
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default memo(CancellationFormModal);
