import React, { useCallback, useEffect, useState, useMemo } from 'react';
import {
  Autocomplete,
  Button,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useDispatch, useSelector } from 'react-redux';
import { clearLoading, setLoading } from '../../actions/loading';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import { useSnackbar } from 'notistack';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import EventBus from '../../common/EventBus';
import moment from 'moment';
import ConfirmAvailability from './ConfirmAvailability';
import { durations } from '../../common/utils/ScheduleUtils';
import { isAdmin, isCareCoordinator } from '../../common/utils/RoleUtils';
import styles from '../../styles/MainAvailability.module.css';
import {
  availabilityService,
  careCoordinatorService,
  providerService,
} from '../../services';
import AddAvailability from './AddAvailability';
import EditDeleteAvailability from './EditDeleteAvailability';
import ThreeDayView from './Views/ThreeDayView';
import CalLegend from '../Legends/CalLegend';
/* 
 The below customCalendar is actually the component
 that's going to be displayed on each Event in the Calendar
 */
import CustomCalendarEvent from './CustomCalendarEvent';

const eventPropGetter = (event) => {
  let backgroundColor = event.color;

  // Check if the event is a remote session
  if (!backgroundColor && event.setting === 'Remote Only') {
    backgroundColor = '#ddd600'; // Set to yellow for remote sessions
  } else if (backgroundColor === 'Yellow') {
    backgroundColor = '#ddd600'; // Convert 'Yellow' to its equivalent color code
  }

  return {
    style: { backgroundColor: `${backgroundColor}` },
  };
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 50,
    },
  },
};
// Below Setting the locations in an array
const locations = ['Silver Spring', 'Baltimore', 'Columbia'];

const frequencies = {
  weekly: 'Weekly',
  every_other_week: 'Every Other Week',
  floater: 'Floater',
};

// The below function for getting colors for a location
const getColorsForLocations = (locations) => {
  const numLocations = locations.length;
  const colors = [];

  switch (numLocations) {
    case 1:
      const location = locations[0];
      switch (location) {
        case 'Silver Spring':
          colors.push('Green');
          break;
        case 'Baltimore':
          colors.push('Purple');
          break;
        case 'Columbia':
          colors.push('Blue');
          break;
        case 'Remote':
          colors.push('Yellow');
          break;
        default:
          colors.push('');
      }
      break;
    case 2:
      colors.push('Green', 'Purple');
      break;
    case 3:
      colors.push('Green', 'Purple', 'Blue');
      break;
    case 4:
      colors.push('Green', 'Purple', 'Blue', 'Yellow');
      break;
    default:
      colors.push('');
  }

  return colors;
};

export const MainAvailability = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [activeEvent, setActiveEvent] = React.useState({});
  const [allEvents, setAllEvents] = useState([]);
  const [providersOptions, setProvidersOptions] = React.useState([]);
  const [selectedProvider, setSelectedProvider] = React.useState();
  const [showCustomAlert, setShowCustomAlert] = useState(false);
  const [selectedLocation, setSelectedLocation] = React.useState([]);
  // By Default, I have made the selectedColor as Yellow
  const [selectedColor, setSelectedColor] = React.useState(['Yellow']);
  const [service, setService] = React.useState('Therapy');
  const [type, setType] = React.useState('Open');
  const [selectedStartDate, setSelectedStartDate] = useState('');
  const [selectedEndDate, setSelectedEndDate] = useState('');
  const [selectedEventType, setSelectedEventType] = React.useState();
  const [setting, setSetting] = useState('Remote Only');

  const [note, setNote] = useState();

  const [selectedFrequency, setSelectedFrequency] = React.useState(['weekly']);

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const [selectedDuration, setSelectedDuration] = React.useState(['60']);

  // The below state for managing hold expiration date
  const [holdExpirationDateTime, setHoldExpirationDateTime] = useState(null);

  /* 
  The below state is for managing special scenario when testing 
  is selected, type is selected as Floater, then we give user the
  option to select weekly Floater or every other week Floater
  */
  const [testingFloaterType, setTestingFloaterType] = useState();

  const localizer = momentLocalizer(moment);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm')); // Detects sm and xs
  const isPortrait = useMediaQuery('(orientation: portrait)');
  const isSmallPortrait = isSmallScreen && isPortrait;
  const [initialView] = useState(() =>
    isSmallPortrait ? 'threeDay' : Views.WEEK
  );

  // The below has been shaped for Responsiveness
  const availableViews = useMemo(
    () => ({
      month: true,
      week: true,
      threeDay: ThreeDayView, // Custom 3-day view
      day: true,
      agenda: true,
    }),
    []
  );

  const showLoading = useCallback(() => {
    dispatch(setLoading());
  }, [dispatch]);

  const hideLoading = useCallback(() => {
    dispatch(clearLoading());
  }, [dispatch]);

  /**
   * Upon the change of the setting. I am setting the color.
   * For Remote Only the color is Yellow
   * For the other two, color depends on the location I select
   */
  const handleChangeSetting = (event) => {
    setSetting(event.target.value);
    let st = event.target.value;
    // 'Remote Only', 'In-Person Only', 'Any Setting'
    if (st === 'Remote Only') {
      setSelectedColor(['Yellow']);
    } else if (st === 'In-Person Only') {
    } else if (st === 'Any Setting') {
    } else {
      setSelectedColor([]);
    }
    /**
     * Below Explicitly setting the selected Location to Empty Array.
     */
    setSelectedLocation([]);
  };

  const handleChangeActiveEventSetting = (event) => {
    let color = null;
    let st = event.target.value;
    // 'Remote Only', 'In-Person Only', 'Any Setting'
    if (st === 'Remote Only') {
      color = ['Yellow'];
      activeEvent.location = [];
    } else if (st === 'In-Person Only') {
    } else if (st === 'Any Setting') {
    } else {
      color = [];
    }
    setActiveEvent({ ...activeEvent, setting: event.target.value, color });
  };

  const handleChangeLocation = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedLocation(typeof value === 'string' ? value.split(',') : value);
    const colors = getColorsForLocations(value.split(','));
    setSelectedColor(colors);
  };

  const handleChangeActiveEventLocation = (event) => {
    const {
      target: { value },
    } = event;
    let location = typeof value === 'string' ? value.split(',') : value;
    let color = '';
    if (value === 'Silver Spring') color = ['Green'];
    else if (value === 'Baltimore') color = ['Purple'];
    else if (value === 'Columbia') color = ['Blue'];
    else if (value === 'Remote') color = ['Yellow'];
    setActiveEvent({ ...activeEvent, color, location });
  };

  const handleChangeColor = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedColor(typeof value === 'string' ? value.split(',') : value);
  };

  const handleChangeActiveEventColor = (event) => {
    const {
      target: { value },
    } = event;
    let color = typeof value === 'string' ? value.split(',') : value;
    setActiveEvent({ ...activeEvent, color });
  };

  const handleChangeFrequency = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedFrequency(typeof value === 'string' ? value.split(',') : value);
  };

  const handleChangeDuration = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedDuration(typeof value === 'string' ? value.split(',') : value);
  };

  const { user: currentUser } = useSelector((state) => state.auth);
  const [open, setOpen] = React.useState(false);
  const [holdCreators, setHoldCreators] = React.useState([]); // This State for seeing holdCreators
  const [selectedHoldCreator, setSelectedHoldCreator] = useState(''); // This State for selected hold creator
  const [holdForClient, setHoldForClient] = useState(''); // This State for setting client for which hold is created
  const [holdCreationDate, setHoldCreationDate] = useState(''); // This State for setting hold creation date for client

  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  const [availabilityDetailOpen, setAvailabilityDetailOpen] =
    React.useState(false);

  const handleAvailabilityDetailClose = () => setAvailabilityDetailOpen(false);
  const handleAvailabilityDetailOpen = () => setAvailabilityDetailOpen(true);

  const handleSelectSlot = useCallback(
    ({ start }) => {
      // Format the start date to the appropriate format for a datetime-local input
      const formattedStartDate = moment(start).format('YYYY-MM-DDTHH:mm');

      // Set the formatted start date in your state
      setSelectedStartDate(formattedStartDate);
      setSelectedColor(['Yellow']); // Set Yellow as the default color for Remote Only

      // Open the modal for confirmation
      setOpen(true);
    },
    [setSelectedStartDate, setOpen]
  );

  const handleSelectEvent = useCallback((event) => {
    const stDate = moment(event.slot_start_date_time).format(
      'YYYY/MM/DD HH:mm:ss'
    );
    const endDate = moment(event.slot_end_date_time).format(
      'YYYY/MM/DD HH:mm:ss'
    );
    // setSelectedEventType()
    setActiveEvent({
      ...event,
      slot_start_date_time: stDate,
      slot_end_date_time: endDate,
    });
    // setActiveEvent(event)
    handleAvailabilityDetailOpen();
  }, []);

  useEffect(() => {
    // Fetch activeCareCoordinators.
    careCoordinatorService.getCareCoordinators().then(
      (response) => {
        let careCoordinators = response?.data;
        setHoldCreators(
          careCoordinators.sort((a, b) =>
            a.care_coordinator_name.localeCompare(b.care_coordinator_name)
          )
        );

        // If the current user is a doctor, only show their own schedule.
        if (
          currentUser &&
          !(isAdmin(currentUser) || isCareCoordinator(currentUser))
        ) {
          setProvidersOptions([currentUser]); // ProvidersOptions contains only the current user.
          setSelectedProvider(currentUser); // Automatically select the current user.
          fetchProviderAvailabilityAndSlots(currentUser.id); // Fetch the availability for the current user only.
        } else {
          providerService.getAllProviders().then((response) => {
            let users = response?.data?.users ?? [];
            users = users.filter(
              // The below filtering provider and only active provider
              (user) => user.roleId === 5 && user.providerActiveStatus === 1
            );
            users.sort((a, b) => {
              const lastNameA = a.username.split(' ')[1] ?? '';
              const lastNameB = b.username.split(' ')[1] ?? '';
              return lastNameA.localeCompare(lastNameB);
            });
            // For non-doctors, show all providers including the dummy 'All' entry.
            let dummyAllProvider = {
              id: 0,
              username: 'All',
              email: null,
              password: null,
              createdAt: null,
              updatedAt: null,
              roleId: 5,
              userId: 0,
            };
            users = users.filter((user) => user.roleId === 5);
            users.unshift(dummyAllProvider); // Insert the dummy 'All' provider at the start of the list.
            setProvidersOptions(users); // ProvidersOptions contains all providers for non-doctors.
            setSelectedProvider(dummyAllProvider); // Automatically select the 'All' option for non-doctors.
            fetchProviderAvailabilityAndSlots(0); // Fetch the availability for all providers.
          });
        }
      },
      (error) => {
        // Handle errors here.
        if (error.response && error.response.status === 401) {
          EventBus.dispatch('logout');
        }
      }
    );
  }, [currentUser]);

  useEffect(() => {
    const time = moment(selectedStartDate, 'YYYY-MM-DDTHH:mm')
      .add(selectedDuration, 'm')
      .toDate();
    setSelectedEndDate(moment(time).format('YYYY-MM-DDTHH:mm'));
  }, [selectedDuration, selectedStartDate]);

  /**
   * In the below useEffect, the type and activeEvent are
   * both the types. They are getting referenced
   * activeEvent is getting used in Availability
   * selectedEventType is getting used in UpdateAvailability
   */
  useEffect(() => {
    if (type === 'Hold' || activeEvent.type === 'Hold') {
      setHoldCreationDate(new Date());
      setSelectedHoldCreator(currentUser.username);
      setActiveEvent({
        ...activeEvent,
        holdCreationDate: new Date(),
        person_creating_hold: selectedHoldCreator,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, activeEvent]);

  const onTagsChange = async (event, values) => {
    let enObj = providersOptions.find((obj) => {
      return obj?.id === values?.id;
    });
    setSelectedProvider(enObj);
    if (enObj?.id >= 0) {
      await fetchProviderAvailabilityAndSlots(enObj.id);
    }
  };

  const fetchProviderAvailabilityAndSlots = async (id) => {
    availabilityService.getProviderAvailabilityAndSlots(id).then(
      (response) => {
        let providerSlots = response?.data?.slots ?? [];

        providerSlots = providerSlots.map((providerSlot) => {
          const slot = {
            ...providerSlot,
            end_date_time: new Date(
              moment.utc(providerSlot.end_date_time).format('llll')
            ),
            slot_end_date_time: new Date(
              moment.utc(providerSlot.slot_end_date_time).format('llll')
            ),
            slot_start_date_time: new Date(
              moment.utc(providerSlot.slot_start_date_time).format('llll')
            ),
            start_date_time: new Date(
              moment.utc(providerSlot.start_date_time).format('llll')
            ),
          };
          return slot;
        });
        setAllEvents(providerSlots);
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        console.log('_content', _content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch('logout');
        }
      }
    );
  };
  const handleAddAvailability = () => {
    setConfirmModalOpen(true);
  };

  const handleConfirmAddAvailability = async () => {
    try {
      // The below showing the loading
      showLoading();
      // Convert selected dates to JavaScript Date objects for comparison
      const selectedStartDateTime = new Date(selectedStartDate);
      const selectedEndDateTime = new Date(selectedEndDate);

      // Check if an event with the same date, time, and provider already exists
      const isDuplicateEvent = allEvents.some((e) => {
        const isDuplicate =
          +e.start_date_time === +selectedStartDateTime &&
          +e.end_date_time === +selectedEndDateTime &&
          e.provider_id === selectedProvider?.id &&
          e.status === 'created'; // Adjusted the status check to "created"
        return isDuplicate;
      });

      if (isDuplicateEvent) {
        setShowCustomAlert(true); // Display the custom alert
        return;
      }

      // Continue with the rest of the existing logic
      if (
        selectedProvider?.id &&
        selectedStartDate &&
        selectedEndDate &&
        selectedFrequency?.[0] &&
        setting &&
        (setting === 'Remote Only' || selectedLocation?.[0])
      ) {
        if (selectedStartDate > selectedEndDate) {
          enqueueSnackbar('Start date must be less than End date.', {
            variant: 'error',
            timeout: 3000,
          });
          return;
        }
        await availabilityService.addProviderAvailability(selectedProvider.id, {
          email: selectedProvider?.provider_email,
          provider_id: selectedProvider?.id,
          slot_start_date_time: moment.utc(selectedStartDate),
          slot_end_date_time: moment.utc(selectedEndDate),
          frequency: selectedFrequency[0],
          location: selectedLocation[0],
          note: note,
          color: selectedColor[0],
          service,
          type,
          setting,
          person_creating_hold: selectedHoldCreator || null,
          clientInitials: holdForClient || null,
          holdCreationDate: moment.utc(holdCreationDate) || null,
          holdExpirationDateTime: moment.utc(holdExpirationDateTime) || null, // Add this line
          actionBy: currentUser.username,
          testingFloaterType:
            testingFloaterType === 'floater_regular'
              ? null // Here I am sending null if this is regular floater
              : testingFloaterType,
          /* 
          The new value getting sent to set TestingFloater Type,
          */
        });
        enqueueSnackbar('Added Provider availability successfully', {
          variant: 'success',
          timeout: 3000,
        });
        handleClose();
        // reset the fields in modal
        setSelectedFrequency(['weekly']); // Reset to the initial frequency
        setSelectedLocation([]);
        setSelectedColor(['Yellow']); // Reset to initial Color Yellow
        setSelectedStartDate(null);
        setSelectedEndDate(null);
        setNote('');
        setSetting('Remote Only'); // Reset to the initial setting
        await fetchProviderAvailabilityAndSlots(selectedProvider?.id);
      } else {
        enqueueSnackbar('Please select required fields.', {
          variant: 'error',
          timeout: 3000,
        });
      }
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
        timeout: 3000,
      });
    } finally {
      hideLoading();
    }
  };

  const deleteEvent = async (event) => {
    try {
      await availabilityService.deleteEvent(selectedProvider.id, {
        id: activeEvent.id,
        eventType: selectedEventType,
      });
    } catch (error) {
      console.log('deleteEvent.error', error);
    }
  };

  const updateAvailability = async (event) => {
    try {
      await availabilityService.updateAvailability(selectedProvider.id, {
        activeEvent,
      });
      enqueueSnackbar('Successfully updated!', {
        variant: 'success',
        timeout: 3000,
      });
    } catch (error) {
      console.log('editEvent.error', error);
    }
  };

  const handleChangeEventType = (event) => {
    const eventType = event.target.value;
    setSelectedEventType(eventType);
  };

  const shouldShowAddButton = () => {
    if (type === 'Hold') {
      if (
        selectedHoldCreator &&
        holdForClient &&
        holdCreationDate &&
        holdExpirationDateTime
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const shouldShowEditButton = () => {
    if (activeEvent.type === 'Hold') {
      if (
        activeEvent.person_creating_hold &&
        activeEvent.clientInitials &&
        activeEvent.holdCreationDate &&
        activeEvent.holdExpirationDateTime
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  return (
    <>
      <React.Fragment>
        <div
          className="main-container"
          style={{
            padding: isSmallPortrait ? '0px' : '10px',
            marginTop: isSmallPortrait ? '2px' : '100px',
            marginLeft: isSmallPortrait ? '-7px' : '0px', // Moves the container 10px to the left
          }}
        >
          <Grid container spacing={2} alignItems="center">
            {(isAdmin(currentUser) || isCareCoordinator(currentUser)) && (
              <Grid size={{ xs: 12, sm: 8, md: 4 }}>
                <Autocomplete
                  disablePortal
                  defaultValue={{
                    id: 0,
                    username: 'All',
                    email: null,
                    password: null,
                    createdAt: null,
                    updatedAt: null,
                    roleId: 5,
                    userId: 0,
                  }}
                  id="provider"
                  options={providersOptions}
                  onChange={onTagsChange}
                  renderInput={(params) => (
                    <TextField
                      variant="outlined"
                      {...params}
                      name="provider_"
                      label="Provider"
                    />
                  )}
                  getOptionLabel={(option) => option.username || ''}
                />
              </Grid>
            )}

            {selectedProvider && selectedProvider.id !== 0 && (
              <Grid size={{ xs: 12, sm: 8, md: 4 }}>
                <Button
                  variant="contained"
                  color="primary" // Use Material UI primary color by default is blue
                  style={{
                    // Additional styles for mobile
                    backgroundColor: isSmallScreen ? '#1976d2' : '', // Material-UI default primary is blue
                    marginLeft: isSmallScreen ? '100px' : '10px',
                    marginTop: isSmallScreen ? '-6px' : '0px',
                  }}
                  onClick={handleOpen}
                >
                  ADD AVAILABILITY
                </Button>
              </Grid>
            )}
          </Grid>

          <CalLegend />

          {selectedProvider && (
            <div className={styles.calendarContainer}>
              <Calendar
                step={15}
                timeslots={4} // 4 timeslots in an hour (15 minutes each)
                selectable
                localizer={localizer}
                events={allEvents}
                // titleAccessor={((event) => `${event.note} | ${event.note}`)}
                // titleAccessor={getEventTitleAccessor}
                startAccessor={(event) => event.start_date_time}
                endAccessor={(event) => event.end_date_time}
                style={{ height: 1000, margin: '10px' }}
                onSelectEvent={handleSelectEvent}
                // onSelectSlot={handleSelectSlot}
                onSelectSlot={
                  selectedProvider && selectedProvider.id === 0
                    ? undefined
                    : handleSelectSlot
                }
                eventPropGetter={eventPropGetter}
                min={new Date(0, 0, 0, 6)} // Sets the minimum time to 6 AM
                max={new Date(0, 0, 0, 22)} // Sets the maximum time to 10 PM
                defaultView={initialView}
                views={availableViews}
                formats={{
                  eventTimeRangeFormat: () => '', // Hide RBC's time label
                }}
                messages={{
                  threeDay: '3-Day', // Displayed label for your custom view button
                }}
                components={{
                  // Use CustomCalendarEvent for rendering events
                  event: (props) => (
                    <CustomCalendarEvent
                      {...props}
                      providersOptions={providersOptions}
                    />
                  ),
                  // VERY IMPORTANT: Override eventWrapper
                  eventWrapper: ({ event, children }) => (
                    <div style={{ height: '100%' }}>{children}</div>
                  ),
                }}
              />
            </div>
          )}
        </div>

        {/*ADD AVAILABILITY MODAL*/}
        <AddAvailability
          // The below two places reference the above comment is there.
          setTestingFloaterType={setTestingFloaterType}
          testingFloaterType={testingFloaterType}
          open={open}
          handleClose={handleClose}
          selectedDuration={selectedDuration}
          handleChangeDuration={handleChangeDuration}
          durations={durations}
          MenuProps={MenuProps}
          type={type}
          selectedStartDate={selectedStartDate}
          setSelectedStartDate={setSelectedStartDate}
          setSelectedHoldCreator={setSelectedHoldCreator}
          setHoldForClient={setHoldForClient}
          setHoldCreationDate={setHoldCreationDate}
          setHoldExpirationDateTime={setHoldExpirationDateTime}
          setType={setType}
          service={service}
          setService={setService}
          setting={setting}
          handleChangeSetting={handleChangeSetting}
          selectedLocation={selectedLocation}
          handleChangeLocation={handleChangeLocation}
          selectedFrequency={selectedFrequency}
          holdCreationDate={holdCreationDate}
          holdExpirationDateTime={holdExpirationDateTime}
          holdForClient={holdForClient}
          setOpen={setOpen}
          handleChangeFrequency={handleChangeFrequency}
          locations={locations}
          frequencies={frequencies}
          note={note}
          setNote={setNote}
          selectedHoldCreator={selectedHoldCreator}
          setShowCustomAlert={setShowCustomAlert}
          shouldShowAddButton={shouldShowAddButton}
          // The below function is for opening confirmation Modal
          handleAddAvailability={handleAddAvailability}
          showCustomAlert={showCustomAlert}
        />

        {/* CONFIRM AVAILABILITY MODAL */}
        <ConfirmAvailability
          confirmModalOpen={confirmModalOpen}
          setConfirmModalOpen={setConfirmModalOpen}
          selectedProvider={selectedProvider}
          type={type}
          note={note}
          service={service}
          setting={setting}
          selectedLocation={selectedLocation}
          selectedStartDate={selectedStartDate}
          holdCreationDate={holdCreationDate}
          selectedFrequency={selectedFrequency}
          frequencies={frequencies}
          holdForClient={holdForClient}
          holdExpirationDateTime={holdExpirationDateTime}
          // The below function is responsible for creating Availability
          handleConfirmAddAvailability={handleConfirmAddAvailability}
        />

        {/* EVENT EDIT/DELETE MODAL*/}
        <EditDeleteAvailability
          activeEvent={activeEvent}
          selectedHoldCreator={selectedHoldCreator}
          setSelectedHoldCreator={setSelectedHoldCreator}
          holdCreationDate={holdCreationDate}
          setHoldCreationDate={setHoldCreationDate}
          availabilityDetailOpen={availabilityDetailOpen}
          handleAvailabilityDetailClose={handleAvailabilityDetailClose}
          handleChangeActiveEventSetting={handleChangeActiveEventSetting}
          MenuProps={MenuProps}
          locations={locations}
          selectedColor={selectedColor}
          handleChangeColor={handleChangeColor}
          setActiveEvent={setActiveEvent}
          holdCreators={holdCreators}
          shouldShowEditButton={shouldShowEditButton}
          selectedEventType={selectedEventType}
          handleChangeEventType={handleChangeEventType}
          updateAvailability={updateAvailability}
          setAvailabilityDetailOpen={setAvailabilityDetailOpen}
          deleteEvent={deleteEvent}
          frequencies={frequencies}
          handleChangeActiveEventColor={handleChangeActiveEventColor}
          fetchProviderAvailabilityAndSlots={fetchProviderAvailabilityAndSlots}
          selectedProvider={selectedProvider}
          handleChangeFrequency={handleChangeFrequency}
          handleChangeActiveEventLocation={handleChangeActiveEventLocation}
        />
      </React.Fragment>
    </>
  );
};
