import React, { memo } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import {
  CircularProgress,
  Modal,
  Box,
  Typography,
  Button,
} from '@mui/material';

const renderStatusUpdates = (steps) => {
  return steps.map((step) => (
    <Box
      key={step.stepId}
      sx={{ display: 'flex', alignItems: 'center', marginY: 1 }}
    >
      <Typography variant="body2" sx={{ flexGrow: 1 }}>
        {step.detail}
      </Typography>
      {step.status === 'pending' && <CircularProgress size={20} />}
      {step.status === 'success' && <CheckCircleIcon color="success" />}
      {step.status === 'error' && <ErrorIcon color="error" />}
    </Box>
  ));
};

const SocketIoModal = ({
  showSocketIOModal,
  shouldDisableCloseButton,
  isMobile,
  steps,
  onClose,
}) => {
  return (
    <>
      <Modal
        open={showSocketIOModal}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: isMobile ? '90%' : 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
            maxHeight: '80vh',
            overflowY: 'auto',
            borderRadius: '8px', // Optional: Match your other modal design
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            gutterBottom
          >
            Update Process
          </Typography>
          {renderStatusUpdates(steps)}

          {/* Close Button */}
          <Button
            variant="contained"
            onClick={onClose}
            sx={{
              marginTop: '16px',
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
            // Passing not, So as soon as response is received. It will enable this button
            disabled={!shouldDisableCloseButton}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default memo(SocketIoModal);
