export const yesNoOptions = ['', 'No', 'Yes'];

export const durations = {
  15: '15 min',
  30: '30 min',
  45: '45 min',
  60: '1 hr',
  75: '1 hr 15 min',
  90: '1 hr 30 min',
  105: '1 hr 45 min',
  120: '2 hr',
  135: '2 hr 15 min',
  150: '2 hr 30 min',
  165: '2 hr 45 min',
  180: '3 hr',
  195: '3 hr 15 min',
  210: '3 hr 30 min',
  225: '3 hr 45 min',
  240: '4 hr',
  255: '4 hr 15 min',
  270: '4 hr 30 min',
  285: '4 hr 45 min',
  300: '5 hr',
  315: '5 hr 15 min',
  330: '5 hr 30 min',
  345: '5 hr 45 min',
  360: '6 hr',
};

export const entryTypeFormValuesValid = (entryTypeFormValues) => {
  let {
    entryType,
    existingClientOptions,
    returningClientQ1,
    cancellationOptions,
  } = entryTypeFormValues;

  if (entryType === 'new') {
    return true;
  }

  if (entryType === 'existing') {
    if (existingClientOptions === 'Transfer') {
      return true;
    }

    if (existingClientOptions === 'Testing Client Coming to Office') {
      return true;
    }

    if (existingClientOptions === 'Therapy Client Coming to Office') {
      return true;
    }

    if (existingClientOptions === 'Turning 18 years-Old') {
      return true;
    }
  }

  if (entryType === 'returning') {
    if (returningClientQ1) {
      return true;
    }
  }

  if (entryType === 'cancellation') {
    if (cancellationOptions) {
      return true;
    }
  }

  // Check if the entry type is 'waitlist' and return true if so
  if (entryType === 'waitlist') {
    return true;
  }

  return false;
};

export const cases = (entryTypeFormValues, services) => {
  return {
    case1:
      entryTypeFormValues.entryType === 'existing' &&
      entryTypeFormValues.existingClientOptions === 'Transfer',

    // since transferQuestion is eliminated, case2 never fires
    case2:
      entryTypeFormValues.entryType === 'existing' &&
      entryTypeFormValues.existingClientOptions === 'Transfer' &&
      entryTypeFormValues.transferQuestion === 'After July 2021',

    // note that covid addendum question is now removed from therapy client coming to office option. so the case conditions have been changed. TODO: revisit case 3,4,5,6

    case3:
      (entryTypeFormValues.entryType === 'existing' &&
        entryTypeFormValues.existingClientOptions ===
          'Therapy Client Coming to Office') ||
      (entryTypeFormValues.existingClientOptions ===
        'Testing Client Coming to Office' &&
        // entryTypeFormValues.covidAddendum === 'Yes' &&
        !services?.includes('Bariatric Surgical Psychological Evaluations')),

    case4:
      (entryTypeFormValues.entryType === 'existing' &&
        entryTypeFormValues.existingClientOptions ===
          'Therapy Client Coming to Office') ||
      (entryTypeFormValues.existingClientOptions ===
        'Testing Client Coming to Office' &&
        services?.includes('Bariatric Surgical Psychological Evaluations')),

    // Since we removed the Covid Addendum - case 5 below will not be fired and commented out first part of case 5 since it's redundant with case 4
    case5:
      entryTypeFormValues.existingClientOptions ===
        'Testing Client Coming to Office' &&
      entryTypeFormValues.covidAddendum === 'No' &&
      services?.includes('Bariatric Surgical Psychological Evaluations'),

    // since we removed the COVID Addendum case 6 will not be fired
    case6:
      (entryTypeFormValues.entryType === 'existing' &&
        entryTypeFormValues.existingClientOptions ===
          'Therapy Client Coming to Office') ||
      (entryTypeFormValues.existingClientOptions ===
        'Testing Client Coming to Office' &&
        entryTypeFormValues.covidAddendum === 'No' &&
        !services?.includes('Bariatric Surgical Psychological Evaluations')),

    case7:
      entryTypeFormValues.entryType === 'returning' &&
      entryTypeFormValues.returningClientQ1 === 'Yes' &&
      entryTypeFormValues.returningClientQ2?.indexOf('Nota') === -1,
    case8:
      entryTypeFormValues.entryType === 'returning' &&
      entryTypeFormValues.returningClientQ1 === 'Yes' &&
      entryTypeFormValues.returningClientQ2?.indexOf('Nota') > -1,
    case9:
      entryTypeFormValues.entryType === 'returning' &&
      entryTypeFormValues.returningClientQ1 === 'No' &&
      entryTypeFormValues.returningClientQ2?.indexOf('Nota') === -1,

    // case 10 never fires since returningClientQ2 is eliminated
    case10:
      entryTypeFormValues.entryType === 'returning' &&
      entryTypeFormValues.returningClientQ1 === 'No' &&
      entryTypeFormValues.returningClientQ2?.indexOf('Nota') > -1,

    case11:
      entryTypeFormValues.entryType === 'existing' &&
      entryTypeFormValues.existingClientOptions === 'Turning 18 years-Old',

    // two cases below will not be fired since we removed COVID Addendum

    caseCovidAddendumYes:
      entryTypeFormValues.entryType === 'existing' &&
      entryTypeFormValues.existingClientOptions ===
        'Testing Client Coming to Office' &&
      entryTypeFormValues.covidAddendum === 'Yes',

    caseCovidAddendumNo:
      entryTypeFormValues.entryType === 'existing' &&
      entryTypeFormValues.existingClientOptions ===
        'Testing Client Coming to Office' &&
      entryTypeFormValues.covidAddendum === 'No',
  };
};

/**
 * Sorts a multiline schedule text so that each line is ordered from Sunday through Friday.
 * Each line should start with the day (either abbreviated or full), e.g. "Mon 8am-5pm".
 *
 * @param {string} text - The multiline schedule text.
 * @returns {string} - The sorted schedule text.
 */
export function sortAndCombineScheduleText(text) {
  if (!text) return '';

  // Define the desired order of days. (Adjust if you need Saturday)
  const dayOrder = {
    Sunday: 0,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    // Uncomment the line below if you need Saturday:
    // Saturday: 6,
  };

  // Mapping from abbreviated to full day names
  const conversion = {
    Sun: 'Sunday',
    Mon: 'Monday',
    Tue: 'Tuesday',
    Wed: 'Wednesday',
    Thu: 'Thursday',
    Fri: 'Friday',
    Sat: 'Saturday',
  };

  // Helper to parse out multiple days in a single chunk, e.g. "Tuesday, Thursday"
  function parseDays(dayString) {
    // Split by comma first, then trim. Example: "Tuesday, Thursday" => ["Tuesday","Thursday"]
    return dayString
      .split(',')
      .map((d) => d.trim())
      .flatMap((d) => d.split(/\s+/)) // in case there's leftover spacing
      .map((d) => conversion[d] || d) // convert abbreviations like "Mon" => "Monday"
      .filter(Boolean);
  }

  // day => array of time ranges
  const scheduleMap = {};

  // Split text by lines
  const lines = text.split('\n').filter((line) => line.trim() !== '');

  lines.forEach((line) => {
    // Try to separate the day(s) from the time range
    // e.g. "Monday 10:15am-1:15pm" => [1] = "Monday", [2] = "10:15am-1:15pm"
    // e.g. "Tuesday, Thursday 10:15am-5:30pm" => [1] = "Tuesday, Thursday", [2] = "10:15am-5:30pm"
    const match = line.match(/^(.*?)\s+(\d.*)/);
    if (!match) {
      return; // skip lines that don't match "Day... Time..."
    }
    const dayPart = match[1].trim();
    const timePart = match[2].trim();

    const days = parseDays(dayPart);
    days.forEach((day) => {
      if (!scheduleMap[day]) {
        scheduleMap[day] = [];
      }
      scheduleMap[day].push(timePart);
    });
  });

  // Sort days by dayOrder
  const sortedDays = Object.keys(scheduleMap).sort(
    (a, b) => (dayOrder[a] ?? 99) - (dayOrder[b] ?? 99)
  );

  // Build final lines, combining time ranges for each day
  const resultLines = sortedDays.map((day) => {
    const timesForDay = scheduleMap[day].join(', ');
    return `${day} ${timesForDay}`;
  });

  return resultLines.join('\n');
}
