import React from 'react';
import { IconButton, Popover, Typography, Paper } from '@mui/material';
import { NoteAltOutlined as NoteAltOutlinedIcon } from '@mui/icons-material';
import useNotePopover from '../../../hooks/useNotePopover'; // Import the hook.  Adjust path!

/**
 * Component to be displayed having slot and the icon
 */
const NoteWithPopover = ({ slot, iconStyle }) => {
  // Receive iconStyle
  const { anchorEl, content, open, handleIconClick, handlePopoverClose } =
    useNotePopover(slot);

  return (
    <>
      <IconButton onClick={handleIconClick} aria-label="Note Details">
        <NoteAltOutlinedIcon style={{ color: 'blue', ...iconStyle }} />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Paper sx={{ p: 2, maxWidth: 300 }}>
          <Typography>{content}</Typography>
        </Paper>
      </Popover>
    </>
  );
};

export default NoteWithPopover;
