import axios from 'axios';
import authHeader from './auth-header';

const API_URL = `${process.env.REACT_APP_API_HOST}/api/`;

// Fetch all groups with non-empty location names
const getAllGroupsWithLocations = () =>
  axios.get(`${API_URL}groups/with-locations`, { headers: authHeader() });

// Fetch group details using location_name
const getGroupDetails = (locationName) =>
  axios.get(
    `${API_URL}group/details?name=${encodeURIComponent(locationName)}`,
    { headers: authHeader() }
  );

const updateGroupLocationDetails = (locationDetails) =>
  axios.post(`${API_URL}group/location/update`, locationDetails, {
    headers: authHeader(),
  });

const updateGroupInfoDetails = (infoData) =>
  axios.post(`${API_URL}group/info/update`, infoData, {
    headers: authHeader(),
  });

// Fetch group insurance info (default and overrides)
const getGroupInsuranceInfo = () =>
  axios.get(`${API_URL}group/insurance`, { headers: authHeader() });

// Update group insurance info (default and overrides)
const updateGroupInsuranceInfo = (insuranceData) =>
  axios.post(`${API_URL}group/insurance/update`, insuranceData, {
    headers: authHeader(),
  });

// Fetch all office rooms
const getAllOfficeRooms = () =>
  axios.get(`${API_URL}office-rooms`, { headers: authHeader() });

// Fetch a specific office room by ID
const getOfficeRoomById = (id) =>
  axios.get(`${API_URL}office-rooms/${id}`, { headers: authHeader() });

// Fetch office rooms by location ID
const getRoomsByLocation = (locationId) =>
  axios.get(`${API_URL}office-rooms/location/${locationId}`, {
    headers: authHeader(),
  });

// Create a new office room
const createOfficeRoom = (roomData) =>
  axios.post(`${API_URL}office-rooms`, roomData, {
    headers: authHeader(),
  });

// Update an office room by ID
const updateOfficeRoom = (id, roomData) =>
  axios.put(`${API_URL}office-rooms/${id}`, roomData, {
    headers: authHeader(),
  });

// Delete an office room by ID
const deleteOfficeRoom = (id) =>
  axios.delete(`${API_URL}office-rooms/${id}`, { headers: authHeader() });

// Fetch all office schedules
const getAllOfficeSchedules = () =>
  axios.get(`${API_URL}office-schedules`, { headers: authHeader() });

// Fetch a specific office schedule by ID
const getOfficeScheduleById = (id) =>
  axios.get(`${API_URL}office-schedules/${id}`, { headers: authHeader() });

// Fetch an office schedule by Provider ID
const getOfficeScheduleByProvider = (providerId) =>
  axios.get(`${API_URL}office-schedules/provider/${providerId}`, {
    headers: authHeader(),
  });

const getSchedulesByLocation = (locationId) =>
  axios.get(`${API_URL}office-schedules/location/${locationId}`, {
    headers: authHeader(),
  });

// Create a new office schedule
const createOfficeSchedule = (data) => {
  return axios.post(`${API_URL}office-schedules`, data, {
    headers: authHeader(),
  });
};
// Update an office schedule by ID
const updateOfficeSchedule = (id, scheduleData) =>
  axios.put(`${API_URL}office-schedules/${id}`, scheduleData, {
    headers: authHeader(),
  });

// Delete an office schedule by ID
const deleteOfficeSchedule = (id, provider_id) =>
  axios.delete(`${API_URL}office-schedules/${id}/${provider_id}`, {
    headers: authHeader(),
  });

// Fetch an office schedule by Admin ID
const getOfficeScheduleByAdmin = (adminId) =>
  axios.get(`${API_URL}office-schedules/admin/${adminId}`, {
    headers: authHeader(),
  });

// eslint-disable-next-line
export default {
  getAllGroupsWithLocations,
  getGroupDetails,
  updateGroupLocationDetails,
  updateGroupInfoDetails,
  getGroupInsuranceInfo,
  updateGroupInsuranceInfo,
  getAllOfficeRooms,
  getOfficeRoomById,
  getRoomsByLocation,
  getOfficeScheduleByProvider,
  createOfficeRoom,
  updateOfficeRoom,
  deleteOfficeRoom,
  getAllOfficeSchedules,
  getSchedulesByLocation,
  getOfficeScheduleById,
  createOfficeSchedule,
  updateOfficeSchedule,
  deleteOfficeSchedule,
  getOfficeScheduleByAdmin,
};
