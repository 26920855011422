import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Typography,
  TextField,
  Button,
} from '@mui/material';
import { memo } from 'react';
import { getAbsoluteURL } from '../../common/utils/ValidationUtils.js';
import styles from '../../styles/ProviderConfig.module.css';

const getActiveStateStepStyle = (stepNumber, currentActiveStateStep) => {
  return {
    fontWeight: 'bold',
    marginBottom: '10px',
    color:
      currentActiveStateStep === stepNumber
        ? 'rgba(0, 0, 0, 0.87)'
        : 'rgba(0, 0, 0, 0.2)', // Adjust opacity for faded effect
    marginLeft: stepNumber === 3 ? '16px' : '0px', // Indent Step 3 to align with others
  };
};

const getActiveStateContentStyle = (stepNumber, currentActiveStateStep) => {
  return {
    marginBottom: '20px',
    display: currentActiveStateStep === stepNumber ? 'block' : 'none', // Only show content for the active step
  };
};

const OutOfStateDialog = ({
  openActiveStateModal,
  setOpenActiveStateModal,
  activeState,
  currentActiveStateStep,
  stateDetails,
  handleDetailChange,
  setChangesMadeForEvents,
  setCurrentActiveStateStep,
  setLinkActiveStateClicked,
  handleOnSubmit,
  linkActiveStateClicked,
}) => {
  return (
    <Dialog
      open={openActiveStateModal}
      onClose={() => setOpenActiveStateModal(false)}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle
        id="form-dialog-title"
        style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '24px' }}
      >
        Renew {activeState} License
      </DialogTitle>

      <DialogContent>
        <Typography
          variant="h6"
          style={{
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: '18px',
            marginBottom: '10px',
          }}
        >
          3 Simple & Quick Steps to Update Out of State License
        </Typography>
        {/* Adding the new instructional note with "Note:" in bold and red */}
        <Typography
          variant="body2"
          style={{
            textAlign: 'center',
            fontStyle: 'italic',
            fontSize: '14px',
            marginBottom: '10px',
          }}
        >
          <span style={{ fontWeight: 'bold', color: 'red' }}>Note:</span> If you
          haven't been able to renew your license yet but are in the process,
          please click the 'Cancel' button below and choose a renewal option
          that best suits your situation.
        </Typography>
        <Typography style={getActiveStateStepStyle(1, currentActiveStateStep)}>
          Step 1 - Enter your updated license expiration date
        </Typography>
        <div style={getActiveStateContentStyle(1, currentActiveStateStep)}>
          <TextField
            fullWidth
            margin="dense"
            label="Expiration Date"
            type="date"
            value={
              stateDetails[activeState]
                ? stateDetails[activeState].expirationDate
                : ''
            }
            onChange={(e) =>
              handleDetailChange(activeState, 'expirationDate')(e)
            }
            onBlur={() => {
              // Move to the next step only when the user exits the field with a fully entered date
              if (stateDetails[activeState]?.expirationDate?.length === 10) {
                setChangesMadeForEvents((prev) => ({
                  ...prev,
                  [activeState]: true,
                }));
                setCurrentActiveStateStep(2);
              }
            }}
            onKeyPress={(e) => {
              // Move to the next step only when the user presses 'Enter' with a fully entered date
              if (
                e.key === 'Enter' &&
                stateDetails[activeState]?.expirationDate?.length === 10
              ) {
                setChangesMadeForEvents((prev) => ({
                  ...prev,
                  [activeState]: true,
                }));
                setCurrentActiveStateStep(2);
              }
            }}
            componentsProps={{
              label: {
                shrink: true,
              },
            }}
            error={
              !stateDetails[activeState]?.expirationDate ||
              stateDetails[activeState]?.expirationDate.length !== 10
            }
            helperText={
              !stateDetails[activeState]?.expirationDate ||
              stateDetails[activeState]?.expirationDate.length !== 10
                ? 'Expiration date is required'
                : ''
            }
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor:
                    !stateDetails[activeState]?.expirationDate ||
                    stateDetails[activeState]?.expirationDate.length !== 10
                      ? 'red'
                      : 'blue',
                  borderWidth: '1px',
                },
                '&:hover fieldset': {
                  borderColor:
                    !stateDetails[activeState]?.expirationDate ||
                    stateDetails[activeState]?.expirationDate.length !== 10
                      ? 'red'
                      : 'blue',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'blue',
                },
              },
            }}
          />
        </div>
        <Typography style={getActiveStateStepStyle(2, currentActiveStateStep)}>
          Step 2 - Verify the updated date you just entered matches the
          licensing website
        </Typography>

        <div style={getActiveStateContentStyle(2, currentActiveStateStep)}>
          {/* <Typography>
  Click <a href={stateDetails[activeState]?.verificationLink || '#'} target="_blank">
    HERE
  </a> to verify the updated expiration date.
</Typography> */}

          <Typography>
            Click{' '}
            <a
              href={
                getAbsoluteURL(stateDetails[activeState]?.verificationLink) ||
                '#'
              }
              rel="noreferrer"
              target="_blank"
              style={{ color: 'darkblue', textDecoration: 'underline' }}
              onClick={() => {
                if (stateDetails[activeState]?.verificationLink) {
                  setLinkActiveStateClicked(true);
                  setCurrentActiveStateStep(3);
                }
              }}
            >
              HERE
            </a>{' '}
            to open the licensing page in another tab so that you can verify the
            updated expiration date and once completed come back here.
          </Typography>
        </div>
      </DialogContent>

      <DialogActions
        style={{
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'center',
        }}
      >
        <Typography style={getActiveStateStepStyle(3, currentActiveStateStep)}>
          Step 3 - Click the "Update" button below
        </Typography>
        <div style={getActiveStateContentStyle(3, currentActiveStateStep)}>
          <Typography style={{ marginLeft: '16px', marginBottom: '20px' }}>
            By Clicking "Update" below, I attest that the link above has my
            updated license information for state named as: {activeState}.
          </Typography>
        </div>
        {/* <div style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', marginTop: '20px' }}> */}
        <div className={styles.modal2buttonContainer}>
          <Button
            onClick={() => setOpenActiveStateModal(false)}
            className={styles.cancel2button}
          >
            Cancel
          </Button>
          <Button
            onClick={(event) => handleOnSubmit(event)}
            className={styles.submit2button}
            disabled={
              !stateDetails[activeState]?.expirationDate ||
              !linkActiveStateClicked
            }
          >
            Update
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default memo(OutOfStateDialog);
