import ReCAPTCHA from 'react-google-recaptcha';
import React, { useState, useEffect } from 'react';
import {
  Grid2,
  CircularProgress,
  Radio,
  FormControlLabel,
} from '@mui/material';
import styles from '../../styles/CustomForm.module.css';
import { useDispatch } from 'react-redux';
import { clearLoading, setLoading } from '../../actions/loading';
import { useSnackbar } from 'notistack';
import { useLocation } from 'react-router-dom';
import ThankYouPage from '../UI/Thankyou';
import ErrorPage from '../UI/Error';
import {
  clientService,
  providerService,
  futureApptService,
  customFormService,
} from '../../services';

const TrashCan = ({ onClick }) => (
  <button type="button" className={styles.trashButton} onClick={onClick}>
    <span className={styles.trashIcon} role="img" aria-label="Trashcan">
      🗑️
    </span>
  </button>
);

const subServiceTypesTherapy = [
  'Individual',
  'Group',
  'Couples Therapy',
  'Family',
];

const subServiceTypesTesting = [
  'Adoption Evaluation',
  'ADHD Adult Diagnostic Evaluation',
  'ADHD Child Comprehensive Evaluation',
  'ADHD/Autism Child Comprehensive Combined Evaluation',
  'Autism Spectrum Disorder Evaluations for Adults',
  'Autism Spectrum Disorder Evaluations for Children',
  'Bariatric Surgical Psychological Evaluation',
  'Child Psycho-Educational Evaluation',
  'Differential Adult Diagnosis Evaluation',
  'Immigration Evaluation',
  'Other Evaluation',
];

// We are defining a functional component named CustomForm.
const CustomForm = () => {
  // Declaring state variables for patientType, firstName, lastName, and dateOfBirth.
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [patientType, setPatientType] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [requesterName, setRequesterName] = useState('');
  const [requesterAge, setRequesterAge] = useState('');
  const [relation, setRelation] = useState('Self');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [, setSelectedRelation] = useState('');
  const [email, setEmail] = useState('');
  const [serviceType, setServiceType] = useState(''); // New state for service type
  const [isTherapySelected, setIsTherapySelected] = useState(false);
  const [therapyType, setTherapyType] = useState('');
  const [issue, setIssue] = useState('');
  const [otherIssue, setOtherIssue] = useState('');
  const [testingIssue, setTestingIssue] = useState('');
  const [otherTestingIssue, setOtherTestingIssue] = useState('');
  const [insuranceCarrier, setInsuranceCarrier] = useState('');
  const [insuranceMemberID, setInsuranceMemberID] = useState('');
  const [shouldShowForm, setShouldShowForm] = useState(true);
  const [futureAppointmentsLength, setFutureAppointmentsLength] = useState();
  const [additionalTimes, setAdditionalTimes] = useState([
    { daysAvailable: '', timeOfDay: '' },
  ]);
  const [interestedClinician, setInterestedClinician] = useState('');
  const [clinicianOptions, setClinicianOptions] = useState([]);
  const [selectedClinician, setSelectedClinician] = useState({});
  const [willingToSeeDifferentClinician, setWillingToSeeDifferentClinician] =
    useState('');
  const [appointmentPreference, setAppointmentPreference] = useState('');
  const [preferredLocation, setPreferredLocation] = useState('');
  const [state, setState] = useState('');
  const [referredBy, setReferredBy] = useState('');
  const [otherReferredBy, setOtherReferredBy] = useState('');
  const [showAdditionalTimes, setShowAdditionalTimes] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [descriptionOfSeekingHelp, setDescriptionSeekingHelp] = useState('');
  const [newsletter, setNewsLetter] = useState('');
  const [, setPhoneNumberValid] = useState(false);
  const [formLoadingState, setFormLoadingState] = useState(false);
  const [apologyMessage, setApologyMessage] =
    useState(`We apologize, the provider you selected is not currently accepting
              new clients. You can see if adjusting your search parameters would allow you to conntinue completing the form`);
  const [showThankYouPage, setShowThankYouPage] = useState(false);
  const [showErrorPage, setShowErrorPage] = useState(false);
  const [prospectiveClientDetails, setProspectiveClientDetails] = useState();
  const [localLoading, setLocalLoading] = useState(true);
  const [
    showChildCombASDADHDMandatoryField,
    setShowChildCombASDADHDMandatoryField,
  ] = useState(false);
  const [
    isChildCombASDADHDMandatoryAcknowledged,
    setIsChildCombASDADHDMandatoryAcknowledged,
  ] = useState(false);
  const [showChildADHDMandatoryField, setShowChildADHDMandatoryField] =
    useState(false);
  const [showChildPsychoEdMandatoryField, setShowChildPsychoEdMandatoryField] =
    useState(false);
  const [
    isChildADHDMandatoryAcknowledged,
    setIsChildADHDMandatoryAcknowledged,
  ] = useState(false);
  const [
    isChildPsychoEdMandatoryAcknowledged,
    setIsChildPsychoEdMandatoryAcknowledged,
  ] = useState(false);
  const [showASDMandatoryField, setShowASDMandatoryField] = useState(false);
  const [isASDMandatoryAcknowledged, setIsASDMandatoryAcknowledged] =
    useState(false);
  const [showInsuranceDropdown, setShowInsuranceDropdown] = useState(true);

  const location = useLocation();

  useEffect(() => {
    dispatch(setLoading());
    const queryParams = new URLSearchParams(location.search);
    const uniqueIdParam = queryParams.get('uniqueId');

    if (uniqueIdParam) {
      clientService.getProspectiveClientDetails(uniqueIdParam).then(
        (response) => {
          setProspectiveClientDetails({ ...response?.data?.data[0] });
          dispatch(clearLoading());
          setLocalLoading(false);
        },
        (error) => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          console.log('error', _content);
          dispatch(clearLoading());
          setLocalLoading(false);
        }
      );
    } else {
      dispatch(clearLoading());
      setLocalLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  useEffect(() => {
    if (
      prospectiveClientDetails &&
      Object.keys(prospectiveClientDetails).length > 0
    ) {
      setFirstName(prospectiveClientDetails.FirstName);
      setLastName(prospectiveClientDetails.LastName);
      setEmail(prospectiveClientDetails.email);
      setPhoneNumber(prospectiveClientDetails.phoneNumber);
      setDescriptionSeekingHelp(
        prospectiveClientDetails.descriptionOfSeekingHelp
      );
      setPatientType(prospectiveClientDetails.patientType);
      setReferredBy(prospectiveClientDetails.referredBy);
    }
  }, [prospectiveClientDetails]);

  useEffect(() => {
    setIsTherapySelected(serviceType === 'Therapy');
  }, [serviceType]);

  useEffect(() => {
    const isASDForChildren =
      testingIssue === 'Autism Spectrum Disorder Evaluations for Children';
    setShowASDMandatoryField(isASDForChildren);
  }, [testingIssue]);

  useEffect(() => {
    const isADHDForChildren =
      testingIssue === 'ADHD Child Comprehensive Evaluation';
    setShowChildADHDMandatoryField(isADHDForChildren);
  }, [testingIssue]);

  useEffect(() => {
    const isCombADHDASDForChildren =
      testingIssue === 'ADHD/Autism Child Comprehensive Combined Evaluation';
    setShowChildCombASDADHDMandatoryField(isCombADHDASDForChildren);
  }, [testingIssue]);

  useEffect(() => {
    const isPscychoEdForChildren =
      testingIssue === 'Child Psycho-Educational Evaluation';
    setShowChildPsychoEdMandatoryField(isPscychoEdForChildren);
  }, [testingIssue]);

  useEffect(() => {
    providerService.getProvidersWithServiceType(serviceType).then(
      (response) => {
        let providers = response?.data?.providers ?? [];
        providers = providers.sort((provider1, provider2) => {
          const name1 = provider1?.provider_name?.split(' ')[1] ?? '';
          const name2 = provider2?.provider_name?.split(' ')[1] ?? '';
          return name1.localeCompare(name2);
        });

        setClinicianOptions(
          providers.map((provider) => {
            return {
              username: provider.provider_name,
              id: provider.id,
            };
          })
        );
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log('error: ', _content);
        // Handle errors here

        if (error.response && error.response.status === 401) {
          // Handle unauthorized access here
        }
      }
    );
  }, [serviceType]);

  useEffect(() => {
    if (selectedClinician.id) {
      futureApptService.getFutureAppt(selectedClinician.id, serviceType).then(
        (response) => {
          setFutureAppointmentsLength(response.data.futureAppointments.length);
        },
        (error) => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          console.log(_content);
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClinician]);

  useEffect(() => {
    if (
      willingToSeeDifferentClinician === 'No' &&
      futureAppointmentsLength === 0
    ) {
      setShouldShowForm(false);
    } else {
      setShouldShowForm(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [willingToSeeDifferentClinician, futureAppointmentsLength]);

  useEffect(() => {
    if (interestedClinician === 'No') {
      setShouldShowForm(true);
      setSelectedClinician({});
      setWillingToSeeDifferentClinician('');
    }
  }, [interestedClinician]);

  /* The belowUseEffect is there control/get the value from the url params and set it */
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const serviceType = urlParams.get('serviceType');
    const subServiceType = urlParams.get('subServiceType');
    if (serviceType === 'Psychological Testing' || serviceType === 'Therapy') {
      setServiceType(serviceType);
    }

    if (
      serviceType === 'Therapy' &&
      subServiceTypesTherapy.includes(subServiceType)
    ) {
      setTherapyType(subServiceType);
    } else if (
      serviceType === 'Psychological Testing' &&
      subServiceTypesTesting.includes(subServiceType)
    ) {
      handleTestingIssueChange(null, subServiceType);
    }
  }, []);

  // This function handles changes in the patient type dropdown.
  const handleDropdownChange = (event) => {
    setPatientType(event.target.value);
  };

  const formatPhone = (digits) =>
    `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6)}`;

  // The below function for handlingPhoneNumber Change
  const handlePhoneNumberChange = (event) => {
    let phone = event.target.value;

    let digits = phone.replace(/\D/g, '');

    if (!(digits.length === 10 || phone === 'na' || phone === ' ')) {
      // error: 'Any phone number format, but must be 10 digits long; If not applicable enter "na" or " " (space)'
      setPhoneNumberValid(true);
    } else {
      if (phone !== 'na' && phone !== ' ') {
        phone = formatPhone(digits);
      } else {
        setPhoneNumberValid(true);
      }
    }
    setPhoneNumber(phone);
  };

  // Update the handleRelationChange function to set the selected relation
  const handleRelationChange = (event) => {
    const selectedRelation = event.target.value;
    setRelation(selectedRelation);
    setSelectedRelation(selectedRelation);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  // Handle changes in the service type dropdown
  const handleServiceTypeChange = (event) => {
    setApologyMessage(
      `We apologize, but the provider you selected is not currently accepting
              new clients. We understand that this might be disappointing. You are welcome to try adjusting your search parameters to see to if that helps. If you need any assistance or have any questions, you can call our office at 410-343-9756 ext 1`
    );
    setInterestedClinician('');
    setTestingIssue('');
    setSelectedClinician({});
    setShouldShowForm(true);
    setWillingToSeeDifferentClinician('');
    setServiceType(event.target.value);
  };

  // Handle changes in the therapy type dropdown
  const handleTherapyTypeChange = (event) => {
    setTherapyType(event.target.value);
  };

  // Handle change for the "Issue" dropdown
  const handleIssueChange = (event) => {
    setIssue(event.target.value);
  };

  const handleOtherIssueChange = (event) => {
    setOtherIssue(event.target.value);
  };

  // Handle change for the "Issue" dropdown passing the optional parameter of testingIssue so that it can take from the url as well

  const handleTestingIssueChange = (event, testingIssue) => {
    const issue = event ? event?.target?.value : testingIssue;
    setTestingIssue(issue);

    // Hide the insurance dropdown if the selected testing issue is 'Autism Spectrum Disorder Evaluations for Children'
    setShowInsuranceDropdown(
      issue !== 'Autism Spectrum Disorder Evaluations for Children'
    );

    if (issue === 'Immigration Evaluation') {
      setShouldShowForm(false);
      setApologyMessage(
        'We apologize, we are not able to provide immigration evaluations at this time.'
      );
    } else if (
      issue === 'Autism Spectrum Disorder Evaluations for Adults' ||
      issue === 'Differential Adult Diagnosis Evaluation' ||
      issue === 'ADHD Adult Diagnostic Evaluation' ||
      issue === 'Bariatric Surgical Psychological Evaluation'
    ) {
      setShouldShowForm(false);
      setApologyMessage(
        'We apologize, we are not able to provide this particular service at this time. Please check back in the beginning of April 2025.'
      );
    } else {
      setShouldShowForm(true);
      setApologyMessage(
        `We apologize, the provider you selected is not currently accepting
          new clients.`
      );
    }
  };

  const handleOtherTestingIssueChange = (event) => {
    setOtherTestingIssue(event.target.value);
  };

  const handleInsuranceCarrierChange = (event) => {
    const selectedInsuranceCarrier = event.target.value;
    setInsuranceCarrier(selectedInsuranceCarrier);
  };

  const handleInsuranceMemberIDChange = (event) => {
    const memberID = event.target.value;
    setInsuranceMemberID(memberID);
  };

  // Function to handle changes in the additional times dropdowns
  const handleAdditionalTimeChange = (index, field, value) => {
    const updatedTimes = [...additionalTimes];
    updatedTimes[index][field] = value;
    setAdditionalTimes(updatedTimes);
  };

  // Function to add an additional set of dropdowns
  const handleAddAdditionalTimes = () => {
    setAdditionalTimes([
      ...additionalTimes,
      { daysAvailable: '', timeOfDay: '' },
    ]);
    setShowAdditionalTimes(true);
  };

  // Function to remove an additional set of dropdowns
  const handleRemoveAdditionalTimes = (index) => {
    const updatedTimes = [...additionalTimes];
    updatedTimes.splice(index, 1);
    setAdditionalTimes(updatedTimes);
  };

  const handleInterestedClinicianChange = (event) => {
    setInterestedClinician(event.target.value);
    const urlParams = new URLSearchParams(window.location.search);

    /*
    The below logic is for handling the case when selectYour prop is being passed in the url param
    */
    // Get the value of the selectYour parameter
    const selectYourValue = urlParams.get('selectYour');

    if (selectYourValue) {
      const matchedClinician = clinicianOptions.find((clinician) =>
        clinician.username.toLowerCase().includes(selectYourValue.toLowerCase())
      );

      // If a matching clinician is found, setting the selectedClinician as
      if (matchedClinician) {
        setSelectedClinician(matchedClinician);
      } else {
        console.log('No matching clinician found.');
      }
    }
  };

  const handleWillingToSeeDifferentClinicianChange = (event) => {
    setWillingToSeeDifferentClinician(event.target.value);
  };

  const handleAppointmentPreferenceChange = (event) => {
    setAppointmentPreference(event.target.value);
  };

  // Handle change for the preferred location dropdown
  const handlePreferredLocationChange = (event) => {
    setPreferredLocation(event.target.value);
  };

  const handleStateChange = (event) => {
    setState(event.target.value);
  };

  // Define the handleChange function for referredBy
  const handleReferredByChange = (event) => {
    setReferredBy(event.target.value);
  };

  // Define handleChange function for Other Referred By
  const handleOtherReferredByChange = (event) => {
    setOtherReferredBy(event.target.value);
  };

  const handleDateBlur = (e) => {
    if (e.target.value === '') {
      e.target.classList.add('faded-date-display');
    }
  };

  // Event handler for when the date input is focused
  const handleDateFocus = (e) => {
    e.target.classList.remove('faded-date-display');
  };

  const handleRadioChange = (event) => {
    setNewsLetter(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (serviceType.includes('Testing')) {
      // Checking if all required fields are filled.
      if (
        !patientType ||
        !firstName ||
        !lastName ||
        !dateOfBirth ||
        !relation ||
        !phoneNumber ||
        !email ||
        !serviceType
      ) {
        enqueueSnackbar('Please answer all the required questions.', {
          variant: 'error',
          timeout: 3000,
        });
        return;
      }
    } else {
      // Checking if all required fields are filled.
      if (
        !patientType ||
        !firstName ||
        !lastName ||
        !dateOfBirth ||
        !relation ||
        !phoneNumber ||
        !email ||
        !serviceType ||
        !therapyType ||
        !issue
      ) {
        enqueueSnackbar('Please answer all the required questions.', {
          variant: 'error',
          timeout: 3000,
        });
        return;
      }
    }
    // comment just to push update
    // Additional check for 'daysAvailable' in 'additionalTimes'
    if (additionalTimes.timeOfDay === '') {
      enqueueSnackbar(
        'Please select a valid option for all Days Available fields.',
        {
          variant: 'error',
          timeout: 3000,
        }
      );
      return;
    }

    if (!isVerified) {
      enqueueSnackbar('Please fill out the captacha', {
        variant: 'error',
        timeout: 3000,
      });

      return;
    }

    // Additional validation for the ASD acknowledgment
    if (showASDMandatoryField && !isASDMandatoryAcknowledged) {
      enqueueSnackbar('You must acknowledge the evaluation cost.', {
        variant: 'error',
        timeout: 3000,
      });
      return;
    }

    // Additional validation for the Child ADHD acknowledgment
    if (showChildADHDMandatoryField && !isChildADHDMandatoryAcknowledged) {
      enqueueSnackbar('You must acknowledge the evaluation cost.', {
        variant: 'error',
        timeout: 3000,
      });
      return;
    }
    // Additional validation for the Child Combined ADHD/ASD acknowledgment
    if (
      showChildCombASDADHDMandatoryField &&
      !isChildCombASDADHDMandatoryAcknowledged
    ) {
      enqueueSnackbar('You must acknowledge the evaluation cost.', {
        variant: 'error',
        timeout: 3000,
      });
      return;
    }

    // Logging the submitted patient type for debugging purposes.
    console.log(`Form submitted with patient type: ${patientType}`);
    setFormLoadingState(true);

    const body = {
      patientType: patientType,
      FirstName: firstName,
      LastName: lastName,
      dateOfBirth: dateOfBirth,
      relation: relation,
      requesterName: requesterName,
      requesterAge: requesterAge,
      phoneNumber: phoneNumber,
      email: email,
      serviceType: serviceType,
      testingIssue: testingIssue,
      otherTestingIssue: otherTestingIssue,
      therapyType: therapyType,
      issue: issue,
      status: 'Active',
      otherIssue: otherIssue,
      insuranceCarrier: insuranceCarrier,
      insuranceMemberID: insuranceMemberID,
      daysAvailable:
        additionalTimes[0].daysAvailable === 'Please Select'
          ? ''
          : additionalTimes
              .map((time) => `${time.daysAvailable} - ${time.timeOfDay}`)
              .join(', '),
      interestedClinician: interestedClinician,
      clinician: selectedClinician.username || '',
      willingToSeeDifferentClinician: willingToSeeDifferentClinician,
      appointmentPreference: appointmentPreference,
      preferredLocation: preferredLocation,
      state: state,
      descriptionOfSeekingHelp: descriptionOfSeekingHelp,
      referredBy: referredBy,
      otherReferredBy: otherReferredBy,
      newsletter: newsletter,
    };

    try {
      let response;
      if (
        prospectiveClientDetails &&
        Object.keys(prospectiveClientDetails).length > 0
      ) {
        response = await customFormService.updateCustomFormEntry(
          body,
          prospectiveClientDetails.dockHealthTaskId
        );
      } else {
        response = await customFormService.createCustomFormEntry(body);
      }

      if (response.status === 200) {
        setShowThankYouPage(true);
      } else {
        enqueueSnackbar('Something went wrong form not submitted', {
          variant: 'error',
          timeout: 3000,
        });
      }
      setFormLoadingState(false);
    } catch (error) {
      setShowErrorPage(true);
    }
  };

  const handleRecaptchaChange = (value) => {
    setIsVerified(true);
  };

  if (showErrorPage) {
    return <ErrorPage />;
  }

  return !showThankYouPage ? (
    <div className={styles.formContainer}>
      <div className={styles.formContent}>
        <h4 className={styles.formHeaderCentered}>Appointment Request Form</h4>

        {/* Space before the first question */}
        <div className={styles.spaceBeforeFirstQuestion}></div>

        {/* First question on its own row */}
        {!localLoading && (
          <form onSubmit={handleSubmit}>
            <Grid2 container spacing={1}>
              {!prospectiveClientDetails && (
                <>
                  <Grid2 item size={{ xs: 12 }} className={styles.formField}>
                    <label className={styles.formLabel}>
                      Is this request for an existing or a new Patient?
                    </label>

                    <select
                      id="patientType"
                      name="patientType"
                      value={patientType}
                      onChange={handleDropdownChange}
                      required
                      className={`${styles.formDropdown} ${
                        patientType === '' ? styles.fadedSelectDisplay : ''
                      }`}
                    >
                      <option value="" disabled>
                        Please Select
                      </option>
                      <option value="New Patient">New Patient</option>
                      <option value="Existing">Existing</option>
                      <option value="Former">Former</option>
                    </select>
                  </Grid2>

                  {/* Existing form fields based on the original CustomForm.js */}
                  {/* First Name, Last Name, and Date of Birth on the same row */}

                  <Grid2
                    item
                    size={{ xs: 12, sm: 4 }}
                    className={styles.formField}
                  >
                    <label className={styles.formLabel}>First Name:</label>
                    <input
                      type="text"
                      className={styles.formInputMedium}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </Grid2>

                  <Grid2
                    item
                    size={{ xs: 12, sm: 4 }}
                    className={styles.formField}
                  >
                    <label className={styles.formLabel}>Last Name:</label>
                    <input
                      type="text"
                      className={styles.formInputMedium}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </Grid2>
                </>
              )}

              <Grid2 item size={{ xs: 12, sm: 4 }} className={styles.formField}>
                <label className={styles.formLabel}>
                  Patient Date of Birth:
                </label>
                <input
                  type="date"
                  className={`${styles.formInputMedium} ${
                    !dateOfBirth ? styles.fadedDateDisplay : ''
                  }`}
                  value={dateOfBirth}
                  onChange={(e) => setDateOfBirth(e.target.value)}
                  onBlur={handleDateBlur}
                  onFocus={handleDateFocus}
                />
              </Grid2>

              <Grid2
                item
                size={{ xs: 12, sm: 6 }}
                className={styles.formField}
                style={{
                  marginLeft:
                    prospectiveClientDetails &&
                    Object.keys(prospectiveClientDetails).length > 0
                      ? '122px'
                      : '0',
                }}
              >
                <label className={styles.formLabel}>Relation to Patient</label>
                <select
                  id="relation"
                  name="relation"
                  value={relation}
                  onChange={handleRelationChange}
                  required
                  className={styles.formDropdown}
                >
                  <option value="" disabled>
                    Please Select
                  </option>
                  <option value="Self">Self</option>
                  <option value="Spouse">Spouse</option>
                  <option value="Parent">Parent</option>
                  <option value="Family Member">Family Member</option>
                  <option value="Other">Other</option>
                </select>
              </Grid2>

              {relation !== 'Self' && (
                <>
                  <Grid2
                    item
                    size={{ xs: 12, sm: 6 }}
                    className={styles.formField}
                  >
                    <label className={styles.formLabel}>
                      {' '}
                      Requester Name (if not Patient)
                    </label>
                    <input
                      type="text"
                      className={styles.formInputWide}
                      value={requesterName}
                      onChange={(e) => setRequesterName(e.target.value)}
                    />
                  </Grid2>

                  <Grid2
                    item
                    size={{ xs: 12, sm: 6 }}
                    className={styles.formField}
                  >
                    <label className={styles.formLabel}>
                      Requester Age (if not Patient)
                    </label>
                    <input
                      type="number"
                      className={styles.formInputWide}
                      id="requesterAge"
                      name="requesterAge"
                      min={1}
                      max={99}
                      pattern="\d{1,2}"
                      value={requesterAge}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value > 0 && value <= 99) {
                          setRequesterAge(e.target.value);
                        } else {
                          e.preventDefault();
                          return;
                        }
                      }}
                    />
                  </Grid2>
                </>
              )}

              {!prospectiveClientDetails && (
                <Grid2
                  item
                  size={{ xs: 12, sm: 6 }}
                  className={styles.formField}
                >
                  <label className={styles.formLabel}>
                    Patient/Parent Phone Number
                  </label>
                  <input
                    type="tel"
                    className={styles.formInputWide}
                    placeholder="(000) 000-0000"
                    maxLength={14}
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                    required
                  />
                </Grid2>
              )}

              {!prospectiveClientDetails && (
                <>
                  <Grid2
                    item
                    size={{ xs: 12, sm: 6 }}
                    className={styles.formField}
                  >
                    <label className={styles.formLabel}>
                      Patient / Parent Email Address:
                    </label>
                    <input
                      type="email"
                      className={styles.formInputWide}
                      id="email"
                      name="email"
                      placeholder="example@example.com"
                      value={email}
                      onChange={handleEmailChange}
                      pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                      required
                    />
                  </Grid2>
                </>
              )}

              <Grid2 item size={{ xs: 12, sm: 6 }} className={styles.formField}>
                <label className={styles.formLabel}>
                  Service type you are seeking
                </label>
                <select
                  className={`${styles.formDropdown} ${
                    !serviceType ? styles.fadedSelectDisplay : ''
                  }`}
                  id="serviceType"
                  name="serviceType"
                  value={serviceType}
                  onChange={handleServiceTypeChange}
                  required
                >
                  <option value="" disabled>
                    Please Select
                  </option>
                  <option value="Therapy">Therapy</option>
                  <option value="Psychological Testing">
                    Psychological Testing
                  </option>
                </select>
                <small>
                  Please select primary service desired. If you require more
                  than one service please call our offices after this form is
                  submitted).
                </small>
              </Grid2>

              {/* {!showRestrictedFields && (
                <Grid2 item xs={12}>
                  <p
                    style={{
                      marginTop: '20px',
                      marginBottom: '20px',
                      fontWeight: 'bold',
                      color: 'darkslategray',
                    }}
                  >
                    Please note that we are currently only offering
                    comprehensive evaluations for clients that are under 22
                    years old. Additionally, the evaluator for these evaluations
                    is an out-of-network provider. This means that evaluation
                    will be at the private pay rate of $2950. Our office can
                    provide a superbill for those that have out-of-network
                    benefits.
                  </p>
                </Grid2>
              )} */}

              {/* {showRestrictedFields && ( */}
              <>
                {serviceType === 'Psychological Testing' && (
                  <Grid2
                    item
                    size={{ xs: 12, sm: 6 }}
                    className={styles.formField}
                  >
                    <label className={styles.formLabel}>
                      Type of Psychological Testing
                    </label>
                    <select
                      className={styles.formDropdown}
                      id="testingIssue"
                      name="testingIssue"
                      value={testingIssue}
                      onChange={(event) =>
                        handleTestingIssueChange(event, undefined)
                      }
                      required
                    >
                      <option value="" disabled>
                        Please Select
                      </option>
                      <option value="ADHD Adult Diagnostic Evaluation">
                        ADHD Adult Diagnostic Evaluation
                      </option>
                      <option value="ADHD Child Comprehensive Evaluation">
                        ADHD Child Comprehensive Evaluation
                      </option>
                      <option value="ADHD/Autism Child Comprehensive Combined Evaluation">
                        ADHD/Autism Child Comprehensive Combined Evaluation
                      </option>
                      <option value="Adoption Evaluation">
                        Adoption Evaluation
                      </option>
                      <option value="Autism Spectrum Disorder Evaluations for Adults">
                        Autism Spectrum Disorder Evaluations for Adults
                      </option>
                      <option value="Autism Spectrum Disorder Evaluations for Children">
                        Autism Spectrum Disorder Evaluations for Children
                      </option>
                      <option value="Bariatric Surgical Psychological Evaluation">
                        Bariatric Surgical Psychological Evaluation
                      </option>
                      {/* <option value="Comprehensive Evaluation">
                          Comprehensive Evaluation
                        </option> */}
                      <option value="Child Psycho-Educational Evaluation">
                        Child Psycho-Educational Evaluation
                      </option>
                      <option value="Differential Adult Diagnosis Evaluation">
                        Differential Adult Diagnosis Evaluation
                      </option>
                      {/* <option value="Immigration Evaluation">
                          Immigration Evaluation
                        </option> */}

                      <option value="Other Evaluation">Other Evaluation</option>
                    </select>
                  </Grid2>
                )}

                {testingIssue === 'Other Evaluation' && (
                  <Grid2
                    item
                    size={{ xs: 12, sm: 6 }}
                    className={styles.formField}
                  >
                    <label className={styles.formLabel}>
                      Other Testing - Describe The Type of Psychological Testing
                    </label>
                    <input
                      type="text"
                      className={styles.formInputWide}
                      id="otherTestingIssue"
                      name="otherTestingIssue"
                      placeholder="Please specify"
                      value={otherTestingIssue}
                      onChange={handleOtherTestingIssueChange}
                      required
                    />
                  </Grid2>
                )}
              </>

              {testingIssue !== 'Immigration Evaluation' && <></>}
              {serviceType === 'Therapy' && (
                <Grid2
                  item
                  size={{ xs: 12, sm: 6 }}
                  className={styles.formField}
                >
                  <label className={styles.formLabel}> Type of Therapy</label>
                  <select
                    className={`${styles.formDropdown} ${
                      !therapyType ? styles.fadedSelectDisplay : ''
                    }`}
                    id="therapyType"
                    name="therapyType"
                    value={therapyType}
                    onChange={handleTherapyTypeChange}
                    required
                  >
                    <option value="" disabled>
                      Please Select
                    </option>
                    <option value="Individual">Individual</option>
                    <option value="Group">Group</option>
                    <option value="Couples Therapy">Couple - Marital</option>
                    <option value="Family">Family</option>
                  </select>
                </Grid2>
              )}

              {serviceType === 'Therapy' && (
                <Grid2
                  item
                  size={{ xs: 12, sm: 6 }}
                  className={styles.formField}
                >
                  <label className={styles.formLabel}> Issue</label>
                  <select
                    className={`${styles.formDropdown} ${
                      !issue ? styles.fadedSelectDisplay : ''
                    }`}
                    id="issue"
                    name="issue"
                    value={issue}
                    onChange={handleIssueChange}
                    required
                  >
                    <option value="" disabled>
                      Please Select
                    </option>
                    <option value="ADHD">ADHD</option>
                    <option value="Addiction">Addiction</option>
                    <option value="Adoption">Adoption</option>
                    <option value="Alcohol Abuse">Alcohol Abuse</option>
                    <option value="Anger Management">Anger Management</option>
                    <option value="Anxiety or Fears">Anxiety or Fears</option>
                    <option value="Aspergers Syndrome">
                      Aspergers Syndrome
                    </option>
                    <option value="Autism">Autism</option>
                    <option value="Behavioral Issues">Behavioral Issues</option>
                    <option value="Bipolar Disorder">Bipolar Disorder</option>
                    <option value="Body Dysmorphic Disorder">
                      Body Dysmorphic Disorder
                    </option>
                    <option value="Borderline Personality">
                      Borderline Personality
                    </option>
                    <option value="Career Counseling">Career Counseling</option>
                    <option value="Chronic Impulsivity">
                      Chronic Impulsivity
                    </option>
                    <option value="Chronic Pain or Illness">
                      Chronic Pain or Illness
                    </option>
                    <option value="Chronic Relapse">Chronic Relapse</option>
                    <option value="Coping Skills">Coping Skills</option>
                    <option value="Depression">Depression</option>
                    <option value="Developmental Disorders">
                      Developmental Disorders
                    </option>
                    <option value="Dual Diagnosis">Dual Diagnosis</option>
                    <option value="Eating Disorder">Eating Disorder</option>
                    <option value="Family Conflict">Family Conflict</option>
                    <option value="Gambling">Gambling</option>
                    <option value="Infidelity">Infidelity</option>
                    <option value="Infertility">Infertility</option>
                    <option value="Internet Addiction">
                      Internet Addiction
                    </option>
                    <option value="Learning Disability">
                      Learning Disability
                    </option>
                    <option value="Loss or Grief">Loss or Grief</option>
                    <option value="Obesity">Obesity</option>
                    <option value="OCD">OCD</option>
                    <option value="Oppositional Defiance">
                      Oppositional Defiance
                    </option>
                    <option value="Parenting">Parenting</option>
                    <option value="Peer Relationships">
                      Peer Relationships
                    </option>
                    <option value="Post Partum Depression">
                      Post Partum Depression
                    </option>
                    <option value="Relationship Issues">
                      Relationship Issues
                    </option>
                    <option value="Self-Esteem">Self-Esteem</option>
                    <option value="Self-Harming">Self-Harming</option>
                    <option value="Sex Therapy">Sex Therapy</option>
                    <option value="Sexual Abuse">Sexual Abuse</option>
                    <option value="Sexual Addiction">Sexual Addiction</option>
                    <option value="Sleep or Insomnia">Sleep or Insomnia</option>
                    <option value="Substance Abuse">Substance Abuse</option>
                    <option value="Teen Violence">Teen Violence</option>
                    <option value="Transgender">Transgender</option>
                    <option value="Trauma and PTSD">Trauma and PTSD</option>
                    <option value="Traumatic Brain Injury">
                      Traumatic Brain Injury
                    </option>
                    <option value="Video Game Addiction">
                      Video Game Addiction
                    </option>
                    <option value="Weight Loss">Weight Loss</option>
                    <option value="Women's Issues">Women's Issues</option>
                    <option value="Other - Not Listed">
                      Other - Not Listed
                    </option>
                  </select>
                </Grid2>
              )}

              {issue === 'Other - Not Listed' && (
                <Grid2
                  item
                  size={{ xs: 12, sm: 6 }}
                  className={styles.formField}
                >
                  <label className={styles.formLabel}>
                    Issue - Other (Not Listed):
                  </label>
                  <input
                    type="text"
                    className={styles.formInputWide}
                    id="otherIssue"
                    name="otherIssue"
                    placeholder="Please specify"
                    value={otherIssue}
                    onChange={handleOtherIssueChange}
                    required
                  />
                </Grid2>
              )}

              {showInsuranceDropdown && (
                <>
                  <Grid2
                    item
                    size={{ xs: 12, sm: 6 }}
                    className={styles.formField}
                  >
                    <label className={styles.formLabel}>
                      Insurance Carrier
                    </label>
                    <select
                      className={`${styles.formDropdown} ${
                        !insuranceCarrier ? styles.fadedSelectDisplay : ''
                      }`}
                      id="insuranceCarrier"
                      name="insuranceCarrier"
                      value={insuranceCarrier}
                      onChange={handleInsuranceCarrierChange}
                      required
                    >
                      <option value="" disabled>
                        Please Select
                      </option>
                      <option value="Aetna">Aetna</option>
                      <option value="Blue Cross Blue Shield">
                        Blue Cross Blue Shield
                      </option>
                      <option value="Cigna">Cigna</option>
                      <option value="Humana Military (Formerly Tricare)">
                        Humana Military (Formerly Tricare)
                      </option>
                      <option value="Johns Hopkins - EHP">
                        Johns Hopkins - EHP
                      </option>
                      <option value="Johns Hopkins - USFHP">
                        Johns Hopkins - USFHP
                      </option>
                      <option value="Medicare">Medicare</option>
                      <option value="Self-Pay">Self-Pay</option>
                      <option value="Other">Other</option>
                      <option value="None">None</option>
                    </select>
                  </Grid2>

                  {insuranceCarrier !== 'Self-Pay' &&
                  insuranceCarrier !== '' ? (
                    <Grid2
                      item
                      size={{ xs: 12, sm: 6 }}
                      className={styles.formField}
                    >
                      <label className={styles.formLabel}>
                        Insurance Member ID number
                        <span
                          style={{
                            fontWeight: 'normal',
                            fontSize: '12px',
                            color: '#777',
                            marginLeft: '8px',
                          }}
                        >
                          (Optional)
                        </span>
                      </label>
                      <input
                        type="text"
                        className={styles.formInputWide}
                        id="insuranceMemberID"
                        name="insuranceMemberID"
                        placeholder="Member ID"
                        value={insuranceMemberID}
                        onChange={handleInsuranceMemberIDChange}
                      />
                    </Grid2>
                  ) : null}
                </>
              )}

              {isTherapySelected && (
                <Grid2 container spacing={1} className={styles.formRow}>
                  <Grid2
                    item
                    size={{ xs: 12, sm: 6 }}
                    className={styles.formField}
                  >
                    <label className={styles.formLabel}>Days Available:</label>
                    <select
                      className={`${styles.formDropdown} ${
                        !additionalTimes[0].daysAvailable
                          ? styles.fadedSelectDisplay
                          : ''
                      }`}
                      id="daysAvailable"
                      name="daysAvailable"
                      value={additionalTimes[0].daysAvailable}
                      onChange={(e) =>
                        handleAdditionalTimeChange(
                          0,
                          'daysAvailable',
                          e.target.value
                        )
                      }
                      required
                    >
                      <option value="" disabled>
                        Please Select
                      </option>
                      <option value="Every Weekday">Every Weekday</option>
                      <option value="Sunday">Sunday</option>
                      <option value="Monday">Monday</option>
                      <option value="Tuesday">Tuesday</option>
                      <option value="Wednesday">Wednesday</option>
                      <option value="Thursday">Thursday</option>
                      <option value="Friday">Friday</option>
                      <option value="Schedule does not allow for a recurring weekly appointment">
                        Schedule does not allow for a recurring weekly
                        appointment
                      </option>
                    </select>

                    {additionalTimes.length > 1 && (
                      <TrashCan
                        onClick={() => handleRemoveAdditionalTimes(0)}
                      />
                    )}
                  </Grid2>

                  <Grid2
                    item
                    size={{ xs: 12, sm: 6 }}
                    className={styles.formField}
                  >
                    <label className={styles.formLabel}>Time of Day:</label>

                    <select
                      className={`${styles.formDropdown} ${
                        !additionalTimes[0].timeOfDay
                          ? styles.fadedSelectDisplay
                          : ''
                      }`}
                      id="timeOfDay"
                      name="timeOfDay"
                      value={additionalTimes[0].timeOfDay}
                      onChange={(e) =>
                        handleAdditionalTimeChange(
                          0,
                          'timeOfDay',
                          e.target.value
                        )
                      }
                      required
                    >
                      <option value="" disabled>
                        Please Select
                      </option>
                      <option value="Anytime - No Restrictions">
                        Anytime - No Restrictions
                      </option>
                      <option value="Mornings (8am-Noon)">
                        Mornings (8am-Noon)
                      </option>
                      <option value="Early Afternoon (Noon-2pm)">
                        Early Afternoon (Noon-2pm)
                      </option>
                      <option value="Later Afternoon (2pm-5pm)">
                        Later Afternoon (2pm-5pm)
                      </option>
                      <option value="Evenings (5pm-9pm)">
                        Evenings (5pm-9pm)
                      </option>
                      <option value="No available recurring time every week">
                        No available recurring time every week
                      </option>
                    </select>
                  </Grid2>
                  <Grid2 item size={{ xs: 12 }} className={styles.formField}>
                    <button type="button" onClick={handleAddAdditionalTimes}>
                      + Add Additional Day/Times
                    </button>
                  </Grid2>
                </Grid2>
              )}

              {showAdditionalTimes &&
                additionalTimes.slice(1).map((time, index) => (
                  <Grid2
                    container
                    key={index}
                    spacing={1}
                    className={styles.formRow}
                  >
                    <Grid2
                      item
                      size={{ xs: 12, sm: 6 }}
                      className={styles.formField}
                    >
                      <label className={styles.formLabel}>
                        Days Available:
                      </label>
                      <select
                        className={`${styles.formDropdown} ${
                          !time.daysAvailable ? styles.fadedSelectDisplay : ''
                        } ${
                          additionalTimes.length > 1 ? styles.addMargin : ''
                        }`}
                        id={`daysAvailable-${index}`}
                        name={`daysAvailable-${index}`}
                        value={time.daysAvailable}
                        onChange={(e) =>
                          handleAdditionalTimeChange(
                            index + 1,
                            'daysAvailable',
                            e.target.value
                          )
                        }
                        required
                      >
                        <option value="" disabled>
                          Please Select
                        </option>
                        <option value="Every Weekday">Every Weekday</option>
                        <option value="Sunday">Sunday</option>
                        <option value="Monday">Monday</option>
                        <option value="Tuesday">Tuesday</option>
                        <option value="Wednesday">Wednesday</option>
                        <option value="Thursday">Thursday</option>
                        <option value="Friday">Friday</option>
                        <option value="Schedule does not allow for a recurring weekly appointment">
                          Schedule does not allow for a recurring weekly
                          appointment
                        </option>
                      </select>
                    </Grid2>

                    <Grid2
                      item
                      size={{ xs: 12, sm: 6 }}
                      className={styles.formField}
                    >
                      <label className={styles.formLabel}>Time of Day:</label>
                      <select
                        className={`${styles.formDropdown} ${
                          !time.timeOfDay ? styles.fadedSelectDisplay : ''
                        }`}
                        id={`timeOfDay-${index}`}
                        name={`timeOfDay-${index}`}
                        value={time.timeOfDay}
                        onChange={(e) =>
                          handleAdditionalTimeChange(
                            index + 1,
                            'timeOfDay',
                            e.target.value
                          )
                        }
                        required
                      >
                        <option value="" disabled>
                          Please Select
                        </option>
                        <option value="Anytime - No Restrictions">
                          Anytime - No Restrictions
                        </option>
                        <option value="Mornings (8am-Noon)">
                          Mornings (8am-Noon)
                        </option>
                        <option value="Early Afternoon (Noon-2pm)">
                          Early Afternoon (Noon-2pm)
                        </option>
                        <option value="Later Afternoon (2pm-5pm)">
                          Later Afternoon (2pm-5pm)
                        </option>
                        <option value="Evenings (5pm-9pm)">
                          Evenings (5pm-9pm)
                        </option>
                        <option value="No available recurring time every week">
                          No available recurring time every week
                        </option>
                      </select>
                    </Grid2>
                    {isTherapySelected && (
                      <Grid2
                        item
                        size={{ xs: 12 }}
                        className={styles.formField}
                      >
                        <button
                          type="button"
                          onClick={handleAddAdditionalTimes}
                        >
                          + Add Additional Day/Times
                        </button>
                      </Grid2>
                    )}

                    <Grid2
                      item
                      size={{ xs: 1 }}
                      className={`${styles.formField} ${styles.trashContainer}`}
                    >
                      <button
                        type="button"
                        className={styles.trashButton}
                        onClick={() => handleRemoveAdditionalTimes(index + 1)}
                      >
                        <span role="img" aria-label="Trash Can">
                          🗑️
                        </span>
                      </button>
                    </Grid2>
                    <TrashCan
                      onClick={() => handleRemoveAdditionalTimes(index + 1)}
                    />
                  </Grid2>
                ))}

              {serviceType === 'Therapy' && (
                <Grid2
                  item
                  size={{ xs: 12, sm: 6 }}
                  className={styles.formField}
                >
                  <label className={styles.formLabel}>
                    {' '}
                    Do you have a certain clinician you are interested in
                    working with?
                  </label>
                  <select
                    className={`${styles.formDropdown} ${
                      !interestedClinician ? styles.fadedSelectDisplay : ''
                    }`}
                    id="interestedClinician"
                    name="interestedClinician"
                    value={interestedClinician}
                    onChange={handleInterestedClinicianChange}
                    required
                  >
                    <option value="" disabled>
                      Please Select
                    </option>
                    <option value="No">No</option>
                    <option value="Yes">Yes</option>
                  </select>
                </Grid2>
              )}

              {interestedClinician === 'Yes' && serviceType === 'Therapy' && (
                <Grid2
                  item
                  size={{ xs: 12, sm: 6 }}
                  className={styles.formField}
                >
                  <label className={styles.formLabel}>
                    Select your preferred clinician:
                  </label>
                  <select
                    className={`${styles.formDropdown} ${
                      styles.preferredDropDown
                    } ${
                      !selectedClinician.id ? styles.fadedSelectDisplay : ''
                    }`}
                    id="clinician"
                    name="clinician"
                    value={selectedClinician.id || ''}
                    onChange={(e) => {
                      const selectedValue = e.target.value;
                      const selectedClinicianObject = clinicianOptions.find(
                        (clinician) => clinician.id === Number(selectedValue)
                      );
                      setSelectedClinician(selectedClinicianObject);
                    }}
                    required
                  >
                    <option value="" disabled>
                      Please Select
                    </option>
                    {clinicianOptions.map((clinician, index) => (
                      <option key={index} value={clinician.id}>
                        {clinician.username}
                      </option>
                    ))}
                  </select>
                </Grid2>
              )}

              {interestedClinician === 'Yes' && serviceType === 'Therapy' && (
                <Grid2
                  item
                  size={{ xs: 12, sm: 6 }}
                  className={styles.formField}
                >
                  <label className={styles.formLabel}>
                    If the preferred clinician is not available, are you willing
                    to see a different clinician?
                  </label>
                  <select
                    className={`${styles.formDropdown} ${
                      !willingToSeeDifferentClinician
                        ? styles.fadedSelectDisplay
                        : ''
                    }`}
                    id="willingToSeeDifferentClinician"
                    name="willingToSeeDifferentClinician"
                    value={willingToSeeDifferentClinician}
                    onChange={handleWillingToSeeDifferentClinicianChange}
                    required
                  >
                    <option value="" disabled>
                      Please Select
                    </option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </Grid2>
              )}

              {serviceType === 'Therapy' && (
                <Grid2
                  item
                  size={{ xs: 12, sm: 6 }}
                  className={styles.formField}
                >
                  <label className={styles.formLabel}>
                    Do you have a preference for in-person or virtual
                    appointments?
                  </label>

                  <select
                    className={`${styles.formDropdown} ${
                      !appointmentPreference ? styles.fadedSelectDisplay : ''
                    }`}
                    id="appointmentPreference"
                    name="appointmentPreference"
                    value={appointmentPreference}
                    onChange={handleAppointmentPreferenceChange}
                    required
                  >
                    <option value="" disabled>
                      Please Select
                    </option>
                    <option value="No Preference">No Preference</option>
                    <option value="In Person">In Person</option>
                    <option value="Virtual-Online">Virtual-Online</option>
                  </select>
                </Grid2>
              )}

              {serviceType === 'Therapy' && (
                <Grid2
                  item
                  size={{ xs: 12, sm: 6 }}
                  className={styles.formField}
                >
                  <label className={styles.formLabel}>
                    {' '}
                    If 'In-Person,' what is your preferred location?
                  </label>

                  <select
                    className={`${styles.formDropdown} ${
                      !preferredLocation ? styles.fadedSelectDisplay : ''
                    }`}
                    id="preferredLocation"
                    name="preferredLocation"
                    value={preferredLocation}
                    onChange={handlePreferredLocationChange}
                    required
                  >
                    <option value="" disabled>
                      Please Select
                    </option>
                    <option value="Baltimore">Baltimore</option>
                    <option value="Columbia">Columbia</option>
                    <option value="Silver Spring">Silver Spring</option>
                  </select>
                </Grid2>
              )}

              {serviceType === 'Therapy' &&
                (appointmentPreference === 'No Preference' ||
                  appointmentPreference === 'Virtual-Online') && (
                  <Grid2
                    item
                    size={{ xs: 12, sm: 6 }}
                    className={styles.formField}
                  >
                    <label className={styles.formLabel}> State</label>

                    <select
                      className={`${styles.formDropdown} ${
                        !state ? styles.fadedSelectDisplay : ''
                      }`}
                      id="state"
                      name="state"
                      value={state}
                      onChange={handleStateChange}
                      required
                    >
                      <option value="" disabled>
                        Please Select
                      </option>
                      <option value="Alabama">Alabama</option>
                      <option value="Alaska">Alaska</option>
                      <option value="Arizona">Arizona</option>
                      <option value="Arkansas">Arkansas</option>
                      <option value="California">California</option>
                      <option value="Colorado">Colorado</option>
                      <option value="Connecticut">Connecticut</option>
                      <option value="Delaware">Delaware</option>
                      <option value="District of Columbia">
                        District of Columbia
                      </option>
                      <option value="Florida">Florida</option>
                      <option value="Georgia">Georgia</option>
                      <option value="Hawaii">Hawaii</option>
                      <option value="Idaho">Idaho</option>
                      <option value="Illinois">Illinois</option>
                      <option value="Indiana">Indiana</option>
                      <option value="Iowa">Iowa</option>
                      <option value="Kansas">Kansas</option>
                      <option value="Kentucky">Kentucky</option>
                      <option value="Louisiana">Louisiana</option>
                      <option value="Maine">Maine</option>
                      <option value="Maryland">Maryland</option>
                      <option value="Massachusetts">Massachusetts</option>
                      <option value="Michigan">Michigan</option>
                      <option value="Minnesota">Minnesota</option>
                      <option value="Mississippi">Mississippi</option>
                      <option value="Missouri">Missouri</option>
                      <option value="Montana">Montana</option>
                      <option value="Nebraska">Nebraska</option>
                      <option value="Nevada">Nevada</option>
                      <option value="New Hampshire">New Hampshire</option>
                      <option value="New Jersey">New Jersey</option>
                      <option value="New Mexico">New Mexico</option>
                      <option value="New York">New York</option>
                      <option value="North Carolina">North Carolina</option>
                      <option value="North Dakota">North Dakota</option>
                      <option value="Ohio">Ohio</option>
                      <option value="Oklahoma">Oklahoma</option>
                      <option value="Oregon">Oregon</option>
                      <option value="Pennsylvania">Pennsylvania</option>
                      <option value="Rhode Island">Rhode Island</option>
                      <option value="South Carolina">South Carolina</option>
                      <option value="South Dakota">South Dakota</option>
                      <option value="Tennessee">Tennessee</option>
                      <option value="Texas">Texas</option>
                      <option value="Utah">Utah</option>
                      <option value="Vermont">Vermont</option>
                      <option value="Virginia">Virginia</option>
                      <option value="Washington">Washington</option>
                      <option value="West Virginia">West Virginia</option>
                      <option value="Wisconsin">Wisconsin</option>
                      <option value="Wyoming">Wyoming</option>
                      <option value="Armed Forces Americas">
                        Armed Forces Americas
                      </option>
                      <option value="Armed Forces Europe">
                        Armed Forces Europe
                      </option>
                      <option value="Armed Forces Pacific">
                        Armed Forces Pacific
                      </option>
                    </select>
                    <small>
                      The state the patient would be residing if services were
                      rendered virtually.
                    </small>
                  </Grid2>
                )}

              {shouldShowForm && (
                <div className={styles.containerTextArea}>
                  {!prospectiveClientDetails && (
                    <Grid2
                      item
                      size={{ xs: 12 }}
                      className={`${styles.formField} ${styles.customHeight}`}
                    >
                      <label className={styles.formLabel}>
                        Please give a brief description of what you are seeking
                        help with:
                      </label>
                      <textarea
                        className={styles.formTextarea}
                        rows="8"
                        placeholder="Type here..."
                        value={descriptionOfSeekingHelp}
                        onChange={(e) =>
                          setDescriptionSeekingHelp(e.target.value)
                        }
                      ></textarea>
                    </Grid2>
                  )}

                  <div className={styles.referredColumn}>
                    {!prospectiveClientDetails && (
                      <>
                        <Grid2
                          item
                          size={{ xs: 12, sm: 6 }}
                          className={styles.formField}
                        >
                          <label className={styles.formLabel}>
                            Referred By:
                          </label>

                          <select
                            className={`${styles.formDropdown} ${
                              !referredBy ? styles.fadedSelectDisplay : ''
                            }`}
                            id="referredBy"
                            name="referredBy"
                            value={referredBy}
                            onChange={handleReferredByChange}
                            required
                          >
                            <option value="" disabled>
                              Please Select
                            </option>
                            <option value="Client at PsychCare">
                              Client at PsychCare
                            </option>
                            <option value="Google Search">Google Search</option>
                            <option value="HealthCare Provider">
                              HealthCare Provider
                            </option>
                            <option value="My Insurance Company">
                              My Insurance Company
                            </option>
                            <option value="Psychology Today">
                              Psychology Today
                            </option>
                            <option value="Other">Other</option>
                          </select>
                        </Grid2>

                        {referredBy === 'Other' && (
                          <Grid2
                            item
                            size={{ xs: 12, sm: 6 }}
                            className={styles.formField}
                          >
                            <label className={styles.formLabel}>
                              {' '}
                              Other Referred By:
                            </label>
                            <input
                              type="text"
                              className={styles.formInputWide}
                              id="otherReferredBy"
                              name="otherReferredBy"
                              placeholder="Please specify"
                              value={otherReferredBy}
                              onChange={handleOtherReferredByChange}
                              required
                            />
                          </Grid2>
                        )}
                      </>
                    )}

                    {/* Radio buttons for 'Would you like to receive email newsletters?' */}

                    <Grid2
                      item
                      size={{ xs: 12, sm: 6 }}
                      className={`${styles.formField} ${styles.customContainer}`}
                    >
                      <label
                        className={`${styles.formLabel} ${styles.customWidthEmail}`}
                      >
                        Would you like to receive email newsletters from our
                        practice?
                      </label>
                      <div
                        className={`${styles.radioGroup} ${styles.radioGroupNegativeMargin}`}
                      >
                        <FormControlLabel
                          style={{ marginRight: '80px' }}
                          control={
                            <Radio
                              checked={newsletter === 'yes'}
                              onChange={handleRadioChange}
                              value="yes"
                              name="newsletter"
                            />
                          }
                          label="Yes"
                        />
                        <FormControlLabel
                          control={
                            <Radio
                              checked={newsletter === 'no'}
                              onChange={handleRadioChange}
                              value="no"
                              name="newsletter"
                            />
                          }
                          label="No"
                        />
                      </div>
                    </Grid2>
                  </div>
                  {showASDMandatoryField && (
                    <div className={styles.formField}>
                      <label htmlFor="asdMandatoryAcknowledgment">
                        <div style={{ marginBottom: '10px' }}>
                          <span style={{ color: 'red' }}>New!</span>{' '}
                          <strong>
                            <i>
                              We now offer the option to pay for this testing
                              through CareCredit e.g. Pay no interest for 12
                              months. Click{' '}
                              <a
                                href="https://www.psychcaremd.com/care-credit/"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: 'blue' }}
                              >
                                here
                              </a>{' '}
                              to learn more and sign up for CareCredit.
                            </i>
                          </strong>
                        </div>
                        <input
                          type="checkbox"
                          id="asdMandatoryAcknowledgment"
                          checked={isASDMandatoryAcknowledged}
                          onChange={(e) =>
                            setIsASDMandatoryAcknowledged(e.target.checked)
                          }
                          required
                        />{' '}
                        I understand that the evaluator conducting Autism
                        Spectrum Disorder Evaluations for Children does not
                        participate with insurance and that an evaluation costs
                        $3,300 (5 years-old +) / $2750 (2-4 years-old).
                        <span className={styles.elqRequired}>*</span>
                      </label>
                    </div>
                  )}

                  {showChildADHDMandatoryField && (
                    <div className={styles.formField}>
                      <label htmlFor="childADHDMandatoryAcknowledgment">
                        <div style={{ marginBottom: '10px' }}>
                          <span style={{ color: 'red' }}>New!</span>{' '}
                          <strong>
                            <i>
                              We now offer the option to pay for this testing
                              through CareCredit e.g. Pay no interest for 12
                              months. Click{' '}
                              <a
                                href="https://www.psychcaremd.com/care-credit/"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: 'blue' }}
                              >
                                here
                              </a>{' '}
                              to learn more and sign up for CareCredit.
                            </i>
                          </strong>
                        </div>
                        <input
                          type="checkbox"
                          id="childADHDMandatoryAcknowledgment"
                          checked={isChildADHDMandatoryAcknowledged}
                          onChange={(e) =>
                            setIsChildADHDMandatoryAcknowledged(
                              e.target.checked
                            )
                          }
                          required
                        />{' '}
                        I understand that the evaluator conducting ADHD Child
                        Comprehensive Evaluations does not participate with
                        insurance and that an evaluation costs $3,000.
                        <span className={styles.elqRequired}>*</span>
                      </label>
                    </div>
                  )}

                  {showChildCombASDADHDMandatoryField && (
                    <div className={styles.formField}>
                      <label htmlFor="childCombASDADHDMandatoryAcknowledgment">
                        <div style={{ marginBottom: '10px' }}>
                          <span style={{ color: 'red' }}>New!</span>{' '}
                          <strong>
                            <i>
                              We now offer the option to pay for this testing
                              through CareCredit e.g. Pay no interest for 12
                              months. Click{' '}
                              <a
                                href="https://www.psychcaremd.com/care-credit/"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: 'blue' }}
                              >
                                here
                              </a>{' '}
                              to learn more and sign up for CareCredit.
                            </i>
                          </strong>
                        </div>
                        <input
                          type="checkbox"
                          id="childCombASDADHDAcknowledgment"
                          checked={isChildCombASDADHDMandatoryAcknowledged}
                          onChange={(e) =>
                            setIsChildCombASDADHDMandatoryAcknowledged(
                              e.target.checked
                            )
                          }
                          required
                        />{' '}
                        I understand that the evaluator conducting ADHD/ASD
                        Child Combined Comprehensive Evaluations does not
                        participate with insurance and that an evaluation costs
                        $3,800.
                        <span className={styles.elqRequired}>*</span>
                      </label>
                    </div>
                  )}

                  {showChildPsychoEdMandatoryField && (
                    <div className={styles.formField}>
                      <label htmlFor="childPsychoEdMandatoryAcknowledgment">
                        <div style={{ marginBottom: '10px' }}>
                          <span style={{ color: 'red' }}>New!</span>{' '}
                          <strong>
                            <i>
                              We now offer the option to pay for this testing
                              through CareCredit e.g. Pay no interest for 12
                              months. Click{' '}
                              <a
                                href="https://www.psychcaremd.com/care-credit/"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: 'blue' }}
                              >
                                here
                              </a>{' '}
                              to learn more and sign up for CareCredit.
                            </i>
                          </strong>
                        </div>
                        <input
                          type="checkbox"
                          id="childPsychoEdAcknowledgment"
                          checked={isChildPsychoEdMandatoryAcknowledged}
                          onChange={(e) =>
                            setIsChildPsychoEdMandatoryAcknowledged(
                              e.target.checked
                            )
                          }
                          required
                        />{' '}
                        I understand that the evaluator conducting
                        Psycho-Educational Child Evaluation does not participate
                        with insurance and that an evaluation costs $3,000.
                        <span className={styles.elqRequired}>*</span>
                      </label>
                    </div>
                  )}

                  <Grid2 item size={{ xs: 12 }} style={{ textAlign: 'center' }}>
                    {/* The below have made textAlign left donot remove this is for responsiveness*/}
                    <div style={{ marginBottom: '8px', textAlign: 'left' }}>
                      Please verify that you are human
                    </div>
                    {/* And the below captacha-container class has styles in css for responsiveness*/}
                    <div className={styles.captachaContainer}>
                      <ReCAPTCHA
                        sitekey={process.env.REACT_APP_CAPTACHA_KEY}
                        onChange={handleRecaptchaChange}
                      />
                    </div>
                    <div className={styles.separatorLine}></div>{' '}
                    {/* Separator line */}
                    <button
                      type="submit"
                      className={styles.submitButton}
                      disabled={!isVerified || formLoadingState}
                    >
                      {formLoadingState ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        'Submit'
                      )}
                    </button>
                    {(!isVerified || formLoadingState) && (
                      <p style={{ fontSize: 'small', fontStyle: 'italic' }}>
                        By entering your phone number, you’re consenting to
                        follow-up calls or text messages from our administrative
                        team. We do not sell, rent or otherwise disclose
                        personal information collected by our site to third
                        parties in the ordinary course of business.
                      </p>
                    )}
                  </Grid2>
                </div>
              )}
            </Grid2>

            {!shouldShowForm && (
              <label
                className={`${styles.formLabel} ${styles.centerText} ${
                  apologyMessage.startsWith('We apologize')
                    ? styles.apologyMessage
                    : ''
                }`}
              >
                {apologyMessage}
              </label>
            )}
          </form>
        )}
      </div>
    </div>
  ) : (
    <ThankYouPage />
  );
};

// Exporting the CustomForm component so it can be used in other parts of the application
export default CustomForm;
