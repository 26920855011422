import React, { memo } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
} from '@mui/material';

const CancelConditionDialog = ({
  cancelConfirmationOpen,
  handleCancelConfirmationClose,
  handleConfirmCancellation,
}) => {
  return (
    <Dialog
      open={cancelConfirmationOpen}
      onClose={handleCancelConfirmationClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Confirm Cancellation</DialogTitle>
      <DialogContent>
        <Typography sx={{ color: 'red' }}>
          Warning: This action cannot be undone and any slots blocked by this
          condition will be unblocked.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleCancelConfirmationClose}
        >
          Go Back
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={handleConfirmCancellation}
        >
          Continue with Cancellation
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(CancelConditionDialog);
